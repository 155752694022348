import { Button } from 'primereact/button'
import React, { useState } from 'react'
import FlexComponent from '../../FlexComponent'
import axios from '../../../utils/axios';
import { toggleWatcher } from '../../../utils/urls';

function Watcher({toast, watchers, formId, userId }) {

    // console.log(watchers.includes(userId))
    const [isWatching, setIsWatching] = useState(watchers.includes(userId))

    const updateWatch = () => {
        const data = {
            form_id: formId,
            user_id: userId,
        }
        console.log(data)
        axios.post(toggleWatcher, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    return (
        <div className="applicantDivs" style={{width : "30%"}} >
            <div style={{padding:".5rem"}} >Notifications</div>
            {isWatching ?
                <Button style={{boxShadow:"none", border:"1px solid grey"}} onClick={() => {
                    setIsWatching(false)
                    updateWatch()
                }} icon="pi pi-eye" label={<div>Watching <i style={{fontWeight: "bold", paddingLeft:"4px"}} className='pi pi-check'></i> </div>} />
                :
                <Button style={{boxShadow:"none", border:"1px solid grey"}} onClick={() => {
                    setIsWatching(true)
                    updateWatch()
                }} icon="pi pi-eye" label='Watch' />
            }
        </div>
    )
}

export default Watcher