import React, { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import FlexComponent from "../FlexComponent";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { deleteCheckListTemplateItem, upsertPipeline } from "../../utils/urls";
import axios from '../../utils/axios';
import { InputText } from "primereact/inputtext";

export function ChecklistItems({ toast, checklistItems, setChecklistItems, item }) {
    console.log(checklistItems, "item", item)
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: item.checklist_id });

    // const [itemEdit, setItemEdit] = useState(false);
    // const [itemName, setItemName] = useState(item.item);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%",
        background: "white",
    };



    // const deleteItem = (id) => {
    //     const updatedData = checklistItems.filter(item => item.checklist_id !== id)
    //     setChecklistItems(updatedData)
    // }

    const deleteItem = (list) => {
        const updatedData = checklistItems?.filter(item => item.checklist_id !== list.checklist_id);
        setChecklistItems(updatedData);
        const Url = `${deleteCheckListTemplateItem}${list.checklist_id}`
        axios.post(Url, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {

                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    // handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                // handleClose()
            })

    };



    return (
        <div className="list-row" ref={setNodeRef} style={style}>
            <FlexComponent gap="1rem" style={{ border: "1px solid grey", padding: "10px", borderRadius: "10px", width: "100%" }}>
                <FlexComponent gap=".5rem">
                    <span style={{ cursor: "pointer" }} {...attributes} {...listeners}>⣿</span>
                    <p>{item.item}</p>
                    <div >
                        {/* <i style={{ cursor: "pointer" }} onClick={() => setItemEdit(true)} className="pi pi-pencil"></i> */}
                        <i style={{ cursor: "pointer", paddingLeft: ".5rem" }} onClick={() => {
                            deleteItem(item)
                        }} className="pi pi-trash"></i>
                    </div>
                </FlexComponent>

            </FlexComponent>

        </div>
    );
}
