import React, { useEffect, useRef, useState } from 'react';
import FlexComponent from '../../FlexComponent';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import './apps.css'
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useSelector } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from '../../../utils/axios';

import { archiveSubmission, deleteSubmission } from '../../../utils/urls';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

const AppItem = ({ app, userId, toast, setTotal, setBoardSections }) => {
    const navigate = useNavigate()
    const dotsOp = useRef(null);
    const otherCustomFields = getValuesByNames(app?.customFields, ["Loan Amount", "Purchase Price"])
    // console.log(otherCustomFields)
    const isSuperuser = useSelector((state) => state.user.isSuperuser)
    const redirectProfilePage = (contact_id, form_id) => {
        navigate(`/app/${contact_id}/${form_id}`)
    }

    function changeIncomeColor(applicantIncome) {
        const income = parseInt(applicantIncome?.replace(/\D/g, ''));
        // console.log(income)
        if (isNaN(income)) return '';
        if (income >= 80) {
            return '#2FC623';
        } else if (income < 80 && income >= 65) {
            return 'orange';
        } else {
            return '#EB4243'
        }
    }

    function changeCreditColor(applicantCredit) {
        const credit = parseInt(applicantCredit);
        // console.log(credit)
        if (isNaN(credit)) return '';
        if (credit >= 620) {
            return '#2FC623';
        } else if (credit < 620 && credit >= 500) {
            return '#F7DF01';
        } else {
            return 'red'
        }
    }

    function changeAvailableColor(cashAvailable) {
        const amount = parseInt(cashAvailable?.replace(/\D/g, ''));
        // console.log(amount)
        if (isNaN(amount)) return '';
        if (amount >= 45) {
            return '#2FC623';
        } else if (amount < 45 && amount >= 20) {
            return '#FF9A00';
        } else {
            return 'red'
        }
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    function formatDate(inputDate) {
        const dateParts = inputDate.split(' ')[0].split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        const date = new Date(`${year}-${month}-${day}`);

        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];
        const formattedDate = `${parseInt(day)} ${monthNames[date.getMonth()]}, ${year}`;
        return formattedDate;
    }

    const handleArchiveCard = (event) => {
        // setLoading(true)
        confirmPopup({
            message: 'Are you sure you want to Archive this card?',
            target: event.currentTarget,
            group: 'headless',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setBoardSections(prevSections => {
                    const updatedSections = { ...prevSections };
                    for (const sectionId in updatedSections) {
                        updatedSections[sectionId] = updatedSections[sectionId].filter((item) => item.form_id !== app.form_id)
                    }
                    return updatedSections;
                });
                const Url = `${archiveSubmission}${app.form_id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            // setLoading(false)
                            toast.current.show({ severity: 'success', summary: 'SuccessFully Archived', detail: '', life: 3000 });
                            setTotal((prev) => prev - 1)
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // handleClose()
                        // setLoading(false)
                    })

            },
            reject: () => {
                console.log("closed")
            }
        });
    };

    const handleDeleteCard = (event) => {
        // setLoading(true)
        confirmPopup({
            message: 'Are you sure you want to Delete this card?',
            target: event.currentTarget,
            group: 'headless',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setBoardSections(prevSections => {
                    const updatedSections = { ...prevSections };
                    for (const sectionId in updatedSections) {
                        updatedSections[sectionId] = updatedSections[sectionId].filter((item) => item.form_id !== app.form_id)
                    }
                    return updatedSections;
                });
                const Url = `${deleteSubmission}${app.form_id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            // setLoading(false)
                            toast.current.show({ severity: 'success', summary: 'SuccessFully Deleted', detail: '', life: 3000 });
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // setLoading(false)
                    })

            },
            reject: () => {
                console.log("closed")
            }
        });
    };

    function convertDate(utcDateString) {
        if(!utcDateString) return  ""
        const utcDate = new Date(utcDateString);

        const dateOptions = {
            day: '2-digit',
            month: 'short',
            // year: 'numeric',
        };

        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const formattedDate = utcDate.toLocaleDateString('en-GB', dateOptions);
        // const formattedTime = utcDate.toLocaleTimeString('en-US', timeOptions);

        const formattedLocalDateTime = `${formattedDate}`;

        // console.log(formattedLocalDateTime);
        return formattedLocalDateTime

    }

    function isDateDue(utcDateString) {
        const utcDate = new Date(utcDateString);
        const now = new Date();
        return utcDate < now;
      }

      function getValuesByNames(customFields, names) {
        const result = {};
      
        names.forEach(name => {
          const field = customFields.find(item => item.name === name);
          if (field) {
            result[field.name] = field.value;
          }
        });
      
        return Object.keys(result).length === names.length ? result : null;
      }

      function formatNumber(number) {
        // Convert the input to a number
        if (number) {
            const num = parseInt(number, 10);

            if (isNaN(num)) {
                throw new Error('Invalid number');
            }

            if (num >= 1000 && num < 1000000) {
                return (num / 1000).toFixed(1) + 'k';
            } else if (num >= 1000000 && num < 1000000000) {
                return (num / 1000000).toFixed(1) + 'M';
            } else if (num >= 1000000000) {
                return (num / 1000000000).toFixed(1) + 'B';
            } else {
                return num.toString();
            }
        }
    }

    return (
        <div className='appItem' style={{ border: 'solid 1px #B1B1B1', padding: '10px', cursor: 'pointer', background: 'linear-gradient(to bottom, #264371, #0E123D)' }}>
            <FlexComponent flexDirection='column' alignItems='start'>
                <FlexComponent justifyContent="end" gap=".3rem" >
                    <FlexComponent justifyContent="start" >
                        {app.labels.map((list, index) => (
                            <FlexComponent justifyContent="start" key={index} style={{ width: "auto", height: "8px" }} ><span style={{ background: `#${list.color}`, color: "white", borderRadius: "10px", padding: "0 .5rem ", fontSize: "10px" }} >{list.name}</span> </FlexComponent>
                        ))}
                    </FlexComponent>
                    {/* <div style={{ width:"5%"}}>
                    <i style={{color:"white"}} className='pi pi-ellipsis-v'></i>
                    </div> */}
                </FlexComponent>
                <FlexComponent style={{ marginTop: app.labels.length > 0 ? ".3rem" : "" }} alignItems='start'>
                    <div style={{ width: "70%" }}>
                        {/* <p style={{ fontSize: '14px', color: '#B1B1B1' }} >{app?.location_name}</p> */}
                        <p style={{ fontSize: '18px' }}>{app?.full_name}</p>
                    </div>
                    <FlexComponent style={{ width: "30%" }} justifyContent="end" >
                        <div style={{ width: "65%" }} >
                            <p style={{ fontFamily: "DINCondensedC", fontSize: '10px', minWidth: "70px" }}>{formatDate(app?.created_date)}</p>
                            <p style={{ fontFamily: "DINCondensedC", fontSize: '10px' }}>{app?.language}</p>
                        </div>
                        {isSuperuser && <div onClick={(e) => {
                            e.stopPropagation()
                            dotsOp.current.toggle(e)
                        }}>

                            <i style={{ color: "white" }}
                                className='pi pi-ellipsis-v'></i>
                            <OverlayPanel onClick={(e) => {
                                e.stopPropagation()
                            }} ref={dotsOp} style={{ background: '#1A2B53' }} >
                                <FlexComponent gap="1rem" >
                                    <ConfirmPopup />
                                    <Button onClick={(e) => {
                                        e.stopPropagation()
                                        handleArchiveCard(e)
                                    }} >Archive</Button>
                                    <Button onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteCard(e)
                                    }}>Delete</Button>
                                </FlexComponent>
                            </OverlayPanel>
                        </div>}
                    </FlexComponent>
                </FlexComponent>
                {(otherCustomFields && otherCustomFields["Loan Amount"] && otherCustomFields["Purchase Price"]) ? <FlexComponent justifyContent='start' gap='.8rem' style={{  marginTop: ".2rem" }}>
                    <div>
                        <p style={{ fontFamily: "DINCondensedC", textAlign: "center", fontSize: '24px' }}>{formatNumber(otherCustomFields["Loan Amount"])}</p>
                        <p>Loan Amount</p>
                    </div>
                    <div>
                        <p style={{ fontFamily: "DINCondensedC", textAlign: "center", fontSize: app?.credit.length > 5 ? '12px' : '24px' }}>{formatNumber(otherCustomFields["Purchase Price"])}</p>
                        <p>Purchase Price</p>
                    </div>
                {/* <div style={{color:"white", width:"50px", fontSize: "12px", padding:"0 .5rem", borderRadius:"5px", background : isDateDue(app?.due_date) ? "red" : "green"}} >{convertDate(app?.due_date)}</div> */}

                </FlexComponent> :  <FlexComponent justifyContent='start' gap='.8rem' style={{  marginTop: ".2rem" }}>
                    <div>
                        <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeIncomeColor(app?.income), fontSize: '24px' }}>{app?.income}</p>
                        <p>Income</p>
                    </div>
                    <div>
                        <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeCreditColor(app?.credit), fontSize: app?.credit.length > 5 ? '12px' : '24px' }}>{app?.credit}</p>
                        <p>Credit</p>
                    </div>
                    <div>
                        <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeAvailableColor(app?.savings), fontSize: '24px' }}>{app?.savings}</p>
                        <p>Available</p>
                    </div>
                {/* <div style={{color:"white", width:"50px", fontSize: "12px", padding:"0 .5rem", borderRadius:"5px", background : isDateDue(app?.due_date) ? "red" : "green"}} >{convertDate(app?.due_date)}</div> */}

                </FlexComponent>}
                <FlexComponent justifyContent="start" style={{marginTop:"4px"}} gap="1rem" >
                {/* {app?.watchers?.includes(userId) && <i style={{color:"white"}} className='pi pi-eye'></i>} */}
                {app?.due_date && <div style={{color:"white",  fontSize: "12px", padding:"0 .5rem", borderRadius:"5px", background : isDateDue(app?.due_date) ? "red" : "green"}} >{convertDate(app?.due_date)}</div>}
                {app?.contract_due_date && <div style={{color:"white",  fontSize: "12px", padding:"0 .5rem", borderRadius:"5px", background : isDateDue(app?.contract_due_date) ? "red" : "green"}} >Contract - {convertDate(app?.contract_due_date)}</div>}
                </FlexComponent>
                
                <FlexComponent >
                    <div>
                        <Button onMouseDown={() => window.open(`https://app.hfsarm.com/v2/location/${app?.location_id}/contacts/detail/${app?.ghl_contact_id}`, '_blank')} style={{ background: 'transparent', color: 'white', padding: '0.3rem' }} ><i className="pi pi-external-link"></i></Button>
                        <Button style={{ background: 'transparent', color: 'white', padding: '0.3rem' }} onMouseDown={() => window.open(`/app/${app?.ghl_contact_id}/${app?.form_id}`, '_blank')}
                        ><i className="pi pi-book"></i></Button>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <FlexComponent gap="0.1rem" alignItems="start">
                            {app?.images?.map((user, index) => (
                                <div key={index}>
                                    {index < 5 &&
                                        <div style={{ position: "relative" }} >
                                            <Avatar image={user?.image} style={{ width: '30px', height: '30px', }} className="p-overlay-badge" size="small" shape='circle'
                                                label={getInitials(user?.name)}
                                            >
                                                {/* <Badge value="^" /> */}
                                                {(user?.roles.includes("application_by") && user?.roles.includes("contact_owner")) ? <div style={{ marginTop: "20px", transform: 'Scale(0.7)' }} >
                                                    <i className="pi pi-angle-double-up" style={{ position: "absolute", marginLeft: "-12px", color: "white", background: "blue", borderRadius: "20px" }} ></i>
                                                </div> :
                                                    (user?.roles.includes("application_by")) ? <div style={{ marginTop: "20px", transform: 'Scale(0.7)' }} >
                                                        <i className="pi pi-angle-up" style={{ position: "absolute", marginLeft: "-12px", color: "white", background: "#2FC623", borderRadius: "20px" }} ></i>
                                                    </div> : (user?.roles.includes("contact_owner")) ? <div style={{ marginTop: "20px", transform: 'Scale(0.7)' }} >
                                                        <i className="pi pi-angle-up" style={{ position: "absolute", marginLeft: "-12px", color: "white", background: "red", borderRadius: "20px" }} ></i>
                                                    </div> : ''}
                                            </Avatar>
                                        </div>
                                    }
                                </div>
                            ))}
                            {app?.images?.length > 5 && <Avatar style={{ width: '30px', height: '30px' }} size="small" shape='circle' label={`${app?.length - 4}+`} />}

                        </FlexComponent>
                    </div>
                </FlexComponent>
            </FlexComponent>

        </div>
    );
};

export default AppItem;
