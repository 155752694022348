import { Button } from 'primereact/button'
import React, { useEffect, useRef, useState } from 'react'
import { AddFollowers, addLabel, AddSales, getLabel, RemoveFollowerSales, removeLabel, updateAssignedUser } from '../../../utils/urls'
import axios from '../../../utils/axios';
import FlexComponent from '../../FlexComponent';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

function Members({ setBoardSections, members, contactOwners, contactId, locationUsers, toast, formId, userId }) {
    const [isMounted, setIsMounted] = useState(false)

    const [allMembers, setAllMembers] = useState(members)
    const [allLocationUsers, setAllLocationUsers] = useState(locationUsers)
    const [selectedSalesUsers, setSelectedSalesUsers] = useState([]);
    const [selectedFollowersUsers, setSelectedFollowersUsers] = useState([]);

    const [contactOwner, setContactOwner] = useState(allMembers.find((member) => member.roles.includes("contact_owner"))?.id);
    const [contactOwnerData, setContactOwnerData] = useState(null)
    const membersOp = useRef(null)
    console.log(allMembers)

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }



    const handleContactowner = (e) => {
        setContactOwner(e.value)
        let user = contactOwners.find(user => user.id === e.value);
        setContactOwnerData(user)
        // if (user) {
        //     updateContactOpportunityOwner(user)
        // }
    }

    const updateContactOpportunityOwner = () => {
        const data = { 
            user: contactOwnerData,
            userId : userId,
            app_id : formId
         }
        const Url = `${updateAssignedUser}${contactId}`
        // const Url = ``
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const AddLeadFollowers = () => {
        console.log(selectedFollowersUsers)
        const data = { 
            selectedUsers: selectedFollowersUsers,
            userId : userId,
            app_id : formId
         }
        const Url = `${AddFollowers}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                    setBoardSections(prevSections => {
                        const updatedSections = { ...prevSections };
                        for (const sectionId in updatedSections) {
                            updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                if (item.form_id === formId) {
                                    const updatedImages = [...item.images];
                                    const updatedFollowers = selectedFollowersUsers.map(user => ({
                                        ...user,
                                        roles: ["followers"], 
                                    }));

                                    // Check for duplicates before adding
                                    updatedFollowers.forEach(newUser => {
                                        const exists = updatedImages.some(image => image.id === newUser.id);
                                        if (!exists) {
                                            updatedImages.push(newUser);
                                        }
                                    });

                                    return {
                                        ...item,
                                        images: updatedImages,
                                    };
                                }
                                return item;
                            });
                        }
                        return updatedSections;
                    });

                    setAllMembers((prev) => {
                        const updatedFollowers = selectedFollowersUsers.map(user => ({
                            ...user,
                            roles: ["followers"], 
                        }));

                        // Check for duplicates before adding
                        updatedFollowers.forEach(newUser => {
                            const exists = prev.some(image => image.id === newUser.id);
                            if (!exists) {
                                prev.push(newUser);
                            }
                        });
                        return prev
                    })

                    setAllLocationUsers(prev => {
                        const idsToRemove = selectedFollowersUsers.map(obj => obj.id);
                        const updatedState = prev.filter(obj => !idsToRemove.includes(obj.id));
                        return updatedState;
                    });
                    setSelectedSalesUsers([])
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }



    const AddLeadSales = () => {
        const data = { 
            selectedUsers: selectedSalesUsers,
            userId : userId,
            app_id : formId
         }
        const Url = `${AddSales}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                    setBoardSections(prevSections => {
                        const updatedSections = { ...prevSections };
                        for (const sectionId in updatedSections) {
                            updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                if (item.form_id === formId) {
                                    const updatedImages = [...item.images];
                                    const updatedSalesUsers = selectedSalesUsers.map(user => ({
                                        ...user,
                                        roles: ["sales_team"], 
                                    }));

                                    // Check for duplicates before adding
                                    updatedSalesUsers.forEach(newUser => {
                                        const exists = updatedImages.some(image => image.id === newUser.id);
                                        if (!exists) {
                                            updatedImages.push(newUser);
                                        }
                                    });

                                    return {
                                        ...item,
                                        images: updatedImages,
                                    };
                                }
                                return item;
                            });
                        }
                        return updatedSections;
                    });

                    setAllMembers((prev) => {
                        const updatedSalesUsers = selectedSalesUsers.map(user => ({
                            ...user,
                            roles: ["sales_team"], 
                        }));

                        // Check for duplicates before adding
                        updatedSalesUsers.forEach(newUser => {
                            const exists = prev.some(image => image.id === newUser.id);
                            if (!exists) {
                                prev.push(newUser);
                            }
                        });
                        return prev
                    })

                    setAllLocationUsers(prev => {
                        const idsToRemove = selectedSalesUsers.map(obj => obj.id);
                        const updatedState = prev.filter(obj => !idsToRemove.includes(obj.id));
                        return updatedState;
                    });
                    setSelectedSalesUsers([])
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const RemoveLeadFollowerSales = (user) => {
        const data = { 
            user,
            userId : userId,
            app_id : formId
         }
        const Url = `${RemoveFollowerSales}${contactId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

            })
    }

    const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.name)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                {option.name}
            </FlexComponent>
        );
    };

    // useEffect(() => {
    //     getLabelsList()
    // }, [])


    return (
        <div className="applicantDivs" >
            {/* <p> <i className="pi pi-wallet" style={{ marginRight: "10px" }} ></i> Labels</p> */}
            <FlexComponent gap="0.1rem" justifyContent="start" alignItems="start">
                {allMembers?.map((user, index) => (
                    <div key={index}>
                        {index < 5 &&
                            <Avatar image={user.image} style={{ width: '30px', height: '30px', color: 'black' }} size="small" shape='circle'
                                label={getInitials(user?.name)}
                            >
                                {(user?.roles.includes("application_by") && user?.roles.includes("contact_owner")) ? <div style={{ marginTop: "20px", transform: 'Scale(0.7)' }} >
                                    <i className="pi pi-angle-double-up" style={{ position: "absolute", marginLeft: "-12px", color: "white", background: "blue", borderRadius: "20px" }} ></i>
                                </div> :
                                    (user?.roles.includes("application_by")) ? <div style={{ marginTop: "20px", transform: 'Scale(0.7)' }} >
                                        <i className="pi pi-angle-up" style={{ position: "absolute", marginLeft: "-12px", color: "white", background: "#2FC623", borderRadius: "20px" }} ></i>
                                    </div> : (user?.roles.includes("contact_owner")) ? <div style={{ marginTop: "20px", transform: 'Scale(0.7)' }} >
                                        <i className="pi pi-angle-up" style={{ position: "absolute", marginLeft: "-12px", color: "white", background: "red", borderRadius: "20px" }} ></i>
                                    </div> : ''}
                            </Avatar>
                        }
                    </div>
                ))}
                {allMembers.length > 5 && <Avatar style={{ width: '30px', height: '30px' }} size="small" shape='circle' label={`${allMembers?.length - 4}+`} />}
                <Button onClick={(e) => {
                    membersOp.current.toggle(e)
                }} style={{ padding: ".5rem", border: "1px solid grey", boxShadow: "none" }} ><i className='pi pi-plus'></i></Button>
                <OverlayPanel ref={membersOp} style={{ background: "#254270" }} >
                    <div style={{ width: "300px" }}>

                        <FlexComponent flexDirection="column" gap="1rem" >
                            <div style={{ width: "100%" }}>
                                <div style={{color:"white"}} >Contact Owner</div>
                                <Dropdown
                                    value={contactOwner}
                                    style={{ width: '100%', textAlign: 'start', border: 'none', boxShadow: 'none', marginTop:".5rem" }}
                                    options={contactOwners}
                                    onChange={(e) => {
                                        handleContactowner(e)
                                    }}
                                    optionLabel="name"
                                    itemTemplate={userTemplate}
                                    placeholder="Select Contact Owner"
                                    filter
                                    // showClear
                                    className="user-dropdown"
                                    optionLabelTemplate={userTemplate}
                                    optionValue="id"
                                />
                            </div>

                            <div style={{ width: "100%" }}>
                                <div style={{color:"white"}} >Sales Team</div>
                                <MultiSelect style={{ width: '100%', marginTop:".5rem" }} itemTemplate={userTemplate} value={selectedSalesUsers} onChange={(e) => {
                                    setSelectedSalesUsers(e.value)
                                    // AddLeadSales(e)
                                }} options={allLocationUsers} optionLabel="name"
                                    filter placeholder="Add Sales Team" maxSelectedLabels={3} className="w-full md:w-20rem" />
                            </div>

                            <div style={{ width: "100%" }}>
                                <div style={{color:"white"}} >Followers</div>
                                <MultiSelect style={{ width: '100%', marginTop:".5rem" }} itemTemplate={userTemplate} value={selectedFollowersUsers} onChange={(e) => {
                                    setSelectedFollowersUsers(e.value)
                                    // AddLeadFollowers()
                                }} options={allLocationUsers} optionLabel="name"
                                    filter placeholder="Add Followers Team" maxSelectedLabels={3} className="w-full md:w-20rem" />
                            </div>
                            <FlexComponent justifyContent="end" >
                                <Button onClick={(e) => {
                                    membersOp.current.toggle(e)
                                    if (contactOwnerData) {
                                        updateContactOpportunityOwner()
                                    }
                                    if (selectedSalesUsers.length > 0) {
                                        AddLeadSales()
                                    }
                                    if (selectedFollowersUsers.length > 0) {
                                        AddLeadFollowers()
                                    }
                                }} >Save</Button>
                            </FlexComponent>
                        </FlexComponent>

                    </div>

                </OverlayPanel>
            </FlexComponent>
        </div>
    )
}

export default Members
