import React from "react";

const Title = ({ children }) => (
  <h2 className="animate-in" style={{ animationDelay: "600ms" }}>
    {children}
  </h2>
);

export default Title;

