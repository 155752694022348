import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import './form.css'

function YourCredit({ values, errors, touched, handleBlur, handleChange }) {


    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='YourCredit'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>How is your credit?</p>
            </div>

            <div style={{ textAlign: 'start' }}>
                <div style={{ margin: "1rem 0.5rem" }}>
                    <label >*Don't allow them to be discouraged! We can work with them to improve their score and they can qualify with as low as 500+</label>
                </div>
                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                    <div className='inputContainerDiv' style={{ width: '40%', margin: '0.5rem' }}>
                        <label htmlFor="estimated_credit_score" style={{ fontWeight: 'bold', color: Boolean(touched.estimated_credit_score && errors.estimated_credit_score) ? 'red' : '' }}>Estimated Credit Score*</label>
                        <Field type="number" id="estimated_credit_score" name="estimated_credit_score" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                        {/* <ErrorMessage name="estimated_credit_score" component="div" className="error" style={{color:'red'}} /> */}
                    </div>

                    <div className='inputContainerDiv' style={{ width: '25%', margin: '0.5rem' }}>
                        <label style={{ fontWeight: 'bold' }} htmlFor="date_of_birth" >Date Of Birth</label>
                        <Field
                            name="date_of_birth"
                        >{({ field, form }) => (
                            <Calendar
                                id={field.name}
                                value={field.value}
                                style={{ width: '100%' }}
                                onChange={(e) => form.setFieldValue(field.name, e.value)}
                            />
                        )
                            }</Field>
                    </div>
                    <div className='inputContainerDiv' style={{ width: '40%', margin: '0.5rem' }}>
                        <label htmlFor="social_security_no" style={{ fontWeight: 'bold' }}>##</label>
                        <Field type="text" id="social_security_no" name="social_security_no" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} placeholder="000-00-0000" />
                        {/* <ErrorMessage name="social_security_no" component="div" className="error" style={{color:'red'}} /> */}
                    </div>

                </FlexComponent>


                <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                    <label htmlFor="status_in_country" style={{ fontWeight: 'bold' }}>Status In Country</label> <br />
                    {["Citizen / Green Card", "Work Authorization", "ITIN"].map((option) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                <Field type="radio" id={option} name="status_in_country" value={option} as={RadioButton} />
                                <label htmlFor={option} className="ml-2">{option}</label>

                            </FlexComponent >
                        );
                    })}
                </div>

            </div>

        </div>
    )
}

export default YourCredit