import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from '../../../utils/axios';
import { deleteCheckListTemplateItem } from "../../../utils/urls";
import FlexComponent from "../../FlexComponent";
import './apps.css'
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { useRef, useState } from "react";

export function ChecklistTemplateItems({ toast, checklistItems, setChecklistItems, item }) {
    console.log(checklistItems, "item", item)
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: item.checklist_id });

    // const [itemEdit, setItemEdit] = useState(false);
    // const [itemName, setItemName] = useState(item.item);
    const dueDateOp = useRef(null);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%",
        background: "white",
    };

    const [dueDate, setDueDate] = useState('')


    // const deleteItem = (id) => {
    //     const updatedData = checklistItems.filter(item => item.checklist_id !== id)
    //     setChecklistItems(updatedData)
    // }

    

    const deleteItem = (list) => {
        const updatedData = checklistItems?.filter(item => item.checklist_id !== list.checklist_id);
        setChecklistItems(updatedData);
        const Url = `${deleteCheckListTemplateItem}${list.checklist_id}`
        axios.post(Url, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {

                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    // handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                // handleClose()
            })

    };

    const convertDateToDateString = (date) => {
        if (!date) return '';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <div className="list-row" style={{ marginTop: ".5rem" }} >
            <FlexComponent gap="1rem" style={{ border: "1px solid grey", padding: "10px", borderRadius: "10px", width: "100%" }}>
                {/* <FlexComponent gap=".5rem"> */}
                    {/* <span style={{ cursor: "pointer" }} {...attributes} {...listeners}>⣿</span> */}
                    <div style={{width : "70%"}} >{item.item}</div>
                    <FlexComponent style={{ border: "none", boxShadow: "none", width:"30%" }} justifyContent="start" >
                        <span style={{ cursor: "pointer" }}
                            onClick={(e) => dueDateOp.current.toggle(e)}
                        ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>
                            {dueDate ? <span>{convertDateToDateString(dueDate)}</span> : <span>Due Date</span>}
                        </span>
                    </FlexComponent>
                {/* </FlexComponent> */}
            </FlexComponent>
                <OverlayPanel ref={dueDateOp} style={{ background: '#1A2B53' }} >
                    <Calendar id="buttondisplay" value={dueDate} inline onChange={(e) => {
                        setDueDate(e.value)
                        console.log("date", e.value)
                        setChecklistItems((prev)=> (
                            prev.map((data)=> (
                                data.checklist_id === item.checklist_id ? {...data, due_date : e.value} : data
                            ))
                        ))
                        dueDateOp.current.toggle(e)
                    }} />
                </OverlayPanel>

        </div>
    );
}
