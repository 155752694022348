import React from 'react'
import LoginPanel from './LoginPanel'
// import SignupPanel from './SignupPanel'


function MainPanel() {
  return (
    <>
    <div className="panels-container1">

    <LoginPanel/>
    {/* <SignupPanel/> */}

    </div>
    </>
  )
}

export default MainPanel