import React, { useState } from 'react';
import FlexComponent from '../FlexComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import './profile.css'
import { Divider } from 'primereact/divider';
import { Avatar } from 'primereact/avatar';
import { Password } from 'primereact/password';
import { deleteProfileData, UpdateCustomAppLead } from '../../utils/urls';
import axios from '../../utils/axios'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2';

function CoSigner({ data, isCosigner, toast, formId, userId, setIsSubmit }) {
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [label, setLabel] = useState("")
    const [error, setError] = useState()

    const convertDateStringToDate = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day); // month is 0-based in Date
    };

    const [formData, setFormData] = useState({
        first_name:  '',
        last_name:  '',
        email:  '',
        phone: '',
        DOB:  '',
        social_security: '',
        credit: '',
        work_title:  '',
        company_name: '',
        work_type: '',
        time_at_position:  '',
        income: '',
        street_address: '',
        state:  '',
        city:  '',
        zip:  '',
        id: ''
    });


    function hasValue(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
                    return true;
                }
            }
        }
        return false;
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    // const handleEditClick = () => {
    //     setIsEditOpen(true);
    // };

    const handleEditClick = (labelData, list) => {
        if (labelData == "add") {
            setLabel("Add Cosigner Information")
            setFormData([])
        } else {
            setLabel("Edit Cosigner Information")
            setFormData({
                first_name: list?.first_name || '',
                last_name: list?.last_name || '',
                email: list?.email || '',
                phone: list?.phone || '',
                DOB: convertDateStringToDate(list?.unformatted_DOB) || '',
                social_security: list?.social_security || '',
                credit: list?.credit || '',
                work_title: list?.work_title || '',
                company_name: list?.company_name || '',
                work_type: list?.type || '',
                time_at_position: list?.time_at_position || '',
                income: list?.unformatted_income || '',
                street_address: list?.street_address || '',
                state: list?.state || '',
                city: list?.city || '',
                zip: list?.zip || '',
                id: list.id
            });
        }
        setIsEditOpen(true);
    }

    const convertDateToDateString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based in Date
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleWorkTypeChange = (e) => {
        setFormData({
            ...formData,
            work_type: e.value
        });
    };

    // const handleEditSave = async () => {
    //     try {
    //         if (hasValue(formData)) {
    //             const updatedData = {
    //                 ...formData,
    //                 DOB: convertDateToDateString(formData.DOB),
    //                 do_you_have_a_cosigner: "Yes",
    //                 type: "Cosingner Information",
    //                 userId: userId
    //             };
    //             console.log(updatedData);
    //             setIsEditOpen(false);
    //             console.log('Submit ')
    //             const url = `${UpdateCustomAppLead}${formId}`
    //             const response = await axios.post(url, updatedData, {
    //                 headers: { 'Content-Type': 'application/json' },
    //             });
    //             if (response.status === 200) {
    //                 toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
    //                 setIsSubmit(true)
    //                 // onSubmitProps.resetForm();
    //             } else {
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
    //                 setIsSubmit(true)
    //             }
    //         }
    //     } catch (error) {
    //         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
    //         setIsSubmit(true)
    //     }
    // };
    const handleEditSave = async () => {
        try {
            const errors = {};
            // if (!formData.first_name?.trim()) errors.first_name = true;
            // if (!formData.last_name) errors.last_name = true;
            setError(errors);

            if (Object.keys(errors).length === 0) {
                setIsEditOpen(false);
                const updatedData = {
                    ...formData,
                    type: "Cosingner Information",
                    DOB: convertDateToDateString(formData.DOB),
                    // do_you_have_a_cosigner : true,
                    userId: userId
                };
                console.log(updatedData);
                setIsEditOpen(false);
                console.log('Submit ')
                const url = `${UpdateCustomAppLead}${formId}`
                const response = await axios.post(url, updatedData, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                    setIsSubmit(true)
                    // onSubmitProps.resetForm();
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                    setIsSubmit(true)
                }
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true)
        }
    };

    const handleDeleteClick = async (id) => {
        try {
            Swal.fire({
                title: 'Are you sure to Delete the Address?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updatedData = {
                        id: id,
                        type: 'Cosingner Information',
                        userId: userId
                    };
                    console.log(updatedData)
                    const url = `${deleteProfileData}${formId}`;
                    const response = await axios.post(url, updatedData, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted', life: 3000 });
                        setIsSubmit(true);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                        setIsSubmit(true);
                    }

                }
            })
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true);
        }
    }


    const handleEditCancel = () => {
        setIsEditOpen(false);
    };

    const handleDateChange = (e) => {
        const { value } = e;
        setFormData((prevData) => ({
            ...prevData,
            DOB: value,
        }));
    };

    function changeAvatarColor(type) {
        if (!type) return ''
        if (type.includes("W2")) {
            return '#F141D0';
        } else if (type === "1099 Independent Contractor") {
            return '#418BCC';
        } else {
            return '#EB4243'
        }
    }

    function avatarTitle(type) {
        if (!type) return ''
        if (type.includes("W2")) {
            return 'W2';
        } else if (type === "1099 Independent Contractor") {
            return '1099';
        } else if (type === "Self Employed/Business Owner") {
            return 'SE'
        } else {
            return ''
        }
    }

    return (
        <>
            <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', background: 'white', padding: '2rem' }}>
                <FlexComponent style={{ textAlign: 'start', width: '100%' }}>
                    <p style={{ fontWeight: '600' }}>Cosingner Information</p>
                    {/* <Button label="Edit" style={{ borderRadius: '25px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }}
                        onClick={() => {
                            handleEditClick("edit")
                        }} /> */}
                </FlexComponent>

                {data?.map((list, index) => (
                    <>
                    <FlexComponent alignItems="start" >
                        <FlexComponent  style={{width: "90%"}} flexDirection="column">
                            <FlexComponent  >
                                <div style={{ textAlign: 'start', width: '60%' }}>
                                    <p style={{ color: '#8C8C8C' }}>First Name</p>
                                    <p>{list?.first_name}</p>
                                </div>
                                <div style={{ textAlign: 'start', width: '40%' }}>
                                    <p style={{ color: '#8C8C8C' }}>Last Name</p>
                                    <p>{list?.last_name}</p>
                                </div>
                            </FlexComponent>
                            <FlexComponent>
                                <div style={{ textAlign: 'start', width: '60%' }}>
                                    <p style={{ color: '#8C8C8C' }}>Email</p>
                                    <p>{list?.email}</p>
                                </div>
                                <div style={{ textAlign: 'start', width: '40%' }}>
                                    <p style={{ color: '#8C8C8C' }}>Phone</p>
                                    <p>{list?.phone}</p>
                                </div>
                            </FlexComponent>
                            <FlexComponent className="inputContainerFlex">
                                <div style={{ textAlign: 'start', width: '60%' }}>
                                    <p style={{ color: '#8C8C8C' }}>Social key</p>
                                    {/* <p>{list?.status_in_country}</p> */}
                                    <div className=" flex justify-content-center">
                                        <Password style={{ border: 'none', width: '240px', }} readOnly value={list?.social_security} toggleMask feedback={false} />
                                    </div>
                                </div>
                                <div style={{ textAlign: 'start', width: '40%' }}>
                                    <p style={{ color: '#8C8C8C' }}>Date of Birth</p>
                                    <p>{list?.DOB || 'N/A'}</p>
                                </div>
                            </FlexComponent>
                            <FlexComponent>
                                <div style={{ textAlign: 'start', width: '60%' }}>
                                    <p style={{ color: '#8C8C8C' }}>Credit Score</p>
                                    <p>{list?.credit || 'N/A'}</p>
                                </div>
                            </FlexComponent>

                            <Divider />

                            <FlexComponent className="inputContainerFlex" alignItems="start">
                                <FlexComponent flexDirection='column' style={{ gap: '1.25rem', textAlign: 'start', }} alignItems='start'>
                                    <p style={{ textAlign: 'start' }}>Work history</p>
                                    <FlexComponent alignItems='start' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }}>
                                        <div>
                                            <Avatar style={{ width: '4.5rem', color: 'white', background: changeAvatarColor(list?.type) }} label={avatarTitle(list?.type)} size="xlarge" />
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <p>{list?.work_title || 'N/A'}</p>
                                            <p>{list?.type || 'N/A'}</p>
                                            <p style={{ color: '#8C8C8C' }}>{list?.time_at_position || 'N/A'}</p>
                                            {list?.income && <p ><span style={{ fontSize: '22px', fontWeight: 'bold' }}>{list?.income}</span><span style={{ marginLeft: '.3rem' }}>Yearly</span></p>}
                                        </div>
                                    </FlexComponent>
                                </FlexComponent>
                                {/* <Divider /> */}
                                <FlexComponent flexDirection='column' style={{ gap: '1.25rem', textAlign: 'start', }} alignItems='start'>
                                    <p style={{ textAlign: 'start' }}>Address</p>
                                    <FlexComponent alignItems='start' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }} >
                                        <div>
                                            <Avatar style={{ width: '4.5rem', color: 'white' }} label="H" size="xlarge" />
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            {/* <p><span style={{ color: '#8C8C8C' }}>Country : </span>{list?.country}</p> */}
                                            <p><span style={{ color: '#8C8C8C' }}>Street Address : </span>{list?.street_address || 'N/A'}</p>
                                            <p><span style={{ color: '#8C8C8C' }}>State : </span>{list?.state || 'N/A'}</p>
                                            <p><span style={{ color: '#8C8C8C' }}>City : </span>{list?.city || 'N/A'}</p>
                                            <p><span style={{ color: '#8C8C8C' }}>Pin Code : </span>{list?.zip || 'N/A'}</p>
                                        </div>
                                    </FlexComponent>
                                </FlexComponent>
                            </FlexComponent>
                        </FlexComponent>
                        <div style={{ width: "10%" }} >
                            <Button
                                label="Edit"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '16px',
                                    padding: '5px 20px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C'
                                }}
                                onClick={() => {
                                    handleEditClick("Edit",list);
                                }}
                            />
                             <Button
                                label="Delete"
                                style={{
                                    borderRadius: '25px',
                                    fontSize: '15px',
                                    padding: '5px 10px',
                                    border: '1px solid #E0E4EC',
                                    background: 'white',
                                    color: '#8C8C8C',
                                    marginTop: ".5rem"
                                }}
                                onClick={() => {
                                    handleDeleteClick(list.id)
                                }}
                            />
                        </div>
                    </FlexComponent>
                        {data?.length > 1 && <Divider style={{background:"grey", height: "5px"}}/>}
                        </>
                ))}

                <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', background: 'white', padding: '2rem' }}>
                    <Button onClick={() => {
                        handleEditClick("add")
                    }} >Add Cosigner</Button>
                </FlexComponent>
            </FlexComponent> 

            <Dialog className='editForm editCosignerForm' header={label} visible={isEditOpen} onHide={() => handleEditCancel()}>
                <div >
                    <form onSubmit={handleEditSave} >
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="first_name">First Name</label> <br />
                                <InputText style={{ width: '100%' }} id="first_name" defaultValue={formData?.first_name} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="last_name">Last Name</label> <br />
                                <InputText style={{ width: '100%' }} id="last_name" defaultValue={formData?.last_name} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="email">Email</label> <br />
                                <InputText style={{ width: '100%' }} id="email" defaultValue={formData?.email} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="phone">Phone</label> <br />
                                <InputText style={{ width: '100%' }} id="phone" defaultValue={formData?.phone} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="social_security">Social Security</label> <br />
                                <InputText style={{ width: '100%' }} id="social_security" defaultValue={formData.social_security} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="DOB">Date of Birth</label> <br />
                                {/* <InputText style={{ width: '100%' }} id="DOB" defaultValue={formData.DOB} onChange={handleInputChange} /> */}
                                <Calendar inputId="DOB" dateFormat="dd/mm/yy" value={formData.DOB} onChange={handleDateChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="credit">Credit</label> <br />
                                <InputText style={{ width: '47%' }} id="credit" defaultValue={formData.credit} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>

                        <p style={{ fontWeight: "bold", margin: '0.5rem' }} >Work History</p>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="work_title">Title</label> <br />
                                <InputText style={{ width: '100%' }} id="work_title" defaultValue={formData.work_title} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="company_name">Company Name</label> <br />
                                <InputText style={{ width: '100%' }} id="company_name" defaultValue={formData.company_name} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="work_type">Work Type</label> <br />
                                {/* <InputText style={{ width: '100%' }} id="work_type" defaultValue={formData.type} onChange={handleInputChange} /> */}
                                <Dropdown
                                    id="work_type"
                                    value={formData?.work_type}
                                    options={[
                                        { label: 'Salary - W2 Employee', value: 'Salary - W2 Employee' },
                                        { label: 'Hourly - W2 Employee', value: 'Hourly - W2 Employee' },
                                        { label: '1099 Independent Contractor', value: '1099 Independent Contractor' },
                                        { label: 'Self Employed/Business Owner', value: 'Self Employed/Business Owner' }
                                    ]}
                                    style={{ width: '100%' }}
                                    onChange={handleWorkTypeChange}
                                    placeholder="Select Work Type"
                                />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="time_at_position">Time at Position</label> <br />
                                <InputText style={{ width: '100%' }} id="time_at_position" defaultValue={formData.time_at_position} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="income">Income</label> <br />
                                <InputText style={{ width: '47%' }} id="income" defaultValue={formData.income} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <p style={{ fontWeight: "bold", margin: '0.5rem' }} >Address</p>

                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="street_address">Street Address</label> <br />
                                <InputText style={{ width: '100%' }} id="street_address" defaultValue={formData.street_address} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="state">State</label> <br />
                                <InputText style={{ width: '100%' }} id="state" defaultValue={formData.state} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="city">City</label> <br />
                                <InputText style={{ width: '100%' }} id="city" defaultValue={formData.city} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="zip">Zip Code</label> <br />
                                <InputText style={{ width: '100%' }} id="zip" defaultValue={formData.zip} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <div style={{ position: 'sticky', bottom: '0', background: 'white' }}>
                            <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem', padding: "1rem" }}>
                                <Button type='button' disabled={!hasValue(formData)} label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                                <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                            </FlexComponent>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>

    );
}

export default CoSigner;
