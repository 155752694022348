import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userId: null,
    isSuperuser: false,
    userName: null,
    userLable: null,
    image: null,
    ghlUserId: null,
    is_leader: false
  },
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload.userId;
      state.isSuperuser = action.payload.isSuperuser;
      state.userName = action.payload.userName;
      state.userLable = action.payload.userLable;
      state.image = action.payload.image;
      state.ghlUserId = action.payload.ghlUserId;
      state.is_leader = action.payload.is_leader;
    },
    clearUser: (state) => {
      state.userId = null;
      state.isSuperuser = false;
      state.userName = null;
      state.userLable = null;
      state.image = null;
      state.ghlUserId = null;
      state.is_leader = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
