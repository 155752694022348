import React,{useState, useEffect} from 'react'
import './Auth.css'
// import { SignUpModeContext } from '../../../Context/SignupModeContext'
import { useLocation, useNavigate} from "react-router-dom" 
import LoginForm from '../loginForm/LoginForm'
import MainPanel from '../../panels/MainPanel'
import image from '../../Images/neighborhood-bg.png'

function Auth() {

  const [signUpState, setSignUpState] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();


//   useEffect(() => {
//     if (location.pathname === "/signup") {
//       setSignUpState(true);
//     } else if (location.pathname === "/login") {
//       setSignUpState(false);
//     }
//   }, [location]);

//   useEffect(() => {
//     if (!isInitialRender) {
//       if (!signUpState) {
//         navigate("/login");
//       } else {
//         navigate("/signup");
//       }
//     } else {
//       setIsInitialRender(false);
//     }
//   }, [signUpState]);


  return (
    <div className='body'>
      <div style={{backgroundImage : `url(${image})`}} className={signUpState ? "container1 sign-up-mode": "container1"}>
      <div className="forms-container1">
        <div style={{height: '100%', background: 'white'}} className="signin-signup">
         <LoginForm/>
        </div>
      </div>
      <MainPanel/>
    </div>
        
  </div>
  
  )
}

export default Auth