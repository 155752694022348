import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { ProgressBar } from 'primereact/progressbar'
import { Mention } from 'primereact/mention'
import { Calendar } from 'primereact/calendar'
import { OverlayPanel } from 'primereact/overlaypanel'
import { MultiSelect } from 'primereact/multiselect'
import { Avatar } from 'primereact/avatar'
import FlexComponent from '../../FlexComponent'
import './apps.css'
import axios from '../../../utils/axios';
import { createCheckList, deleteCheckList, sendInternalChecklistNotification, updateCheckList, updateCheckListOrder } from '../../../utils/urls'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import CheckListItem from './CheckListItem'

function CheckList({ userId, formId, usersList, salesUsersList, pipelineId, checkListsData, handleUpdateCheckList, handleDeleteCheckList, checkListHeading, handleClose, toast }) {
    // console.log("checkListsData", checkListsData)
    const [checkLists, setCheckLists] = useState(checkListsData.items);
    // const [salesMembersList, setSalesMembersList] = useState(salesUsersList.filter((user)=> (user.roles.includes("contact_owner"))))
    const [salesMembersList, setSalesMembersList] = useState(
        salesUsersList
            .filter(user => !user.roles.includes("application_by"))
            .map(user => ({ label: user.name, value: user.id, image: user.image }))
    );

    const [suggestions, setSuggestions] = useState([]);
    const checklistNotifyOp = useRef(null)
    const [isEditTitle, setIsEditTitle] = useState(false)
    const [editTitle, setEditTitle] = useState(checkListHeading)
    const [toggle, setToggle] = useState(false)

    const [isCheckListAdd, setIsCheckListAdd] = useState(false)
    const [value, setValue] = useState('');
    const [mentionedUsers, setMentionedUsers] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([])
    const [dueDate, setDueDate] = useState('')
    const [checkListEditIndex, setCheckListEditIndex] = useState(null);
    const [selectedSalesUsers, setSelectedSalesUsers] = useState([])

    const [isCheckListEdit, setIsCheckListEdit] = useState(false);

    const [hideCheckedItems, setHideCheckedItems] = useState(true)

    const [checkListLoad, setCheckListLoad] = useState(false)
    const checkedCount = checkLists.filter(list => list.completed).length;

    const dueDateOp = useRef(null);
    const assignOp = useRef(null);


    const handleAddCheckList = () => {
        setIsCheckListAdd(true)
        setIsCheckListEdit(false)
    }

    const convertDateToDateString = (date) => {
        if (!date) return '';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    // console.log("salesMembersList", salesMembersList)


    const onSearch = (event) => {
        setTimeout(() => {
            const query = event.query.toLowerCase();
            let filteredSuggestions = [];

            if (!query.trim().length) {
                filteredSuggestions = [...usersList];
            } else {
                filteredSuggestions = usersList.filter((user) =>
                    user.label.toLowerCase().startsWith(query)
                );
            }

            setSuggestions(filteredSuggestions);
        }, 250);
    };

    const onChange = (e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        setMentionedUsers(extractUsers(inputValue));
    };



    const extractUsers = (text) => {
        // Update the regex pattern to match @mentions with periods, underscores, and hyphens
        const mentionPattern = /@([\w.-]+)/g;
        const mentions = [...text.matchAll(mentionPattern)].map(match => match[1]);
        return mentions.map(mention => {
            const user = usersList.find(user => user.label.replace(/\s+/g, '').toLowerCase() === mention.toLowerCase());
            return user ? { id: user.value } : null;
        }).filter(user => user !== null);
    };

    const itemTemplate = (suggestion) => {
        return (
            <div className="flex align-items-center">
                <span className="flex flex-column ml-2">
                    {suggestion.label}
                </span>
            </div>
        );
    };

    const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={getInitials(option?.label)} shape="circle" style={{ marginRight: '8px' }} />
                <span>{option.label}</span>
            </FlexComponent>
        );
    };

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    const convertDateToString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based in Date
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSaveCheckList = () => {
        const data = {
            item: value,
            mention: mentionedUsers,
            assigned_user: assignedUsers.map(item => ({ id: item })),
            due_date: convertDateToString(dueDate),
            heading_id: checkListsData.id
        }
        console.log(data)
        setCheckListLoad(true)
        const Url = `${createCheckList}${formId}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // handleClick(formId)
                    const newCheckListObj = {
                        checklist_id: response.data.checklist_id,
                        ...data
                    }
                    setCheckLists(prev => [...prev, newCheckListObj]);

                    setCheckListLoad(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setValue('');
        setMentionedUsers([]);
        setAssignedUsers([]);
        setDueDate('')
    };


    function getNameById(id) {
        const item = usersList.find(obj => obj.value === id);
        return item ? item.label : null; // Return the name if found, otherwise return null
    }

    // console.log(checkListsData)

    const calculateCompletionPercentage = () => {
        const total = checkLists.length;
        const completedCount = checkLists.filter(list => list.completed).length;
        const percentage = (completedCount / total) * 100;
        return Math.round(percentage);
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor)
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        // console.log(active, over)
        if (active.id !== over.id) {
            setCheckLists((items) => {
                const oldIndex = items.findIndex(item => item.checklist_id === active.id);
                const newIndex = items.findIndex(item => item.checklist_id === over.id);

                // Move the items in the array
                const newItems = arrayMove(items, oldIndex, newIndex);
                const order = newItems.map(item => item.checklist_id);
                // console.log(order)
                updateChecklistOrderFn(order)
                // Update the position field
                return newItems.map((item, index) => ({
                    ...item,
                    position: index + 1 // assuming position is 1-based
                }));
            });
        }
    };

    const updateChecklistOrderFn = async (order) => {
        try {
            const data = {
                heading_id: checkListsData.id,
                order: order
            }
            const response = await axios.post(updateCheckListOrder, data);
            if (response.status === 200) {
                console.log('Order updated successfully');
            } else {
                console.error('Failed to update order');
            }
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    const sendChecklistNotification = async () => {
        try {

            const data = {
                checklist_heading_id: checkListsData.id,
                sales_users: selectedSalesUsers,
                pipeline_id: pipelineId
            }
            console.log(data)
            const response = await axios.post(sendInternalChecklistNotification, data);
            if (response.status === 200) {
                // console.log('Order updated successfully');
                toast.current.show({ severity: 'success', summary: '', detail: 'Success', life: 3000 });

            } else {
                console.error('Failed to update order');
            }
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    // console.log(hideCheckedItems)

    // const userTemplate = (option) => {
    //     return (
    //         <FlexComponent justifyContent="start">
    //             <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.label)}</span>} shape="circle" style={{ marginRight: '8px' }} />
    //             <span>{option.label}</span>
    //         </FlexComponent>
    //     );
    // };

    return (
        <div className='checkListDiv' style={{ marginTop: "1rem" }} >
            {/* <span style={{ cursor: "pointer" }} >⣿</span> */}
            <FlexComponent gap="1rem" >
                {!isEditTitle ?
                    <FlexComponent justifyContent="start" gap="1rem" >
                        <p><span style={{ marginLeft: "1rem" }} >{checkListHeading}</span> </p>
                        <i style={{ cursor: "pointer" }} onClick={() => {
                            setIsEditTitle(!isEditTitle)
                        }} className='pi pi-pencil'></i>
                    </FlexComponent> :
                    <FlexComponent justifyContent="start" gap="1rem" >
                        <InputText style={{ padding: ".3rem" }} id="title" value={editTitle} onChange={(e) => {
                            setEditTitle(e.target.value)
                        }} />
                        <i style={{ cursor: "pointer" }} onClick={() => {
                            handleUpdateCheckList(editTitle, checkListsData.id)
                            setIsEditTitle(!isEditTitle)
                        }} className='pi pi-save'></i>
                    </FlexComponent>
                }
                <FlexComponent justifyContent="end" gap="1rem" style={{ paddingRight: "1rem" }} >

                    <Button onClick={(e) => {
                        checklistNotifyOp.current.toggle(e)
                    }} style={{ boxShadow: "none", padding: "0.4rem 1rem", border: "1px solid grey" }}>Send</Button>

                    <OverlayPanel ref={checklistNotifyOp} style={{ background: "#254270" }} >

                        <FlexComponent justifyContent="center" gap=".5rem" style={{ marginTop: ".5rem" }}>
                            <MultiSelect
                                value={selectedSalesUsers}
                                options={salesMembersList}
                                itemTemplate={userTemplate}
                                style={{ width: '100%', color: 'black' }}
                                onChange={(event) => {
                                    setSelectedSalesUsers(event.value)
                                    // setLoading(true)
                                }}
                                placeholder='Select Members'
                                filter
                            />
                            <Button style={{ boxShadow: "none", padding: "0.4rem 1.4rem 0.4rem 1rem", border: "1px solid grey" }}
                                disabled={selectedSalesUsers.length === 0} onClick={(e) => {
                                    sendChecklistNotification()
                                    checklistNotifyOp.current.toggle(e)
                                    setSelectedSalesUsers([])
                                }}>Submit </Button>
                        </FlexComponent>

                    </OverlayPanel>

                    <Button style={{ boxShadow: "none", padding: "0.4rem 1rem", border: "1px solid grey" }} onClick={() => {
                        setHideCheckedItems(!hideCheckedItems)
                    }}
                    >{hideCheckedItems ? `Show Checked Items (${checkedCount})` : 'Hide Checked Items'}</Button>

                    <i style={{ cursor: "pointer" }} onClick={() => {
                        handleDeleteCheckList(checkListsData.id)
                    }} className='pi pi-trash'></i>

                    <i style={{ cursor: "pointer" }} onClick={() => {
                        setToggle(!toggle)
                    }} className={toggle ? 'pi pi-plus' : 'pi pi-minus'}></i>

                </FlexComponent>
            </FlexComponent>
            <div style={{ padding: "0 1rem" }} >
                <div className="card" style={{ borderRadius: "20px", marginTop: "0.5rem" }} >
                    <ProgressBar value={calculateCompletionPercentage()}></ProgressBar>
                </div>
            </div>
            <div style={{ marginTop: ".5rem", display: toggle ? "none" : "" }} >
                <div >
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext items={checkLists.map(item => item.checklist_id)} strategy={verticalListSortingStrategy}>
                            {checkLists.map((list, index) => (
                                <div>
                                    {(!hideCheckedItems || !list.completed) && <CheckListItem list={list} index={index} usersList={usersList} convertDateToString={convertDateToString} getNameById={getNameById}
                                        userTemplate={userTemplate} getInitials={getInitials} itemTemplate={itemTemplate} setIsCheckListEdit={setIsCheckListEdit} setCheckLists={setCheckLists}
                                        setIsCheckListAdd={setIsCheckListAdd} updateCheckList={updateCheckList} userId={userId} toast={toast} handleClose={handleClose} setValue={setValue}
                                        setMentionedUsers={setMentionedUsers} setAssignedUsers={setAssignedUsers} setDueDate={setDueDate} checkLists={checkLists} suggestions={suggestions}
                                        onSearch={onSearch} convertDateToDateString={convertDateToDateString} hideCheckedItems={hideCheckedItems} isCheckListEdit={isCheckListEdit}
                                        checkListEditIndex={checkListEditIndex} setCheckListEditIndex={setCheckListEditIndex}
                                    />
                                    }
                                </div>
                            ))}
                        </SortableContext>
                    </DndContext>
                </div>
                <Button onClick={handleAddCheckList} style={{ marginLeft: "1rem", padding: "0.4rem 1rem", border: "1px solid grey" }}>Add an Item</Button>
                <div style={{ margin: "1rem 0" }} >
                    {isCheckListAdd &&
                        <div  >
                            <Mention
                                value={value}
                                onChange={onChange}
                                suggestions={suggestions}
                                onSearch={onSearch}
                                field="label"
                                placeholder="Add an Item"
                                // rows={1}
                                // cols={50}
                                itemTemplate={itemTemplate}
                                style={{ width: "100%" }}
                                className="mention-input"
                            />
                            <FlexComponent >
                                <FlexComponent gap="0.5rem" justifyContent="start" style={{ width: "55%", marginTop: ".5rem" }} >
                                    <Button style={{ padding: "0.4rem 1rem", background: "#2FC635", border: "1px solid grey" }} disabled={!dueDate} onClick={() => {
                                        handleSaveCheckList()
                                    }} >Add</Button>
                                    <Button style={{ padding: "0.4rem 1rem", border: "1px solid grey" }} onClick={() => {
                                        setIsCheckListAdd(false)
                                        setAssignedUsers([])
                                        setValue('')
                                        setDueDate('')
                                    }} >Cancel</Button>
                                </FlexComponent>
                                <FlexComponent justifyContent="center" gap="1rem" >
                                    <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                        <span onClick={(e) => assignOp.current.toggle(e)} style={{ cursor: "pointer" }} ><i className="pi pi-user-plus" style={{ marginRight: '5px' }}></i>
                                            {assignedUsers.length > 0 ? (
                                                <>
                                                    {assignedUsers.map((id, index) => (
                                                        <>
                                                            {index == 0 && <span key={index}>{getNameById(id)}</span>}
                                                        </>
                                                    ))}
                                                    {assignedUsers.length > 1 && <span style={{ marginLeft: ".2rem", padding: ".2rem", background: "white", color: "black", borderRadius: "25px" }}>+{assignedUsers.length - 1}</span>}
                                                </>
                                            ) :
                                                'Assign'}
                                        </span>
                                    </FlexComponent>
                                    <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                        <span style={{ cursor: "pointer" }}
                                            onClick={(e) => dueDateOp.current.toggle(e)}
                                        ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>
                                            {dueDate ? <span>{convertDateToDateString(dueDate)}</span> : <span>Due Date</span>}
                                        </span>
                                    </FlexComponent>
                                </FlexComponent>

                            </FlexComponent>
                            <OverlayPanel ref={assignOp} style={{ background: '#1A2B53', maxWidth: "400px" }} showCloseIcon>
                                <MultiSelect
                                    value={assignedUsers}
                                    options={usersList}
                                    itemTemplate={userTemplate}
                                    style={{ width: '100%', color: 'black' }}
                                    onChange={(event) => {
                                        setAssignedUsers(event.value);
                                    }}
                                    placeholder='Select Members'
                                    filter
                                />
                            </OverlayPanel>
                            <OverlayPanel ref={dueDateOp} style={{ background: '#1A2B53' }} >
                                <Calendar id="buttondisplay" value={dueDate} inline onChange={(e) => {
                                    setDueDate(e.value)
                                    dueDateOp.current.toggle(e)
                                }} />
                            </OverlayPanel>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CheckList
