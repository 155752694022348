import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { AppUserList, getLeaderBoard } from '../../utils/urls';
import axios from '../../utils/axios';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import FlexComponent from '../FlexComponent';
import { Calendar } from 'primereact/calendar';
import { Avatar } from 'primereact/avatar';
import awardImg from '../../Images/awardImg.png'
import './leaderBoard.css'

export default function LeaderBoardTable({ isSuperuser }) {
    const navigate = useNavigate();
    const [appLeads, setAppLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    const [page, setPage] = useState('Top Calls');
    const [showMedal, setShowMedal] = useState(true)
    const [numberOneId, setNumberOneId] = useState("")

    const generateDateRange = () => {
        const today = new Date();
        const last7thDay = new Date();
        last7thDay.setDate(today.getDate() - 7);
        console.log(last7thDay)
        return [last7thDay, today];
    };
    const [dates, setDates] = useState(() => generateDateRange());

    useEffect(() => {
        if (dates[0] && dates[1]) {
            setLoading(true)
            const formatDate = (date) => date.toLocaleDateString('en-CA'); 

            const startDate = formatDate(dates[0]);
            const endDate = formatDate(dates[1]);
            axios.get(`${getLeaderBoard}?type=callers&start_date=${startDate}&end_date=${endDate}`, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response.data);
                        // if(page === "Top Calls"){
                        const updatedSortedData = [...response.data].sort((a, b) => {
                            return b["calls"] - a["calls"];
                        });
                        setAppLeads(updatedSortedData);
                        // }
                        if (page === "Top Apps") {
                            const sortedData = [...updatedSortedData].sort((a, b) => {
                                if (a["apps"] === b["apps"]) {
                                    if (a["apps"] === 0) return 0;
                                    // Perform secondary sorting when the primary key values are the same
                                    return a["calls"] - b["calls"];
                                }
                                return b["apps"] - a["apps"];
                            });
                            setAppLeads(sortedData);
                        }
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setAppLeads([]);
                    setLoading(false);
                });
        }
    }, [AppUserList, isMounted, dates]);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        calls: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        apps: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        company: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    // console.log(numberOneId)

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        // console.log(rowData)
        if (rowIndex === 1 && showMedal) {
            setNumberOneId(rowData.user_id)
        }
        return <FlexComponent key={rowIndex} justifyContent="start" gap="1rem" style={{ paddingLeft: "2rem" }} >
            <div style={{ width: "50px" }}>
                {(rowIndex === 1 && numberOneId === rowData.user_id) ? <img src={awardImg} alt="" style={{ width: "40px", height: "40px" }} /> : ""}
            </div>
            <div style={{ width: "30px" }}><span>{rowIndex}</span> </div>
            <Avatar size="medium" image={rowData?.image} shape='circle'
                className={rowIndex === 1 && numberOneId === rowData.user_id && page === "Top Calls" ? "numberOneCalls" : rowIndex === 1 && numberOneId === rowData.user_id && page === "Top Apps" ? "numberOneApps" : ""}
                label={<span style={{ padding: '5px' }}>{getInitials(rowData?.name)}</span>}
                style={{ background: 'white', border: '2px solid #8C8C8C', width: '40px', height: '40px' }} />
            <span>{rowData?.name}</span>
        </FlexComponent>;
    };

    const getTrailingColor = (number) => {
        if (!number) return ''
        if (number < 0) {
            return "red"
        } else {
            return "#2EBE27"
        }
    }

    const callsBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <FlexComponent key={rowIndex} justifyContent="start" gap=".2rem" >
            <span>{rowData.calls}</span>
            {rowData.trailing_calls !== 0 && <span style={{ fontSize: "10px", color: getTrailingColor(rowData.trailing_calls) }} >{rowData.trailing_calls > 0 && "+"}{rowData.trailing_calls}</span>}
        </FlexComponent>;
    };

    const appsBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <FlexComponent key={rowIndex} justifyContent="start" gap=".2rem" >
            <span>{rowData.apps}</span>
            {rowData.trailing_apps !== 0 && <span style={{ fontSize: "10px", color: getTrailingColor(rowData.trailing_apps) }} >{rowData.trailing_apps > 0 && "+"}{rowData.trailing_apps}</span>}
        </FlexComponent>;
    };


    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        if (value.trim()) {
            setShowMedal(false)
        } else {
            setShowMedal(true)
        }
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
    };
    // console.log(filters)
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';
        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
            </div>
        );
    };

    const sortData = (key, direction = 'desc') => {
        setLoading(true);
        const sortedData = [...appLeads].sort((a, b) => {
            // if (direction === 'asc') {
            //     return a[key] - b[key];
            // }
            return b[key] - a[key];
        });
        setAppLeads(sortedData);
        setLoading(false);
    };

    const sortAppsData = (key, secondaryKey, direction = 'desc') => {
        setLoading(true);
        const sortedData = [...appLeads].sort((a, b) => {
            if (a[key] === b[key]) {
                if (a[key] === 0) return 0;
                // Perform secondary sorting when the primary key values are the same
                return a[secondaryKey] - b[secondaryKey];
            }
            return b[key] - a[key];
        });
        setAppLeads(sortedData);
        setLoading(false);
    };


    const header = renderHeader();
    // console.log(dates)
    return (
        <div >
            <FlexComponent className='applicantDivs' justifyContent="end" gap="1rem">
                <div className='applicantDivs' style={{ color: "white", zIndex: "1", width: "30%", fontSize: "22px" }} >
                    Caller Leaderboard
                </div>
                <div >
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" />
                    {/* <label htmlFor="ms-cities">Select Date Range</label> */}
                </div>
                <div>
                    <Button
                        style={{
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                            fontWeight: "600",
                            background: page === 'Top Calls' ? '#0F173E' : 'white',
                            color: page === 'Top Calls' ? 'white' : 'black',
                            border: "1px solid grey"
                        }}
                        onClick={() => {
                            setPage('Top Calls');
                            sortData('calls', 'desc');
                        }}
                    >
                        Top Calls
                    </Button>
                    <Button
                        style={{
                            borderTopLeftRadius: "0",
                            borderRadius: "0 .5rem .6rem 0",
                            fontWeight: "600",
                            background: page === 'Top Apps' ? '#0F173E' : 'white',
                            color: page === 'Top Apps' ? 'white' : 'black',
                            border: "1px solid grey"
                        }}
                        onClick={() => {
                            setPage('Top Apps');
                            sortAppsData('apps', 'calls', 'desc');
                        }}
                    >
                        Top Apps
                    </Button>
                </div>
            </FlexComponent>
            <div className="card p-fluid leaderboardTable" style={{ marginTop: ".2rem" }} >
                <DataTable
                    value={appLeads}
                    size='small'
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 30, 50, 100]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    scrollable
                    scrollHeight="65vh"
                    header={header}
                    filters={filters}
                    loading={loading}
                    onFilter={(e) => setFilters(e.filters)}
                    dataKey="user_id"
                    // stateStorage="session"
                    // stateKey="caller-leader-board-table"
                    tableStyle={{ minWidth: '50rem' }}
                >
                    <Column header="" field="name" className="padding-0" body={srNoBodyTemplate} />
                    {/* <Column field="name" header="Name" style={{ minWidth: '13rem' }} /> */}
                    <Column field="company" header="Company" style={{ minWidth: isSuperuser ? '15rem' : '20rem', fontWeight: "bold", fontSize: "1.1rem" }} />
                    <Column field="calls" header="Total Calls" body={callsBodyTemplate} style={{ minWidth: isSuperuser ? '8rem' : '15rem', fontWeight: "bold", fontSize: "1.1rem" }} />
                    <Column field="apps" header="Total Apps" body={appsBodyTemplate} style={{ minWidth: isSuperuser ? '8rem' : '15rem', fontWeight: "bold", fontSize: "1.1rem" }} />
                    {isSuperuser && <Column field="caller_apps" sortable header="Caller Apps" style={{ minWidth: '10rem', fontWeight: "bold", fontSize: "1.1rem" }} />}
                    {isSuperuser && <Column field="total_docs_in_apps" sortable header="Total Docs in Apps" style={{ minWidth: '10rem', fontWeight: "bold", fontSize: "1.1rem" }} />}
                    {isSuperuser && <Column field="total_preapproved_apps" sortable header="Total Preapproved Apps" style={{ minWidth: '12rem', fontWeight: "bold", fontSize: "1.1rem" }} />}
                </DataTable>
            </div>
        </div>
    );
}
