import React, { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import FlexComponent from '../FlexComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createBlockSlot, deleteBlockedSlot, getAvailabilityslots, getBlockedSlots, upsertAvailabilitySlot } from '../../utils/urls';
import axios from "../../utils/axios"
import { ProgressSpinner } from 'primereact/progressspinner';
import './availability.css'
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';


const daysOfWeek = [
    { name: 'Monday', value: 0 },
    { name: 'Tuesday', value: 1 },
    { name: 'Wednesday', value: 2 },
    { name: 'Thursday', value: 3 },
    { name: 'Friday', value: 4 },
    { name: 'Saturday', value: 5 },
    { name: 'Sunday', value: 6 }
];

const Availability = ({ userId, toast, availabilityType, headingTitle }) => {

    const [loading, setLoading] = useState(false)

    const [availability, setAvailability] = useState([
        { day_of_week: 0, selected: false, slots: [] },
        { day_of_week: 1, selected: false, slots: [] },
        { day_of_week: 2, selected: false, slots: [] },
        { day_of_week: 3, selected: false, slots: [] },
        { day_of_week: 4, selected: false, slots: [] },
        { day_of_week: 5, selected: false, slots: [] },
        { day_of_week: 6, selected: false, slots: [] }
    ]);

    const [blockedSlots, setBlockedSlots] = useState([])
    const [blockedDate, setBlockedDate] = useState()
    const [startBlockedTime, setStartBlockedTime] = useState(null);
    const [endBlockedTime, setEndBlockedTime] = useState(null);
    const [blockedLoading, setBlokedLoading] = useState(false)

    const handleDayToggle = (dayIndex) => {
        setAvailability((prev) =>
            prev.map((day, index) =>
                index === dayIndex
                    ? {
                        ...day,
                        selected: !day.selected,
                        slots: !day.selected
                            ? [{ start_time: new Date(new Date().setHours(9, 0)), end_time: new Date(new Date().setHours(17, 0)) }] // Default 9 AM - 5 PM
                            : [], // Empty the slots when unchecked
                    }
                    : day
            )
        );
    };

    const handleSlotChange = (dayIndex, slotIndex, field, value) => {
        setAvailability((prev) =>
            prev.map((day, index) =>
                index === dayIndex
                    ? {
                        ...day,
                        slots: day.slots.map((slot, sIndex) =>
                            sIndex === slotIndex
                                ? { ...slot, [field]: value }
                                : slot
                        )
                    }
                    : day
            )
        );
    };

    const addSlot = (dayIndex) => {
        setAvailability((prev) =>
            prev.map((day, index) =>
                index === dayIndex
                    ? {
                        ...day,
                        slots: [...day.slots, { start_time: new Date(new Date().setHours(9, 0)), end_time: new Date(new Date().setHours(17, 0)) }] // New slot with default 9 AM - 5 PM
                    }
                    : day
            )
        );
    };

    const deleteSlot = (dayIndex, slotIndex) => {
        setAvailability((prev) =>
            prev.map((day, index) =>
                index === dayIndex
                    ? {
                        ...day,
                        slots: day.slots.filter((_, sIndex) => sIndex !== slotIndex)
                    }
                    : day
            )
        );
    };

    const formatTime = (time) => {
        const formatted = time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
        // Handle case where "24:xx" should be "00:xx"
        return formatted.startsWith('24') ? formatted.replace('24', '00') : formatted;
    };

    console.log(availability)
    const saveAvailability = () => {
        const formattedAvailability = availability.map((day) => ({
            ...day,
            slots: day.slots.map((slot) => ({
                start_time: slot.start_time
                    ? formatTime(slot.start_time)
                    : '',
                end_time: slot.end_time
                    ? formatTime(slot.end_time)
                    : ''
            }))
        }));

        console.log('Formatted Availability:', formattedAvailability);
        const data = {
            type: availabilityType,
            availabilty: formattedAvailability
        }
        axios.post(`${upsertAvailabilitySlot}${userId}`, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 201) {
                    // setCommentLoad(false)
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })

        // You can now use formattedAvailability for your API request or further processing.
    };

    const getTimeAvailabilities = () => {
        if (userId) {
            setLoading(true)
            const url = `${getAvailabilityslots}${userId}?type=${availabilityType}
`
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        let formatData = response.data.map(({ type, ...data }) => {
                            return {
                                ...data,
                                selected: data.slots.length > 0,
                                slots: data.slots.map((slot) => ({
                                    start_time: new Date(new Date().setHours(...slot.start_time.split(':').map(Number))),
                                    end_time: new Date(new Date().setHours(...slot.end_time.split(':').map(Number)))
                                }))
                            }
                        })
                        setAvailability(formatData)
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                    setLoading(false)
                })
        }
    }

    const getblockedSlotsFn = () => {
        if (userId) {
            // setLoading(true)
            const url = `${getBlockedSlots}${userId}?type=${availabilityType}
`
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setBlockedSlots(response.data)
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                    // setLoading(false)
                })
        }
    }

    const handleDeleteBlockedSlot = (slotId) => {
        const updatedData = blockedSlots.filter((slot) => slot.id !== slotId)
        setBlockedSlots(updatedData)
        axios.get(`${deleteBlockedSlot}${slotId}`, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setCommentLoad(false)
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                getblockedSlotsFn()
            })

    }

    const addBlockedSlot = () => {
        setBlokedLoading(true)
        if (!blockedDate || !startBlockedTime || !endBlockedTime) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please select date and times', life: 3000 });
            return;
        }

        const formattedBlockedSlot = {
            date: blockedDate.toISOString().split('T')[0], // Extract date in 'YYYY-MM-DD' format
            slot: {
                start_time: formatTime(startBlockedTime),
                end_time: formatTime(endBlockedTime),
            },
        };

        const data = {
            type: availabilityType,
            blocked: [formattedBlockedSlot]
        };

        axios.post(`${createBlockSlot}${userId}`, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 201) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Blocked slot added', life: 3000 });
                    getblockedSlotsFn();
                    setBlokedLoading(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setBlokedLoading(false)
            });
    };


    const formatTimeWithAmPm = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(":");
        const date = new Date();
        date.setHours(hours, minutes, seconds);

        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    useEffect(() => {
        getTimeAvailabilities()
        getblockedSlotsFn()
    }, [userId])

    // console.log(blockedDate);

    return (
        <FlexComponent className="availabilityContainer" flexDirection="column" style={{ boxShadow: "0px 0px 5px 2px", width: "98%", padding: "1rem", borderRadius: "1rem" }} >
            <FlexComponent justifyContent="center" >
                <div style={{ fontSize: "20px", fontWeight: "bold" }} >{headingTitle}</div>
            </FlexComponent>
            {!loading ? <>

                <FlexComponent className="availabilityContainer">
                    <div style={{ width: "70%" }} >
                        <FlexComponent style={{ padding: "1rem" }} className="availabilityContainer daysOfWeek ">
                            {availability.map((day, index) => (
                                <FlexComponent className="availabilityContainer" key={index} justifyContent="start" gap=".4rem" style={{ marginBottom: '1rem' }}>
                                    <Checkbox
                                        inputId={daysOfWeek[index].name}
                                        checked={day.selected}
                                        onChange={() => handleDayToggle(index)}
                                    />
                                    <label htmlFor={daysOfWeek[index].name}>{daysOfWeek[index].name}</label>
                                </FlexComponent>
                            ))}
                        </FlexComponent>
                        <FlexComponent flexDirection="column" justifyContent="start" className="availabilityContainer" alignItems="center" style={{ paddingLeft: "1rem", height: "65vh", overflowY: "scroll" }} >
                            {availability?.map((day, index) => (
                                <div key={index} style={{ marginBottom: '1rem' }}>
                                    {day.selected && (
                                        <div className="availabilityContainer"  >
                                            <FlexComponent className="availabilityContainer" alignItems="center" gap="1rem" style={{ marginTop: '1rem' }}>
                                                <div style={{ width: "100px" }} >{daysOfWeek[index].name}</div>
                                                <FlexComponent className="availabilityContainer" flexDirection="column" gap="1rem" style={{}} >
                                                    {day.slots.map((slot, slotIndex) => (
                                                        <div className="availabilityContainer" key={slotIndex} style={{ display: 'flex', gap: '1rem', marginTop: ".5rem" }}>
                                                            <DatePicker
                                                                selected={slot.start_time}
                                                                onChange={(date) => handleSlotChange(index, slotIndex, 'start_time', date)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Start Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="Start Time"
                                                            />
                                                            <DatePicker
                                                                selected={slot.end_time}
                                                                onChange={(date) => handleSlotChange(index, slotIndex, 'end_time', date)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="End Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="End Time"
                                                            />
                                                            <Button
                                                                label=""
                                                                icon="pi pi-trash"
                                                                disabled={day.slots.length === 1}
                                                                onClick={() => deleteSlot(index, slotIndex)}
                                                            />
                                                        </div>
                                                    ))}
                                                </FlexComponent>
                                            </FlexComponent>
                                            <div style={{ marginLeft: "30%" }} className='addSlotBtn' >
                                                <Button
                                                    label="Add Slot"
                                                    icon="pi pi-plus"
                                                    onClick={() => addSlot(index)}
                                                    style={{ marginTop: '1rem' }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </FlexComponent>
                        <FlexComponent justifyContent="center" style={{ position: "sticky", bottom: "0", background: "white" }} >
                            <Button
                                label="Save Availability"
                                icon="pi pi-save"
                                onClick={saveAvailability}
                                style={{ margin: '.5rem' }}
                            />
                        </FlexComponent>
                    <Divider/>
                    </div>

                    <div style={{ width: "30%" }} className="availabilityContainer">
                        <div style={{textAlign: "center", fontWeight: "bold", margin: "1rem 0"}} >Blocked Time Slots</div>
                        <FlexComponent  gap=".5rem" flexDirection="column" justifyContent="start" className="availabilityContainer" alignItems="start" style={{ padding: "0 1rem", height: "65vh", overflowY: "scroll" }} >
                            {blockedSlots?.map((slot, slotIndex) => (
                                <FlexComponent className="availabilityContainer" gap=".5rem">
                                    <div>{slot?.date}</div>
                                    <div style={{ border: "1px solid grey", padding: "10px", minWidth: "100px", borderRadius: "5px" }} >{formatTimeWithAmPm(slot?.start_time)}</div>
                                    <div style={{ border: "1px solid grey", padding: "10px", minWidth: "100px", borderRadius: "5px" }}>{formatTimeWithAmPm(slot?.end_time)}</div>
                                    <Button
                                        label=""
                                        icon="pi pi-trash"
                                        // disabled={day.slots.length === 1}
                                        onClick={() => handleDeleteBlockedSlot(slot.id)}
                                    />
                                </FlexComponent>
                            ))}
                            <Divider/>
                            <FlexComponent className='addBlockedSlots' >
                                <div>
                                    <Calendar
                                        inputId="DOB"
                                        dateFormat="yy/mm/dd"
                                        value={blockedDate}
                                        onChange={(e) => setBlockedDate(e.value)}
                                    />
                                </div>
                                <DatePicker
                                    selected={startBlockedTime}
                                    onChange={(date) => setStartBlockedTime(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Start Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Start Time"
                                />
                                <DatePicker
                                    selected={endBlockedTime}
                                    onChange={(date) => setEndBlockedTime(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="End Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="End Time"
                                />
                            </FlexComponent>

                            <FlexComponent justifyContent="center" style={{ position: "sticky", bottom: "0", background: "white" }} >
                                <Button
                                    label="Add a Slot to Blocked"
                                    icon="pi pi-plus"
                                    onClick={addBlockedSlot}
                                    style={{ margin: '.5rem' }}
                                    loading={blockedLoading}
                                    disabled={!blockedDate || !startBlockedTime || !endBlockedTime}
                                />
                            </FlexComponent>
                        </FlexComponent>

                    </div>

                </FlexComponent>
            </> :
                <ProgressSpinner />
            }
        </FlexComponent>
    );
};

export default Availability;
