import { useEffect, useState } from 'react';
import { deletePipeline, getPipelines, labelCreate, labelDelete, labelUpdate, upsertPipeline } from '../../utils/urls';
import axios from '../../utils/axios';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../FlexComponent';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Stages } from './Stages';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

function Pipelines({ toast }) {
    const [pipelineName, setPipelineName] = useState('');
    const [stageName, setStageName] = useState('');
    const [pipelineId, setPipelineId] = useState();
    const [pipeline, setPipeline] = useState();
    const [stages, setStages] = useState([]);
    const [pipelinesList, setPipelinesList] = useState([]);
    const [pipelineEditIndex, setPipelineEditIndex] = useState();
    const [isPipelineEdit, setIsPipelineEdit] = useState(false);
    const [isPipelineAdd, setIsPipelineAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsmounted] = useState(false);
    const userId = useSelector(state => state.user.userId);

    const handleClick = () => {
        setIsPipelineEdit(false);
    };

    const handleEdit = (list, index) => {
        setIsPipelineEdit(true);
        setPipelineEditIndex(index);
        setPipeline(list);
        setPipelineName(list.name)
        setStages(list.stages);
    };

    const getPipelinesList = () => {
        if (userId) {
            const pipelineUrl = `${getPipelines}?userId=${userId}`;
            axios.get(pipelineUrl, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setPipelinesList(response.data);
                        setLoading(false)
                    }
                })
                .catch(() => {
                    setLoading(false)
                 });
        }
    };

    const handleAddSubmit = () => {
        setIsmounted(true)
        // console.log(pipeline)
        const data = {
            type: "create",
            pipeline: {
                name: pipelineName
            },
            stages: stages.map(stage => ({
                ...stage,
                related_stages: stage.related_stages.map(related => related.id)
            }))
        }
        // console.log(data)
        setIsPipelineAdd(false)
        axios.post(upsertPipeline, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 201) {
                    // setLabelName('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsmounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    setIsmounted(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsmounted(false)
            })
    }

    const handleEditSubmit = () => {
        setIsmounted(true)
        // console.log(pipeline)
        // console.log(stages)
        const data = {
            type: "update",
            pipeline: {
                id: pipeline.id,
                name: pipelineName || pipeline.name
            },
            stages: stages.map(({ ...stage}) => {
                const newStage = {
                    ...stage,
                    // stage_due : {
                    //     days : due_in_days,
                    //     hours : due_in_hours,
                    //     minutes : due_in_minutes
                    // },
                    related_stages: stage.related_stages.map(related => typeof related === 'object' ? related.id : related)
                };
                if (typeof stage.id === 'string') {
                    delete newStage.id;
                }
                return newStage;
            }),
        }
        console.log(data)
        axios.post(upsertPipeline, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 201) {
                    // setLabelName('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsmounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    setIsmounted(false)
                }
            })
            .catch((error) => {
                // console.log(error)
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data, life: 3000 });
                setIsmounted(false)
            })
    }

    const handleDelete = (list) => {
        setIsmounted(true);
        confirmDialog({
            message: 'Are you sure you want to delete this Pipeline?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                
                const Url = `${deletePipeline}${list.id}`;
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsmounted(false);
                            toast.current.show({ severity: 'success', summary: 'Pipeline Deleted Successfully', detail: '', life: 3000 });
                            getPipelinesList();
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            setIsmounted(false);
                        }
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        setIsmounted(false);
                    });
            },
            reject: ()=> {
                console.log("closed")
            }
        });
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor)
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setStages((items) => {
                const oldIndex = items.findIndex(item => item.id === active.id);
                const newIndex = items.findIndex(item => item.id === over.id);

                // Move the items in the array
                const newItems = arrayMove(items, oldIndex, newIndex);

                // Update the position field
                return newItems.map((item, index) => ({
                    ...item,
                    position: index + 1 // assuming position is 1-based
                }));
            });
        }
    };

    useEffect(() => {
        getPipelinesList();
    }, [userId, isMounted]);

    return (
        <div>
            <ConfirmDialog />
            {pipelinesList?.length > 0 && (
                <div>
                    {pipelinesList.map((list, index) => (
                        <div key={index} style={{ marginTop: ".5rem" }}>
                            {!isPipelineEdit || pipelineEditIndex !== index ? (
                                <FlexComponent gap="1rem">
                                    <FlexComponent justifyContent="start" style={{ border: "1px solid grey", padding: "10px", borderRadius: "10px" }}>
                                        <p>{list.name}</p>
                                    </FlexComponent>
                                    <div style={{ width: "20%" }}>
                                        {!list?.is_ghl_pipeline ? (
                                            <FlexComponent>
                                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => handleEdit(list, index)}>
                                                    <i className='pi pi-pencil'></i>
                                                </Button>
                                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => handleDelete(list)}>
                                                    <i className='pi pi-trash'></i>
                                                </Button>
                                            </FlexComponent>
                                        ) : (
                                            <Button style={{ padding: ".5rem .6rem" }} onClick={() => handleEdit(list, index)}>
                                                <i className='pi pi-eye'></i>
                                            </Button>
                                        )}
                                    </div>
                                </FlexComponent>
                            ) : (
                                <Dialog header="Manage Stages" footer={
                                    <div>{!list?.is_ghl_pipeline && <Button disabled={!stages.length || !pipelineName.trim()} onClick={() => {
                                        setIsPipelineEdit(false);
                                        handleEditSubmit()
                                    }} >Save</Button>}</div>} visible={isPipelineEdit} style={{ width: '50vw', minHeight: '65vh' }} onHide={() => {
                                        setIsPipelineEdit(false);
                                        setPipeline();
                                        setStages([]);
                                    }}>
                                    <FlexComponent style={{ marginTop: "1rem" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='start'>
                                        <div className='inputContainerDiv' style={{ width: '50%' }}>
                                            <label htmlFor="pipelineName" style={{ fontWeight: 'bold' }}>Pipeline Name *</label>
                                            <InputText size="small" style={{ width: '100%' }} id="pipelineName" value={pipelineName}
                                                onChange={(e) => setPipelineName(e.target.value)}
                                                placeholder='Enter Pipeline Name'
                                                readOnly = {list?.is_ghl_pipeline}
                                            />
                                        </div>
                                        {/* <h3>{pipeline.name}</h3> */}
                                        <Divider />
                                        <label style={{ fontWeight: 'bold' }}>Stages</label>
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext items={stages.map(item => item.id)} strategy={verticalListSortingStrategy}>
                                                {stages.map((stage) => (
                                                    <Stages key={stage.id} toast={toast} stages={stages} setStages={setStages} pipelinesList={pipelinesList} pipeline={pipeline} id={stage.id} stage={stage} />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                        {!list?.is_ghl_pipeline && (
                                            <FlexComponent justifyContent="start" gap="1rem" className='inputContainerDiv' style={{ marginTop: ".5rem", width: '100%' }}>
                                                {/* <label htmlFor="stageName" style={{ fontWeight: 'bold' }}>Stage Name</label> */}
                                                <InputText size="small" style={{ width: '70%' }} id="stageName" value={stageName}
                                                    onChange={(e) => setStageName(e.target.value)}
                                                    placeholder='Enter Stage Name'
                                                />
                                                <Button disabled={!stageName.trim()} onClick={() => {
                                                    setStages(prev => ([...prev, { id: `${Date.now()}`, name: stageName, position: stages.length + 1, related_stages: [] }]));
                                                    setStageName('');
                                                }}>Add Stage</Button>
                                            </FlexComponent>
                                        )}
                                    </FlexComponent>
                                </Dialog>
                            )}
                        </div>
                    ))}
                </div>
            )}

                {loading && <FlexComponent justifyContent="center">
                    <ProgressSpinner />
                </FlexComponent>}

            <Button style={{ marginTop: ".5rem" }} onClick={() => {
                setIsPipelineAdd(true)
                setPipelineName('')
                }}>Add Pipeline</Button>
            <Dialog header="Add Pipeline" footer={
                <div><Button disabled={!stages.length || !pipelineName.trim()} onClick={() => {
                    handleAddSubmit()
                }} >Save</Button></div>}
                visible={isPipelineAdd} style={{ width: '50vw', minHeight: '65vh' }} onHide={() => {
                    setIsPipelineAdd(false)
                    setPipelineName('')
                    setStageName('')
                    setStages([])
                }}>
                <FlexComponent style={{ marginTop: "1rem" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='start'>
                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                        <label htmlFor="pipelineName" style={{ fontWeight: 'bold' }}>Pipeline Name *</label>
                        <InputText size="small" style={{ width: '100%' }} id="pipelineName" value={pipelineName}
                            onChange={(e) => setPipelineName(e.target.value)}
                            placeholder='Enter Pipeline Name'
                        />
                    </div>
                    <Divider />
                    <label style={{ fontWeight: 'bold' }}>Stages</label>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext items={stages.map(item => item.id)} strategy={verticalListSortingStrategy}>
                            {stages.map((stage) => (
                                <Stages key={stage.id} toast={toast} stages={stages} setStages={setStages} pipelinesList={pipelinesList} pipeline={pipeline} id={stage.id} stage={stage} />
                            ))}
                        </SortableContext>
                    </DndContext>
                    <FlexComponent justifyContent="start" gap="1rem" className='inputContainerDiv' style={{ marginTop: ".5rem", width: '100%' }}>
                        {/* <label htmlFor="stageName" style={{ fontWeight: 'bold' }}>Stage Name</label> */}
                        <InputText size="small" style={{ width: '70%' }} id="stageName" value={stageName}
                            onChange={(e) => setStageName(e.target.value)}
                            placeholder='Enter Stage Name'
                        />
                        <Button disabled={!stageName.trim()} onClick={() => {
                            setStages(prev => ([...prev, { id: `${Date.now()}`, name: stageName, position: stages.length + 1, related_stages: [] }]));
                            setStageName('');
                        }}>Add Stage</Button>
                    </FlexComponent>
                </FlexComponent>
            </Dialog>
        </div>
    );
}

export default Pipelines;
