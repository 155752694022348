import React, { useEffect, useState, useRef} from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import axios from '../../utils/axios';  
import { userList, userUpdate, getLanguageCampaign, getUser } from '../../utils/urls';
import { useParams } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export default function UserTable() {
    const navigate = useNavigate()
    const toast = useRef(null);
    const {locationId} = useParams()
    const url = `${userList}${locationId}`
    const [products, setProducts] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fullName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        languages: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    const [editingUserId, setEditingUserId] = useState('');
    const [intialLanguage, setInitialLanguage] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const [userLanguages, setUserLanguages] = useState('');
    const [userUrl, setUserUrl] = useState('');
    const [editVisible, setEditVisible] = useState(false);

    const [languages, setLanguages] = useState([])

    useEffect(()=>{
        // setLoading(true);
        axios.get(url, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setProducts(response.data);
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setProducts(null)
                
            })
    },[url, isMounted])

    useEffect(()=>{
        // setLoading(true);
        axios.get(getLanguageCampaign, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    // Convert the array to an array of objects with a "name" property
                    const languagesObjects = response.data.language_name.map(language => ({ name: language }));

                    setLanguages(languagesObjects)
                    
                  }
                })
            .catch((error)=>{
                setLanguages([])
                
            })
    },[getLanguageCampaign, isMounted])

    const getSeverity = (value) => {
        switch (value) {
            case 'Active':
                return 'success';

            case 'Inactive':
                return 'danger';

            default:
                return null;
        }
    };

    const onRowEditComplete = (e) => {
       
        const UpdateUrl = `${userUpdate}${editingUserId}`
        const data = {
            'location_id' : locationId,
            'languages': userLanguages,
            'bussiness_card_url' : userUrl
        }

       
        axios.post(UpdateUrl, data,{
            headers: {'Content-Type': 'application/json'},
        })
        .then((response)=>{
            if (response.status === 200){
                setEditingUserId(null)
                setUserLanguages(null)
                setUserUrl(null)
                // setProducts(response.data);
                setLoading(false);
                setIsMounted(!isMounted);
                setEditVisible(false)
                toast.current.show({severity:'success', summary: 'Success', detail:'User Details updated', life: 3000});
            }
            else if (response.status === 208){
                const response_message = response.data
                toast.current.show({severity:'error', summary: 'Error', detail: response_message, life: 3000}); 
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        }) 
        
        
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>;
    };

    const repliesConvTemplate = (rowData) => {
        const replies_conv = `${rowData.replies_conv}%`
        return replies_conv;
    };

    const oppConvTemplate = (rowData) => {
        const opp_conv = `${rowData.oppertunities_conv}%`
        return opp_conv;
    };

    const preAppConvTemplate = (rowData) => {
        const pre_apps_conv = `${rowData.pre_apps_conv}%`
        return pre_apps_conv;
    };


    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };


      const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };
    
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
        
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                
        );
    };

    const footerEditContent = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={onRowEditComplete} autoFocus />
        </div>
    );

    const handleEditOnHide = () =>{
        setUserLanguages('')
        setUserUrl('')
        setEditingUserId('')
        setEditVisible(false)
    }

    const handleGetUserDetails = (user_id) => {

        const getUrl = `${getUser}${user_id}`

        axios.get(getUrl,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                console.log(response.data)
                setUserLanguages(response.data.languages_object)
                setUserUrl(response.data.bussiness_card_url)
                setEditingUserId(response.data.user_id)
                setEditVisible(true)
            }
        })        
        
    }

    const editTemplate = (rowData) => {
        const user_id = rowData.user_id
        const icon = 'pi pi-pencil'

        return <Button type="button" icon={icon} className="p-button-sm p-button-text" onClick={()=>handleGetUserDetails(user_id)}/>;
    };

    const urlTemplate = (rowData) => {
        const maxLength = 20; // Maximum number of characters to display
        const bussiness_card_url = rowData.bussiness_card_url || ''; // Handle null or empty strings

        const displayUrl = bussiness_card_url.length > maxLength
        ? bussiness_card_url.slice(0, maxLength) + "..."
        : bussiness_card_url;

        return(
            <div className='status'>
            <a href={rowData.bussiness_card_url} target="_blank" rel="noopener noreferrer">{displayUrl}</a>
            </div>
        )
    };
    
    const header = renderHeader();

    return (
        <div className="card p-fluid">
            <Toast ref={toast} />
            <DataTable value={products} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)} editMode="row" dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem'  }} body={srNoBodyTemplate} />
                <Column field="fullName" header="Name" style={{ minWidth: '2rem' }} sortable></Column>
                <Column field="languages" header="Languages" style={{ minWidth: '2rem' }} sortable></Column>
                <Column field="bussiness_card_url" header="Biz Card URL" body={urlTemplate} style={{ minWidth: '10rem' }} sortable></Column>
                <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: '2rem' }} sortable></Column>
                <Column field="target" header="Target" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="received" header="Deliverd" style={{ minWidth: '1rem'}} sortable></Column>
                <Column field="remaining" header="Remaining" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="replies" header="Replies" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="replies_conv" header="Replies_Conv" body={repliesConvTemplate} style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="oppertunities" header="Oppertunities" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="oppertunities_conv" header="Opp_Conv" body={oppConvTemplate} style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="pre_apps" header="PreApp" style={{ minWidth: '1rem' }} sortable></Column>
                <Column field="pre_apps_conv" header="PreApp_Conv" body={preAppConvTemplate} style={{ minWidth: '1rem' }} sortable></Column>
                {/* <Column body={editTemplate} style={{ width: '2rem'  }}></Column> */}
            </DataTable>

            <Dialog header="Edit Purchase Order" visible={editVisible} style={{ width: '50vw' }} onHide={handleEditOnHide} footer={footerEditContent}>
            <div>
               
                <div className='purchase-form-group'>
                    
                    <div className="card flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                        <MultiSelect value={userLanguages} onChange={(e) => setUserLanguages(e.value)}  options={languages} optionLabel="name" 
                            placeholder="Select Languages" maxSelectedLabels={3} className="w-full md:w-20rem purchase-dropdown" />
                            <label htmlFor="currency-us">Languages</label>
                        </span>
                    </div>

                    <div className="card flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                        <InputText className='purchase-dropdown' value={userUrl} onChange={(e) => setUserUrl(e.target.value)} />
                            <label htmlFor="leadAmount">Biz Card URL</label>
                        </span>
                    </div>

                </div>

            </div>
            </Dialog>

        </div>
    );
}