import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Checkbox } from "primereact/checkbox";
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";  
import 'primeicons/primeicons.css';
import { purchaseList, purchaseFormData, purchaseCreate, purchaseInitiate, purchaseGet, purchaseEdit, purchasedelete } from '../../utils/urls';   
import axios from '../../utils/axios';       
import { useNavigate } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import './purchase.css'

export default function PurchaseTable() {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        location_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        user_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        campaign_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        language_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedLeadSpend, setSelectedLeadSpend] = useState('');
    const [selectedLeadAmount, setSelectedLeadAmount] = useState('');
    const [selectedEditCampaign, setSelectedEditCampaign] = useState('');
    const [selectedEditLocation, setSelectedEditLocation] = useState('');
    const [selectedEditUser, setSelectedEditUser] = useState('');
    const [selectedEditLanguage, setSelectedEditLanguage] = useState('');
    const [selectedEditLeadSpend, setSelectedEditLeadSpend] = useState('');
    const [selectedEditLeadAmount, setSelectedEditLeadAmount] = useState('');
    const [editPurchaseId, setEditPurchaseId] = useState('');
    const [fixedLeadSpend, setFixedLeadSpend] = useState('');
    const [fixedLeadAmount, setFixedLeadAmount] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [locations, setLocations] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);


    useEffect(()=>{
        // setLoading(true);
        axios.get(purchaseList, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data);
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[purchaseList, isMounted])

    useEffect(()=>{
        // setLoading(true);
        axios.get(purchaseFormData, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCampaigns(response.data.campaigns)
                    setLocations(response.data.locations)
                    setAllUsers(response.data.users)
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[purchaseList, isMounted])

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className="flex justify-content-between table-header-container">
                 <div className='sync-button-container'>
                    <Button className='sync-button' label="ADD" icon="pi pi-plus" onClick={addPurchaseOrder} />
                </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
            </div>
        );
    };

    const addPurchaseOrder = () =>{
        setVisible(true)
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

    const leadSpendBody = (rowData) => {
        const lead_spend = rowData.lead_spend
        return <div className='status'>$ {lead_spend}</div>;
    };

    const handlePurchaseFormSubmit = () => {
        if (selectedLeadSpend && selectedLeadAmount) {
            const currentDate = new Date();

            // Extract year, month, and day components from the date
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
            const day = String(currentDate.getDate()).padStart(2, '0');

            // Create the formatted date string 'YYYY-MM-DD'
            const formattedDate = `${year}-${month}-${day}`;

            const data = {
                'campaign_name' : selectedCampaign.name,
                'location_id' : selectedLocation.location_id,
                'user_id' : selectedUser.id,
                'language_name' : selectedLanguage.name,
                'lead_amount' : selectedLeadAmount,
                'lead_spend' : selectedLeadSpend,
                'date':formattedDate
            }

            axios.post(purchaseCreate, data,{
                headers: {'Content-Type': 'application/json'},
              })
            .then((response)=>{
                if (response.status === 201){
                    setSelectedCampaign('')
                    setSelectedLocation('')
                    setSelectedUser('')
                    setSelectedLanguage('')
                    setSelectedLeadAmount('')
                    setSelectedLeadSpend('')
                    setIsMounted(!isMounted);
                    setVisible(false);
                    toast.current.show({severity:'success', summary: 'Success', detail:'Purchase order created', life: 3000});
                }

                else if (response.status === 208){
                    const response_message = response.data
                    toast.current.show({severity:'error', summary: 'Error', detail: response_message, life: 3000}); 
                }
            })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            })         
        }
        else{

            toast.current.show({severity:'error', summary: 'Error', detail:'Please fill all fields', life: 3000});
        }
    };

    const handlePurchaseEditSubmit = () => {
        const url = `${purchaseEdit}${editPurchaseId}`
        const data = {
            'lead_amount' : selectedEditLeadAmount,
            'lead_spend' : selectedEditLeadSpend
        }
        axios.post(url, data,{
            headers: {'Content-Type': 'application/json'},
          })
          .then((response)=>{
            if (response.status === 200){
                setSelectedEditCampaign('')
                setSelectedEditLocation('')
                setSelectedEditUser('')
                setSelectedEditLanguage('')
                setSelectedEditLeadAmount('')
                setSelectedEditLeadSpend('')
                setFixedLeadAmount('')
                setFixedLeadSpend('')
                setEditPurchaseId('')
                setIsMounted(!isMounted);
                setEditVisible(false)
                toast.current.show({severity:'success', summary: 'Success', detail:'Purchase order updated', life: 3000});
            }

            else if (response.status === 208){
                const response_message = response.data
                toast.current.show({severity:'error', summary: 'Error', detail: response_message, life: 3000}); 
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        }) 

    }
    
    const footerContent = (
        <div>
            {selectedLanguage && (
                <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handlePurchaseFormSubmit} autoFocus />
            )}
        </div>
    );

    const footerEditContent = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handlePurchaseEditSubmit} autoFocus />
        </div>
    );
    

    const handleOnHide = () =>{
        setSelectedCampaign('')
        setSelectedLocation('')
        setSelectedUser('')
        setSelectedLanguage('')
        setVisible(false)
    }

    const handleEditOnHide = () =>{
        setSelectedEditCampaign('')
        setSelectedEditLocation('')
        setSelectedEditUser('')
        setSelectedEditLanguage('')
        setSelectedEditLeadAmount('')
        setSelectedEditLeadSpend('')
        setFixedLeadAmount('')
        setFixedLeadSpend('')
        setEditPurchaseId('')
        setEditVisible(false)
    }

    const handleLocationChange = (e) =>{
     
        setSelectedLocation(e.value);
        setSelectedUser('')
        setSelectedLanguage('')
        const filtered = filterUsersByLocation(allUsers, e.value);
        const formatted = formatFilteredUsers(filtered);
        setFilteredUsers(formatted)
          
    }


    const filterUsersByLocation = (users, selectedLocation) => {
        return users.filter(user => user.locationId.includes(selectedLocation.location_id));
    };

    const formatFilteredUsers = (filteredUsers) => {
    return filteredUsers.map((user, index) => ({
        id: user.id,
        name: user.name
    }));
    };


    const handleUserChange = (e) =>{
     
        setSelectedUser(e.value);
        setSelectedLanguage('')
        const filtered = filterLanguagesByUser(allUsers, e.value.name);
        setLanguages(filtered.language)
        console.log({selectedLeadAmount})
        console.log({selectedLeadSpend})
        
          
    }


    const handleIntiated = (initiate, purchase_id) =>{
        console.log(initiate)
        console.log(purchase_id)
        const initiate_url = `${purchaseInitiate}${purchase_id}`
        const intiate_data = {
            initiate
        }

        axios.post(initiate_url, intiate_data,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                setIsMounted(!isMounted);
                // toast.current.show({severity:'success', summary: 'Success', detail:'Purchase order created', life: 3000});
            }
            else if (response.status == 208){
                const response_message = response.data
                toast.current.show({severity:'error', summary: 'Error', detail: response_message, life: 3000}); 
            }
        })
        .catch((error)=>{
            console.log(error)
            // toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        })


    }

    const initiatedBodyTemplate = (rowData) => {
        const purchase_id = rowData.purchase_order_id
        return <Checkbox onChange={e => handleIntiated(e.checked, purchase_id)} checked={rowData.initiated}></Checkbox>;
    };

    const handleGetPurchase = (purchase_id) => {

        const getUrl = `${purchaseGet}${purchase_id}`

        axios.get(getUrl,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                console.log(response.data)
                setSelectedEditCampaign(response.data.campaign)
                setSelectedEditLocation(response.data.location)
                setSelectedEditUser(response.data.user)
                setSelectedEditLanguage(response.data.language)
                setSelectedEditLeadAmount(response.data.lead_amount)
                setSelectedEditLeadSpend(response.data.lead_spend)
                setFixedLeadAmount(response.data.received)
                setFixedLeadSpend(response.data.lead_spend)
                setEditPurchaseId(purchase_id)
                setEditVisible(true)
            }
        })        
        
    }

    const editTemplate = (rowData) => {
        const purchase_id = rowData.purchase_order_id
        const icon = 'pi pi-pencil'

        return <Button type="button" icon={icon} className="p-button-sm p-button-text" onClick={()=>handleGetPurchase(purchase_id)}/>;
    };

    const filterLanguagesByUser = (users, name) => {
        return users.find(user => user.name === name);
    };

    const accept = (data) => {
        axios.post(purchasedelete, data,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                setIsMounted(!isMounted);
                setVisible(false);
                toast.current.show({severity:'success', summary: 'Success', detail:'Purchase Order Deleted', life: 3000});
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        })         
    }

    const reject = () => {
        console.log('closed')
    }

    const handlePurchaseDelete = (purchase_id) => {
        const data = {
            purchase_id
        }
        
        confirmDialog({
            message: 'Are you sure you want to delete this purchase order?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(data),
            reject
        });

    }

    const deleteBody = (rowData) => {
        const purchase_id = rowData.purchase_order_id
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handlePurchaseDelete(purchase_id)}/>;
    };

    const header = renderHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable value={customers} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                    dataKey="id"
                    stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No purchase order found." tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ width: '2rem'  }} body={srNoBodyTemplate} />
                <Column field="campaign_name" header="Campaign" sortable style={{ width: '20%' }}></Column>
                <Column field="user_name" header="User" sortable style={{ width: '20%' }}></Column>
                <Column field="location_name" header="Team" sortable style={{ width: '20%' }}></Column>
                <Column field="date" header="Date" sortable style={{ width: '10%' }}></Column>
                <Column field="lead_spend" header="Lead Spend" body={leadSpendBody} sortable style={{ width: '10%' }}></Column>
                <Column field="lead_amount" header="Lead Amount" sortable style={{ width: '10%' }}></Column>
                <Column field="language_name" header="Language" sortable style={{ width: '10%' }}></Column>
                <Column field="initiated" header="Initiated" body={initiatedBodyTemplate} style={{ width: '20%' }}></Column>
                <Column body={editTemplate} style={{ width: '2rem'  }}></Column>
                <Column body={deleteBody} style={{ width: '10%' }}></Column>  
            </DataTable>
            <Dialog header="New Purchase Order" visible={visible} style={{ width: '50vw' }} onHide={handleOnHide} footer={footerContent}>
            <div>
                <div className='purchase-form-group'>
                    <div className="card flex justify-content-center form-field-container">
                        <Dropdown value={selectedCampaign} onChange={(e) => setSelectedCampaign(e.value)} options={campaigns} optionLabel="name" 
                            placeholder="Select a Campaign" filter className="w-full md:w-14rem purchase-dropdown" />
                    </div>

                    {selectedCampaign && (
                        <div className="card flex justify-content-center form-field-container">
                        <Dropdown value={selectedLocation} onChange={handleLocationChange} options={locations} optionLabel="name" 
                            placeholder="Select a Location" filter className="w-full md:w-14rem purchase-dropdown" />
                        </div>
                    )}
                </div>

                <div className='purchase-form-group'>
                    {selectedLocation && (
                        <div className="card flex justify-content-center form-field-container">
                        <Dropdown value={selectedUser} onChange={handleUserChange} options={filteredUsers} optionLabel="name" 
                            placeholder="Select a User" filter className="w-full md:w-14rem purchase-dropdown" />
                        </div>
                    )}

                    {selectedUser && (
                        <div className="card flex justify-content-center form-field-container">
                        <Dropdown value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.value)} options={languages} optionLabel="name" 
                            placeholder="Select a Language" filter className="w-full md:w-14rem purchase-dropdown" />
                        </div>
                    )}
                </div>
                
                {selectedLanguage && (
                <div className='purchase-form-group'>
                    
                    <div className="card flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                            <InputNumber disabled className='purchase-dropdown p-inputwrapper-focus' id="currency-us" value={selectedLeadSpend} onChange={(e) => setSelectedLeadSpend(e.value)} mode="currency" currency="USD" locale="en-US" min={0}/>
                            <label htmlFor="currency-us">Lead Spend</label>
                        </span>
                    </div>

                    <div className="card flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                            <InputNumber className='purchase-dropdown p-inputwrapper-focus' id="leadAmount" value={selectedLeadAmount} onChange={(e) => {setSelectedLeadAmount(e.value); setSelectedLeadSpend(e.value * 5)}} min={0} showButtons/>
                            <label htmlFor="leadAmount">Lead Amount</label>
                        </span>
                    </div>

                </div>
                
                )}
            </div>
            </Dialog>

            <Dialog header="Edit Purchase Order" visible={editVisible} style={{ width: '50vw' }} onHide={handleEditOnHide} footer={footerEditContent}>
            <div>
                <div className='purchase-form-group'>
                    <div className="card flex justify-content-center form-field-container">
                        <InputText disabled value={selectedEditCampaign.name} className="w-full md:w-14rem purchase-dropdown"/>
                    </div>

                    <div className="card flex justify-content-center form-field-container">
                        <InputText disabled value={selectedEditLocation.name} className="w-full md:w-14rem purchase-dropdown"/>
                    </div>
                </div>

                <div className='purchase-form-group'>
                    <div className="card flex justify-content-center form-field-container">
                        <InputText disabled value={selectedEditUser.name} className="w-full md:w-14rem purchase-dropdown"/>
                    </div>

                    <div className="card flex justify-content-center form-field-container">
                        <InputText disabled value={selectedEditLanguage.name} className="w-full md:w-14rem purchase-dropdown"/>
                    </div>
                </div>
                
                <div className='purchase-form-group'>
                    
                    <div className="card flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                            <InputNumber disabled className='purchase-dropdown' id="currency-us" value={selectedEditLeadSpend} onChange={(e) => {setSelectedEditLeadSpend(e.value); }} mode="currency" currency="USD" locale="en-US"/>
                            <label htmlFor="currency-us">Lead Spend</label>
                        </span>
                    </div>

                    <div className="card flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                            <InputNumber className='purchase-dropdown' id="leadAmount" value={selectedEditLeadAmount} onChange={(e) => {setSelectedEditLeadAmount(e.value); setSelectedEditLeadSpend(e.value * 5);}} min={fixedLeadAmount} showButtons/>
                            <label htmlFor="leadAmount">Lead Amount</label>
                        </span>
                    </div>

                </div>

            </div>
            </Dialog>
        </div>
    );
}