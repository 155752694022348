import { useEffect, useContext, useState } from 'react'
import { Nav } from "../../shared/Nav";
import { useNavigate } from 'react-router-dom';
import { getCompanies, getDesignations, verifyToken } from '../../utils/urls';
import jwt_decode from "jwt-decode";
import axios from '../../utils/axios'
import { RefreshToken } from '../../utils/refreshToken';
import Swal from 'sweetalert2';
import './teamLeader.css'
import LeaderTable from '../../components/Leaders/LeaderTable'
import GhlUserTable from '../../components/Leaders/LeaderTable'
import { useParams } from 'react-router-dom';
// import CampaignTable from '../../components/campaigns/CampaignTable';
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/userSlice';
import { useSelector } from 'react-redux'

const Wrapper = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  // const { isLoggedIn, setIsLoggedIn } = useContext(UserIsLoggedIn);
  const { locationId } = useParams();
  // const {userId} = useParams();
  const isSuperuser = useSelector((state) => state.user.isSuperuser);
  const image = useSelector((state) => state.user.image);
  const userLable = useSelector((state) => state.user.userLable);
  const userName = useSelector((state) => state.user.userName);
  const is_leader = useSelector((state) => state.user.is_leader);
  const [scrolling, setScrolling] = useState(false);
  const [designationList, setDesignationList] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const userId = useSelector((state) => state.user.userId);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    const authTokens = JSON.parse(localStorage.getItem('authTokens'))
    const refresh = authTokens?.refresh
    if (refresh) {
      const body = JSON.stringify({
        token: refresh,
      });
      axios
        .post(verifyToken, body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            const access = authTokens?.access
            const decodedToken = jwt_decode(access)
            const superUser = decodedToken.is_superuser
            if (superUser) {
              dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
              localStorage.setItem("prevUrl", window.location.pathname);
            }
            else {
              navigate('/')
            }
            // console.log(decodedToken.is_superuser)
            // if (decodedToken.is_superuser === true){
            //   localStorage.setItem("prevUrl", window.location.pathname);
            // }
            // else{
            //   localStorage.removeItem('authTokens');
            //   localStorage.removeItem('prevUrl');
            //   navigate('/login')
            // }

          }
        })
        .catch((err) => {
          localStorage.removeItem('authTokens');
          localStorage.removeItem('prevUrl');
          dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
          // dispatch(change({ id: null ,username: null, image: null }));
          // setIsLoggedIn(false)
          navigate('/login')
        });
    } else {
      localStorage.removeItem('authTokens');
      localStorage.removeItem('prevUrl');
      dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
      // dispatch(change({ id: null ,username: null, image: null }));
      // setIsLoggedIn(false)
      navigate('/login')
    }
  }, [])


  useEffect(() => {
    const intervalId = setInterval(async () => {
      await RefreshToken();
    }, 3600000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('authTokens');
        localStorage.removeItem('prevUrl');
        // dispatch(change({ username: null, image: null }));
        navigate('/login')
      }
    })
  }

  const getAllDesignations = () => {
    const url = `${getDesignations}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          setDesignationList(response.data)

        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  }

  const getAllCompanies = () => {
    const url = `${getCompanies}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          setCompaniesList(response.data)
          // setLoading(false)
        }
      })
      .catch((error) => {
        // setLoading(false)
      })
  }

  useEffect(() => {
    getAllDesignations()
    getAllCompanies()
  }, [userId])

  return (
    <>
      <header className={scrolling ? 'sticky' : ''}>
        <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
      </header>
      <div className='table-container'>
        <LeaderTable designationList={designationList} companiesList={companiesList}  />
      </div>
    </>
  );
}
export default Wrapper;