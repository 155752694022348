import React, { useEffect, useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import './form.css'
import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'

function ShortFormComponent({ setLanguageSelect, values, ghlUserId, locationData, errors, touched, handleBlur, handleChange }) {

    const [userOptions, setUserOptions] = useState([]);
    const [ghlUserName, setGhlUserName] = useState(null)

    useEffect(() => {
        if(ghlUserId){
            handleChange('application_by')(ghlUserId)
            setGhlUserName(findFullNameById(ghlUserId))
        }
        const selectedLocation = values.which_homes_for_sale_region;
        const selectedLocationData = locationData?.find((location) => location.location_id === selectedLocation);
        const options = selectedLocationData?.users?.map((user) => ({
            label: user.full_name || user.name,
            value: user.user_id,
        })) || [];
        setUserOptions(options);
    }, [locationData, values.which_homes_for_sale_region, ghlUserId]);

    const findFullNameById = (userId) => {
        if(locationData){
            for (let location of locationData) {
                for (let user of location.users) {
                    if (user.user_id === userId) {
                        return user.full_name;
                    }
                }
            }
        }
        return null; // Return null if the user is not found
    };

    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='getStarted'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>I can definitely help. Let's get started!</p>
            </div>

            <FlexComponent className='inputContainer' gap='1rem' alignItems='start' style={{ padding: "1rem 0" }}>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '65%', margin: '0.5rem' }}>
                    <label htmlFor="name" style={{ color: Boolean((touched.firstName && errors.firstName) || (touched.middleName && errors.middleName) || (touched.lastName && errors.lastName)) ? 'red' : '', fontWeight: 'bold' }}>Name *</label> <br />
                    <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                        <div className='inputContainerDiv'>
                            <Field type="text" id="firstName" name="firstName" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            <label htmlFor="firstName" style={{ color: Boolean(touched.firstName && errors.firstName) ? 'red' : '' }}>First</label>
                            {/* <ErrorMessage name="firstName" component="div" className="error" style={{color:'red'}} /> */}
                        </div>
                        <div className='inputContainerDiv'>
                            <Field type="text" id="middleName" name="middleName" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            <label htmlFor="middleName" style={{ color: Boolean(touched.middleName && errors.middleName) ? 'red' : '' }}>Middle</label>
                            {/* <ErrorMessage name="middleName" component="div" className="error" style={{color:'red'}}/> */}
                        </div>
                        <div className='inputContainerDiv'>
                            <Field type="text" id="lastName" name="lastName" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            <label htmlFor="lastName" style={{ color: Boolean(touched.lastName && errors.lastName) ? 'red' : '' }}>Last</label>
                            {/* <ErrorMessage name="lastName" component="div" className="error" style={{color:'red'}}/> */}
                        </div>

                    </FlexComponent>
                </div>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '35%', margin: '0.5rem' }}>
                    <label htmlFor="language" style={{ fontWeight: 'bold', color: Boolean(touched.language && errors.language) ? 'red' : '' }}>Language *</label> <br />
                    {['English', 'Spanish', 'Spanish & Some English', 'Other'].map((lang) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={lang} style={{ marginTop: '0.5rem' }}>
                                {/* <Field type="radio" id={lang} name="language" value={lang} as={RadioButton} /> */}
                                <RadioButton inputId="language" name="language" value={lang} onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name: `language`,
                                            value: e.value
                                        }
                                    })
                                    setLanguageSelect(e.value)
                                }} checked={values.language === lang} />
                                <label htmlFor={lang} className="ml-2">{lang}</label>

                            </FlexComponent >
                        );
                    })}
                    {values.language === 'Other' && (
                        <div style={{ marginTop: '1rem' }}>
                            <Field type="text" placeholder="Other" id="otherLanguage" name="otherLanguage" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                        </div>
                    )}

                </div>
            </FlexComponent>
            <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                    <label htmlFor="cell_phone_number" style={{ fontWeight: 'bold', color: Boolean(touched.cell_phone_number && errors.cell_phone_number) ? 'red' : '' }}>Cell Phone Number *</label>
                    <div className="p-inputgroup flex-1 login-input-div">
                        <span className="p-inputgroup-addon" style={{ background: 'white' }}>
                            +1
                        </span>
                        <Field mask="(999) 999-9999" type="text" id="cell_phone_number" name="cell_phone_number" as={InputMask} style={{ width: '100%' }} onBlur={handleBlur} />
                    </div>
                    {/* <ErrorMessage name="cell_phone_number" component="div" className="error" style={{color:'red'}} /> */}
                </div>
                <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                    <label htmlFor="email" style={{ fontWeight: 'bold', color: Boolean(touched.email && errors.email) ? 'red' : '' }}>Email *</label>
                    <Field type="text" id="email" name="email" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                    {/* <ErrorMessage name="email" component="div" className="error" style={{color:'red'}}/> */}
                </div>

            </FlexComponent>

            <FlexComponent className='inputContainer' gap='1rem' alignItems='start' flexWrap="wrap">
                    <div className='inputContainerDiv' style={{ margin: '0.5rem', width: "45%" }}>
                        <label htmlFor="lead_source" style={{ fontWeight: 'bold', color: Boolean(touched.lead_source && errors.lead_source) ? 'red' : '' }}>Lead Source *</label> <br />
                        {["Homes For Sale Lead", "Lead Derived Referral", "Lead Derived Referral Source", "Self Sourced", "Other"].map((option) => {
                            return (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field type="radio" id={option} name="lead_source" value={option} as={RadioButton} />
                                    <label htmlFor={option} className="ml-2">{option}</label>

                                </FlexComponent >
                            );
                        })}
                        {values.lead_source === 'Other' && (
                            <div style={{ marginTop: '1rem' }}>
                                <Field type="text" placeholder="Other" id="other_lead_source" name="other_lead_source" as={InputText} style={{ width: '100%' }} />
                            </div>
                        )}

                    </div>

                    {/* {values.lead_source === 'Homes For Sale Lead' && ( */}
                        <div className='inputContainerDiv' style={{ margin: '0.5rem', width: '45%' }}>
                        <label htmlFor='which_homes_for_sale_region' style={{ fontWeight: 'bold', color: Boolean(touched.which_homes_for_sale_region && errors.which_homes_for_sale_region) ? 'red' : '' }}>Which Homes For Sale Region?*</label>
                        <Dropdown
                            id='which_homes_for_sale_region'
                            value={values.which_homes_for_sale_region}
                            options={locationData?.map((location) => ({
                                label: location.location_name,
                                value: location.location_id,
                            })) || []}
                            style={{ width: '100%' }}
                            onChange={(event) => handleChange('which_homes_for_sale_region')(event.value)}
                            onBlur={handleBlur}
                            placeholder='Select'
                            filter
                        />
                    </div>
                    {/* )} */}

                    {/* {values.lead_source !== 'Self Sourced' && <div className='inputContainerDiv' style={{ margin: '0.5rem', width: '35%' }}>
                        <label htmlFor="does_buyer_name_match_original_lead" style={{ fontWeight: 'bold' }}>Does the buyer name match the original lead?</label> <br />
                        {["Yes", "No", "Not a lead"].map((option) => {
                            return (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field type="radio" id={option} name="does_buyer_name_match_original_lead" value={option} as={RadioButton} />
                                    <label htmlFor={option} className="ml-2">{option}</label>

                                </FlexComponent >
                            );
                        })}
                    </div>} */}

                    {/* {values.does_buyer_name_match_original_lead === 'No' && (
                        <FlexComponent flexWrap="no-wrap" style={{ width: "60%" }}>
                            <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                                <label htmlFor="name_of_lead" style={{ fontWeight: 'bold' }}>Name of Lead</label>
                                <Field type="text" id="name_of_lead" name="name_of_lead" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            </div>
                            <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                                <label htmlFor="cell_number_of_lead" style={{ fontWeight: 'bold' }}>Cell number of Lead</label>
                                <Field  mask="(999) 999-9999" type="text" id="cell_number_of_lead" name="cell_number_of_lead" as={InputMask} style={{ width: '100%' }} onBlur={handleBlur} />
                            </div>
                        </FlexComponent>
                    )} */}

                    <div className='inputContainerDiv' style={{ margin: '0.5rem', width: '45%' }}>
                        <label htmlFor='application_pipeline' style={{ fontWeight: 'bold', color: Boolean(touched.application_pipeline && errors.application_pipeline) ? 'red' : '' }}>Application Pipeline *</label>
                        <Dropdown
                            id='application_pipeline'
                            value={values.application_pipeline}
                            options={userOptions}
                            style={{ width: '100%' }}
                            onChange={(event) => handleChange('application_pipeline')(event.value)}
                            onBlur={handleBlur}
                            placeholder='Select'
                            filter
                        />
                    </div>

                    <div className='inputContainerDiv' style={{ margin: '0.5rem', width: '45%' }}>
                        <label htmlFor='application_by' style={{ fontWeight: 'bold', color: Boolean(touched.application_by && errors.application_by) ? 'red' : '' }}>Application By *</label>
                        {ghlUserId?
                            <InputText disabled value={ghlUserName} style={{ width: '100%' }}/>
                            :
                            <Dropdown
                                id='application_by'
                                value={values.application_by}
                                options={userOptions}
                                style={{ width: '100%' }}
                                onChange={(event) => handleChange('application_by')(event.value)}
                                onBlur={handleBlur}
                                placeholder='Select'
                                filter
                            />
                        }
                    </div>

                </FlexComponent>

        </div>
    )
}

export default ShortFormComponent