import { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { getTeams, deleteTeam, teamLocations, teamLocationManage } from '../../utils/urls';   
import axios from '../../utils/axios';       
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';

export default function TeamsTable() {
    const navigate = useNavigate()
    const toast = useRef(null);
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const [locationVisible, setLocationVisible] = useState(false);
    const [allowedLocations, setAllowedLocations] = useState(null);
    const [activeLocations, setActiveLocations] = useState(null);
    const [manageTeamId, setManageTeamId] = useState(null);

    useEffect(()=>{
        // setLoading(true);
        axios.get(getTeams, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setCustomers(response.data);
                    setLoading(false);
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[getTeams, isMounted])

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const getSeverity = (value) => {
        switch (value) {
            case true:
                return 'success';

            case false:
                return 'danger';

            default:
                return null;
        }
    };

    const manageLocationView = (rowData) => {
        const team_id = rowData.team_id
        return <Button className='view-button' label="Manage" icon="pi pi-external-link" onClick={()=>manageLocationDialog(team_id)} />
    };

    const manageLocationDialog = (team_id) =>{
        const manageLocationUrl = `${teamLocations}${team_id}`

        axios.get(manageLocationUrl, {
            headers: {'Content-Type': 'application/json'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setActiveLocations(response.data.all_active_locations);
                    setAllowedLocations(response.data.allowed_locations);
                    setManageTeamId(team_id)
                    setLocationVisible(true)
                  }
                })
            .catch((error)=>{
                setActiveLocations(null)
                setAllowedLocations(null)
                setManageTeamId(null)
                setLocationVisible(false)
                
            })
    }

    const accept = (Data) => {
        axios.post(deleteTeam, Data,{
        headers: {'Content-Type': 'application/json'},
        })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });   
            }
            })
        .catch((error)=>{
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });   
        })
    }

    const reject = () => {
        console.log('closed')
    }

    const deleteBody = (rowData) => {
        const team_id = rowData.team_id
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleDeleteLeader(team_id)}/>;
    };

    const handleDeleteLeader = (team_id) => {
        const data = {
            team_id
        }
        
        confirmDialog({
            message: 'Are you sure you want to delete this Team?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(data),
            reject
        });
    }


    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            // <div className="flex justify-content-between table-header-container">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
                // <Button className='sync-button' label="Sync" icon="pi pi-sync" onClick={syncHandle} />
            // </div>
        );
    };

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

      const handleLocationOnHide = () =>{
        setActiveLocations(null)
        setAllowedLocations(null)
        setManageTeamId(null)
        setLocationVisible(false)
    }

    const handleLocationSubmit = () =>{
        const data = {
            "team_id": manageTeamId,
            "allowed_locations": allowedLocations
        }
        axios.post(teamLocationManage, data,{
            headers: {'Content-Type': 'application/json'},
        })
        .then((response) => {
            if (response.status === 200) {
                setActiveLocations(null)
                setAllowedLocations(null)
                setManageTeamId(null)
                setLocationVisible(false)
                setIsMounted(!isMounted)
                toast.current.show({severity:'success', summary: 'Success', detail:'', life: 3000});                
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});          
        })
    }

    const locationManageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleLocationSubmit} autoFocus />
        </div>
    );

    const header = renderHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable value={customers} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                    dataKey="team_id"
                    stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No Teams found." tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ minWidth: '2rem'  }} body={srNoBodyTemplate} />
                <Column field="name" header="Team" sortable style={{ minWidth: '2rem' }}></Column>
                <Column field="location_count" header="Locations" sortable style={{ minWidth: '2rem' }}></Column>
                <Column header="Manage Locations" body={manageLocationView} style={{ minWidth: '2rem' }}></Column>
                <Column body={deleteBody} style={{ width: '10%' }}></Column>
            </DataTable>

            <Dialog header="Manage Allowed Locations" visible={locationVisible} style={{ width: '50vw' }} onHide={handleLocationOnHide} footer={locationManageFooter}>
            <div>
                <div className='purchase-form-group'>
                <div className="card flex justify-content-center">
                <MultiSelect value={allowedLocations} onChange={(e) => setAllowedLocations(e.value)} options={activeLocations} optionLabel="name" 
                    filter placeholder="Manage Locations" maxSelectedLabels={3} className="w-full md:w-20rem language-multiselect" />
                </div>
                </div>
            </div>
            </Dialog>
        </div>
    );
}