import { useEffect, useState } from 'react';
import { createCheckListTemplateHeading, createCheckListTemplateItem, deleteCheckListTemplateHeading, deletePipeline, getCheckListTemplate, getCheckListTemplateHeadings, getPipelines, labelCreate, labelDelete, labelUpdate, updateCheckListTemplateHeading, updateCheckListTemplateItemsOrder, upsertPipeline } from '../../utils/urls';
import axios from '../../utils/axios';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../FlexComponent';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ChecklistItems } from './ChecklistItems';

function CheckListTemplates({ toast }) {
    const [headingName, setHeadingName] = useState('');
    const [isHeadingNameEdit, setIsHeadingNameEdit] = useState(false)
    const [headingId, setHeadingId] = useState()
    const [heading, setHeading] = useState('')
    const [item, setItem] = useState('');

    const [checklistItems, setChecklistItems] = useState([]);
    const [itemsLoading, setItemsLoading] = useState(false)
    const [checklistHeadingsList, setChecklistHeadingsList] = useState([]);
    const [headingEditIndex, setHeadingEditIndex] = useState();
    const [isHeadingEdit, setIsHeadingEdit] = useState(false);
    const [isCheckListHeadingAdd, setIsChecklistHeadingAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsmounted] = useState(false);
    const userId = useSelector(state => state.user.userId);

    const handleClick = () => {
        setIsHeadingEdit(false);
    };

    const getChecklistItems = (id, loading = true) => {
        if (loading) {
            setItemsLoading(true)
        }
        if (userId) {
            const url = `${getCheckListTemplate}${id}`;
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setChecklistItems(response.data.items);
                        setItemsLoading(false)
                    }
                })
                .catch(() => {
                    setItemsLoading(false)
                });
        }
    };

    const handleEdit = (list, index) => {
        setIsHeadingEdit(true);
        setHeadingEditIndex(index);
        setHeadingName(list.name)
        setHeadingId(list.id)
        getChecklistItems(list.id);
    };

    const getChecklistHeadingsList = () => {
        if (userId) {
            const url = `${getCheckListTemplateHeadings}`;
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setChecklistHeadingsList(response.data);
                        setLoading(false)
                    }
                })
                .catch(() => {
                    setLoading(false)
                });
        }
    };

    const handleAddChecklistHeading = () => {
        // setIsMounted(true)
        setIsChecklistHeadingAdd(false)
        const data = {
            name: heading,
        }
        axios.post(createCheckListTemplateHeading, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setHeading('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    getChecklistHeadingsList()
                    // setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleAddChecklistItem = (id) => {
        // setIsMounted(true)
        // setItemsLoading(true)
        const data = {
            heading_id: id,
            item: item
        }
        axios.post(`${createCheckListTemplateItem}`, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    getChecklistItems(id, false)
                    // setItemsLoading(false)
                    // setIsMounted(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setItemsLoading(false)
            })
    }


    const handleTemplateDelete = (list) => {
        setIsmounted(true);
        confirmDialog({
            message: 'Are you sure you want to delete this Checklist Template?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                const Url = `${deleteCheckListTemplateHeading}${list.id}`;
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsmounted(false);
                            toast.current.show({ severity: 'success', summary: 'Pipeline Deleted Successfully', detail: '', life: 3000 });
                            getChecklistHeadingsList();
                        } else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            setIsmounted(false);
                        }
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        setIsmounted(false);
                    });
            },
            reject: () => {
                console.log("closed")
            }
        });
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor)
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setChecklistItems((items) => {
                const oldIndex = items.findIndex(item => item.checklist_id === active.id);
                const newIndex = items.findIndex(item => item.checklist_id === over.id);

                // Move the items in the array
                const newItems = arrayMove(items, oldIndex, newIndex);
                const order = newItems.map(item => item.checklist_id);

                updateChecklistOrderFn(order)
                // Update the position field
                return newItems.map((item, index) => ({
                    ...item,
                    position: index + 1 // assuming position is 1-based
                }));
            });
        }
    };

    const updateChecklistOrderFn = async (order) => {
        try {
            const data = {
                heading_id: headingId,
                order: order
            }
            const response = await axios.post(updateCheckListTemplateItemsOrder, data);
            if (response.status === 200) {
                console.log('Order updated successfully');
            } else {
                console.error('Failed to update order');
            }
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    const handleChecklistHeadingEdit = async () => {
        try {
            const data = {
                name: headingName
            }
            const response = await axios.post(`${updateCheckListTemplateHeading}${headingId}`, data);
            if (response.status === 200) {
                // console.log('Order updated successfully');
                setIsmounted(!isMounted)
            } else {
                console.error('Failed to update order');
            }
        } catch (error) {
            console.error('Error updating order:', error);
        }
    }

    useEffect(() => {
        getChecklistHeadingsList();
    }, [userId, isMounted]);

    return (
        <div>
            <ConfirmDialog />
            {checklistHeadingsList?.length > 0 && (
                <div>
                    {checklistHeadingsList.map((list, index) => (
                        <div key={index} style={{ marginTop: ".5rem" }}>
                            {!isHeadingEdit || headingEditIndex !== index ? (
                                <FlexComponent gap="1rem">
                                    <FlexComponent justifyContent="start" style={{ border: "1px solid grey", padding: "10px", borderRadius: "10px" }}>
                                        <p>{list.name}</p>
                                    </FlexComponent>
                                    <div style={{ width: "20%" }}>
                                        {!list?.is_ghl_pipeline ? (
                                            <FlexComponent>
                                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => handleEdit(list, index)}>
                                                    <i className='pi pi-pencil'></i>
                                                </Button>
                                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => handleTemplateDelete(list)}>
                                                    <i className='pi pi-trash'></i>
                                                </Button>
                                            </FlexComponent>
                                        ) : (
                                            <Button style={{ padding: ".5rem .6rem" }} onClick={() => handleEdit(list, index)}>
                                                <i className='pi pi-eye'></i>
                                            </Button>
                                        )}
                                    </div>
                                </FlexComponent>
                            ) : (
                                <Dialog header="Manage Items"
                                    // footer={
                                    //     <div>{!list?.is_ghl_pipeline && <Button disabled={!checklistItems.length || !headingName.trim()} onClick={() => {
                                    //         setIsHeadingEdit(false);
                                    //         handleChecklistHeadingEdit()
                                    //     }} >Save</Button>}</div>}
                                    visible={isHeadingEdit} style={{ width: '50vw', minHeight: '65vh' }} onHide={() => {
                                        setIsHeadingEdit(false);
                                        setIsHeadingNameEdit(false)
                                        setChecklistItems([]);
                                    }}>
                                    <FlexComponent style={{ marginTop: "1rem" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='start'>
                                        <div className='inputContainerDiv' style={{ width: '50%' }}>
                                            <label htmlFor="headingName" style={{ fontWeight: 'bold' }}>Checklist Heading *</label>
                                            <FlexComponent gap="1rem" style={{ marginTop: ".5rem" }}  >
                                                {isHeadingNameEdit ? <InputText size="small" style={{ width: '100%' }} id="headingName" value={headingName}
                                                    onChange={(e) => setHeadingName(e.target.value)}
                                                    placeholder='Enter Checklist Heading'
                                                /> : <div style={{ border: "1px solid grey", width: "100%", padding: ".6rem 1rem", borderRadius: "10px" }} >{headingName}</div>}
                                                {isHeadingNameEdit ? <Button disabled={!headingName.trim()} onClick={() => {
                                                    setIsHeadingNameEdit(!isHeadingNameEdit)
                                                    handleChecklistHeadingEdit()
                                                }} >Save</Button> :
                                                    <Button onClick={() => {
                                                        setIsHeadingNameEdit(!isHeadingNameEdit)
                                                    }} >Edit</Button>
                                                }
                                            </FlexComponent>
                                        </div>
                                        <Divider />
                                        <label style={{ fontWeight: 'bold' }}>Items</label>

                                        {itemsLoading && <FlexComponent justifyContent="center">
                                            <ProgressSpinner />
                                        </FlexComponent>}

                                        {!itemsLoading && <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}
                                        >
                                            <SortableContext items={checklistItems.map(item => item.checklist_id)} strategy={verticalListSortingStrategy}>
                                                {checklistItems.map((item) => (
                                                    <ChecklistItems key={item.checklist_id} toast={toast} checklistItems={checklistItems} setChecklistItems={setChecklistItems} checklistHeadingsList={checklistHeadingsList} id={item.checklist_id} item={item} />
                                                ))}
                                            </SortableContext>
                                        </DndContext>}
                                        {!list?.is_ghl_pipeline && (
                                            <FlexComponent justifyContent="start" gap="1rem" className='inputContainerDiv' style={{ marginTop: ".5rem", width: '100%' }}>
                                                {/* <label htmlFor="item" style={{ fontWeight: 'bold' }}>Stage Name</label> */}
                                                <InputText size="small" style={{ width: '70%' }} id="item" value={item}
                                                    onChange={(e) => setItem(e.target.value)}
                                                    placeholder='Enter Item'
                                                />
                                                <Button disabled={!item.trim()} onClick={() => {
                                                    // setChecklistItems(prev => ([...prev, { checklist_id : `${Date.now()}`, item: item, position: checklistItems.length + 1 }]));
                                                    handleAddChecklistItem(list.id)
                                                    setItem('');
                                                }}>Add Item</Button>
                                            </FlexComponent>
                                        )}
                                    </FlexComponent>
                                </Dialog>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {loading && <FlexComponent justifyContent="center">
                <ProgressSpinner />
            </FlexComponent>}

            <Button style={{ marginTop: ".5rem" }} onClick={() => {
                setIsChecklistHeadingAdd(true)
            }} >Add Checklist Heading</Button>
            {isCheckListHeadingAdd && <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>

                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <label htmlFor="heading" style={{ fontWeight: 'bold' }}>Title *</label>
                    <InputText size="small" style={{ width: '100%' }} id="heading" value={heading}
                        onChange={(e) => {
                            setHeading(e.target.value)
                        }}
                    />
                </div>

                <FlexComponent justifyContent="center" gap="1rem" >
                    <Button type='button' onClick={handleAddChecklistHeading} disabled={!heading.trim()} >Save</Button>
                    <Button style={{ background: "white", color: "black" }} type='button' onClick={() => {
                        setIsChecklistHeadingAdd(false)
                    }} >Cancel</Button>
                </FlexComponent>
            </FlexComponent>}
        </div>
    );
}

export default CheckListTemplates;
