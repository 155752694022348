import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import './form.css'

function HowMuchMoneySaved({ values, errors, touched, handleBlur, handleChange }) {
    console.log(values)

    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='savingsAssets'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>How much money do you have saved for buying a house so far?</p>
            </div>

            <div style={{ textAlign: 'start' }}>
                <div style={{ margin: "1rem 0.5rem" }}>
                    <label >It's important to get a full picture of all of your savings including any retirement - it can help you qualify if the bank knows you will have additional money left in reserve NOT to be used for buying the home.</label>
                </div>
                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                    <div className='inputContainerDiv' style={{ width: '40%', margin: '0.5rem' }}>
                        <label htmlFor="total_amount_saved" style={{ fontWeight: 'bold', color: Boolean(touched.total_amount_saved && errors.total_amount_saved) ? 'red' : '' }}>Total Amount Saved *</label>
                        <Field type="number" id="total_amount_saved" name="total_amount_saved" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                        {/* <ErrorMessage name="total_amount_saved" component="div" className="error" style={{color:'red'}} /> */}
                    </div>

                    <div className='inputContainerDiv' style={{ width: '25%', margin: '0.5rem' }}>
                        <label htmlFor="is_any_of_it_cash_on_hand" style={{ fontWeight: 'bold' }}>Is any of it cash on hand?</label> <br />
                        {['Some of it is in cash', 'All savings are in the bank'].map((option) => {
                            return (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field type="radio" id={option} name="is_any_of_it_cash_on_hand" value={option} as={RadioButton} />
                                    <label htmlFor={option} className="ml-2">{option}</label>

                                </FlexComponent >
                            );
                        })}
                        {values.is_any_of_it_cash_on_hand === 'Other' && (
                            <div style={{ marginTop: '1rem' }}>
                                <Field type="text" placeholder="Other" id="otherTimeAtPostion" name="otherTimeAtPostion" as={InputText} style={{ width: '100%' }} />
                            </div>
                        )}

                    </div>
                    <div className='inputContainerDiv' style={{ width: '35%', margin: '0.5rem' }}>
                        <label htmlFor="any_additional_savings" style={{ fontWeight: 'bold' }}>Any additional savings</label>
                        <div>
                            {["Has Retirement / 401k", "Investments / Life Insurance", "Any other assets", "Gift from family / friends"].map((option) => (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field
                                        type="checkbox"
                                        id={option}
                                        name="any_additional_savings" 
                                        value={option} 
                                        as={Checkbox}
                                        checked={values.any_additional_savings.includes(option)} 
                                        onChange={(event) => {
                                            const updatedValues = [...values.any_additional_savings]; 
                                            if (event.target.checked) {
                                                updatedValues.push(option); 
                                            } else {
                                                const index = updatedValues.indexOf(option); 
                                                updatedValues.splice(index, 1); 
                                            }
                                            handleChange({ target: { name: 'any_additional_savings', value: updatedValues } });
                                        }}
                                    />
                                    <label htmlFor={option} className="ml-2">{option}</label>
                                </FlexComponent>
                            ))}
                        </div>
                    </div>

                </FlexComponent>


                <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                    <label htmlFor="notes_about_savings" >
                        <p style={{ fontWeight: 'bold' }}>Notes about Savings</p>
                    </label>
                    <Field rows={4} type="text" id="notes_about_savings" name="notes_about_savings" as={InputTextarea} style={{ width: '100%' }} 
                    placeholder="Offer any additional details about the status of their monies saved for buying a house" />
                </div>

            </div>

        </div>
    )
}

export default HowMuchMoneySaved