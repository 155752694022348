import React, { useEffect, useState, useRef} from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import axios from '../../utils/axios';  
import { getLanguages, deleteLanguage } from '../../utils/urls';
import { Button } from 'primereact/button';

export default function Language() {
    const toast = useRef(null);
    const [products, setProducts] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)

    useEffect(()=>{
        // setLoading(true);
        axios.get(getLanguages, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setProducts(response.data);
                    setLoading(false);
                  }
                })
            .catch((error)=>{
                setProducts(null)
                
            })
    },[getLanguages, isMounted])

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
      };


      const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };
    
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>

        );
    };

    const accept = (language_id) => {

        const url = `${deleteLanguage}${language_id}`
        axios.post(url,{
        headers: {'Content-Type': 'multipart/form-data'},
        })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
        .catch((error)=>{

            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            
        })
    }

    const reject = () => {
        console.log('closed')
    }

    const deleteTemplate = (rowData) => {
        const language_id = rowData.language_id

        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleDeleteLanguage(language_id)}/>;
    };

    const handleDeleteLanguage = (language_id) => {

        
        confirmDialog({
            message: 'Are you sure you want to delete this language? Deleting it will also remove all purchase orders associated with this language!',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(language_id),
            reject
        });
        
    }
    
    const header = renderHeader();

    return (
        <div className="card p-fluid">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable value={products} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ width: '10%'  }} body={srNoBodyTemplate} />
                <Column field="name" header="Language" style={{ width: '70%' }}></Column>
                <Column body={deleteTemplate} style={{ width: '2rem'  }}></Column>
            </DataTable>
        </div>
    );
}