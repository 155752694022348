import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { AppUserList } from '../../utils/urls';
import axios from '../../utils/axios';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import './profile.css'
import { Divider } from 'primereact/divider';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';

const labels = {
    //personal Information
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone: "Phone",
    social_security: "Social Key",
    status_in_country: "Status In Country",
    relationship_status: "Relationship Status",
    language: "Language",

    // ShoppingFor
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    home_type: "Home Type",
    price_range: "Price Range",
    notes_about_home_search: "Notes About Search",
    specific_area_interested_in: "Priority Areas",
    why_are_you_looking_to_buy_at_this_time: "Why are you looking to buy at this time?",
    city: "City",
    state: "State",
    priority: "priority",

    //work experience
    work_title: "Title",
    company_name: "Company Name",
    work_type: "Work Type",
    time_at_position: "Time at Position",
    income: "Income",
    status: "Status",
    start_date: "Start Date",
    end_date: "End Date",
    work_history_description: "Work History Description",

    //address
    street_address: "Street Address",
    zip: "Pin Code",
    home_status: "Home Status",
    notes_about_address: "Notes About Address",

    //property owned
    rental_income: "Rental Income",
    notes: "Notes",

    //savings and assets
    saved: "Total Amount Saved",
    additional_savings: "Any additional savings",
    any_cash_in_hand: "Any Cash on hand",
    savings_notes: "Notes about savings"
}
function KeyValueRenderer({ data, indent = 0 }) {
    // Check data type
    if (Array.isArray(data)) {
        return (
            <ul style={{ paddingLeft: `${indent * 15}px` }}>
                {data.map((item, index) => (
                    <li key={index} style={{ marginBottom: "5px" }} >
                        {renderValue(item, indent + 1)}
                    </li>

                ))}
            </ul>
        );
    } else if (typeof data === 'object' && data !== null) {
        return (
            <ul style={{ paddingLeft: `${indent * 10}px` }}>
                {Object.entries(data).map(([key, value]) => (
                    <li key={key}>
                        <b>{labels[key] || key}:</b> {renderValue(value, indent + 1)}
                    </li>
                ))}
            </ul>
        );
    } else {
        return <p style={{ paddingLeft: `${indent * 15}px` }}>{data}</p>;
    }
}

function renderValue(value, indent = 0) {
    console.log(value)
    if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
        return <KeyValueRenderer data={value} indent={indent} />;
    } else {
        // return <span >{shoppingData[value]}</span>;
        return <span style={{ paddingBottom: typeof value === "boolean" && "10px" }} >{(typeof value === "boolean" && value === true) ? "Yes" : (typeof value === "boolean" && value === false) ? "No" : value}</span>;
    }
}

export default function AuditLogs({ data, formId }) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [auditData, setAuditData] = useState()
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenData, setIsOpenData] = useState()

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    const handleDataClose = () => {
        setIsOpenData(false)
    }


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        section: { value: null, matchMode: FilterMatchMode.EQUALS },
        action: { value: null, matchMode: FilterMatchMode.EQUALS },
        created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        done_by: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },

    });

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
    };


    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <div className='header-container-with-csv-export'>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
            </div>

        );
    };

    const [actions] = useState(['Deleted', 'Updated', 'Created']);
    const [sections] = useState(['Applicant', 'Work Experience', 'Personal Information', 'Address', 'Property Owned', 'Shopping for', 'Savings & Assets', 'Cosingner Information']);


    const profileView = (rowData) => {
        console.log(rowData.data)
        return <Button className='view-button' label="View" icon="pi pi-external-link" onClick={() => auditDataView(rowData.data)} />
    };

    const colorChange = (label) => {
        if (label === "Created") {
            return 'blue'
        } else if (label === "Deleted") {
            return 'red'
        } else if (label === "Updated") {
            return 'green'
        } else {
            return 'black'
        }
    }

    // const sectionItemTemplate = (option) => {
    //     return <Button style={{ pointerEvents: "none", background: colorChange(option), padding: "5px" }} label={option} value={option} />;
    // };

    const sectionRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={sections} onChange={(e) => options.filterApplyCallback(e.value)}  placeholder="Select One"  showClear style={{ minWidth: '12rem' }} />
        );
    };

    // const sectionBodyTemplate = (rowData) => {
    //     return <Button style={{ pointerEvents: "none", background: colorChange(rowData.action), padding: "5px" }} className='view-button' label={rowData.section} />
    // };

    const actionItemTemplate = (option) => {
        return <Button style={{ pointerEvents: "none", background: colorChange(option), padding: "5px" }} label={option} value={option} />;
    };

    const actionRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={actions} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={actionItemTemplate} placeholder="Select One"  showClear style={{ minWidth: '12rem' }} />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return <Button style={{ pointerEvents: "none", background: colorChange(rowData.action), padding: "5px" }} className='view-button' label={rowData.action} />
    };
    

    const auditDataView = (data) => {
        setIsOpenData(true)
        setAuditData(data)
    }


    const header = renderHeader();

    return (
        <div style={{ padding: '1rem' }}>
            <Button onClick={handleOpen} >Audit Logs</Button>
            <Dialog className='auditList' header="Audit Logs" visible={isOpen} onHide={() => handleClose()}>
                <div className="card p-fluid">
                    <DataTable value={data} paginator rows={15} rowsPerPageOptions={[15, 30, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" filterDisplay="row" scrollable scrollHeight="65vh" header={header} filters={filters} loading={false} onFilter={(e) => setFilters(e.filters)} dataKey="ghl_contact_id" tableStyle={{ minWidth: '50rem' }}
                        emptyMessage="Nothing found.">
                        <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem' }} body={srNoBodyTemplate} />
                        {/* <Column field="id" header="id" style={{ minWidth: '13rem' }} sortable ></Column> */}
                        {/* <Column field="section" header="Section" style={{ minWidth: '2rem' }} sortable ></Column> */}
                        <Column field="section" header="Section" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} filter filterElement={sectionRowFilterTemplate} />
                        {/* <Column body={actionBody} field="action" header="Action" style={{ minWidth: '2rem' }} sortable ></Column> */}
                        <Column field="action" header="Action" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={actionBodyTemplate} filter filterElement={actionRowFilterTemplate} />
                        <Column field="created_at" header="Date" style={{ minWidth: '2rem' }} sortable ></Column>
                        <Column field="done_by" header="Done By" style={{ minWidth: '13rem' }} sortable ></Column>
                        <Column body={profileView} style={{ minWidth: '1rem' }}></Column>
                    </DataTable>
                </div>
            </Dialog>
            <Dialog header="Audit Log" visible={isOpenData} onHide={() => handleDataClose()}>
                <div style={{ padding: "0 2rem" }} >
                    <KeyValueRenderer data={auditData} />
                    {/* <p>{auditData.id}</p> */}
                </div>
            </Dialog>
        </div>

    );
}