import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { Button } from 'primereact/button';
import FlexComponent from '../../FlexComponent';
import { descriptionAdd } from '../../../utils/urls';
import axios from '../../../utils/axios';
import './apps.css';

function Description({ description, formId, toast }) {
    const [value, setValue] = useState(description);
    const [descValue, setDescValue] = useState(description);
    const [isEdit, setIsEdit] = useState(false);
    const quillRef = useRef(null);
    const editorContainerRef = useRef(null);

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const stripHtml = (html) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    const handleSave = () => {
        setIsEdit(false);
        setDescValue(value);

        const data = { content: value };
        const url = `${descriptionAdd}${formId}`;
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Saved', detail: 'Description saved successfully', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            }
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        });
    };

    const adjustEditorHeight = () => {
        if (quillRef.current) {
            const editorContent = quillRef.current.getEditor().root;
            const scrollHeight = editorContent.scrollHeight;

            // Set a minimum and maximum height as needed
            const newHeight = Math.max(scrollHeight, 80);
            editorContainerRef.current.style.height = `${newHeight}px`;
        }
    };

    useEffect(() => {
        adjustEditorHeight();
    }, [value]);

    return (
        <div style={{ marginBottom: "1rem" }}>
            <FlexComponent style={{ paddingBottom: ".5rem" }}>
                <p> <i className='pi pi-align-left' style={{ marginRight: "5px" }} ></i> Description</p>
                {stripHtml(descValue).trim() && (
                    <Button style={{ padding: ".5rem .6rem", border: "1px solid grey", boxShadow: "none" }} onClick={() => setIsEdit(true)}>
                        Edit
                    </Button>
                )}
            </FlexComponent>
            {(stripHtml(descValue).trim() === "" || isEdit) ? (
                <div>
                    <div className='descriptionEditorDiv' ref={editorContainerRef}>
                        <FlexComponent justifyContent="center" style={{ marginTop: ".6rem" }}>
                            <ReactQuill
                                ref={quillRef}
                                value={value}
                                onChange={(content) => {
                                    setValue(content);
                                    adjustEditorHeight();
                                }}
                                modules={modules}
                                style={{ width: "92%" }}
                                theme="snow"
                            />
                        </FlexComponent>
                    </div>
                    <div style={{ marginLeft: "2rem", marginTop:"3rem" }}>
                        <Button disabled={!stripHtml(value).trim()} style={{ background: "#2FC635" }} onClick={handleSave}>
                            Save
                        </Button>
                        {isEdit && (
                            <Button style={{ marginLeft: ".5rem" }} onClick={() => {
                                setValue(description);
                                setIsEdit(false);
                            }}>
                                Cancel
                            </Button>
                        )}
                    </div>
                </div>
            ) : (
                <FlexComponent className='descriptionDiv'>
                    <div dangerouslySetInnerHTML={{ __html: descValue }}></div>
                </FlexComponent>
            )}
        </div>
    );
}

export default Description;
