import { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { getAllCustomUsers, getShoppersLeaderBoard, toggleAvailabilitySlots, updateShopperGoal } from '../../utils/urls';
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import FlexComponent from '../FlexComponent';
import { Avatar } from 'primereact/avatar';
// import './leaderBoard.css'
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function UserAvailabilitiesTable() {
    const navigate = useNavigate();
    const toast = useRef(null);
    // const ghlUserId = "gOxDKuJ9hLEPxEXVKMa2"
    const [appLeads, setAppLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    const [fieldsData, setFieldsData] = useState({})
    const [customUsers, setCustomUsers] = useState([])

    // const generateDateRange = () => {
    //     const today = new Date();
    //     const last7thDay = new Date();
    //     last7thDay.setDate(today.getDate() - 7);
    //     return [last7thDay, today];
    // };

    const getCustomUsers = () => {
        setLoading(true);
        const url = `${getAllCustomUsers}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setCustomUsers(response.data)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getCustomUsers()
    }, [isMounted]);


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    // console.log(numberOneId)

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <FlexComponent key={rowIndex} justifyContent="start" gap="1rem" style={{ paddingLeft: "2rem" }} >
            <div style={{ width: "30px" }}><span>{rowIndex}</span> </div>
            <Avatar size="medium" image={rowData?.image} shape='circle'
                label={<span style={{ padding: '5px' }}>{getInitials(`${rowData?.first_name} ${rowData?.last_name}`)}</span>}
                style={{ background: 'white', border: '2px solid #8C8C8C', width: '40px', height: '40px' }} />
            <span>{rowData?.first_name} {rowData?.last_name}</span>
        </FlexComponent>;
    };



const accept = (availabilityData, user_id) => {
    axios.post(`${toggleAvailabilitySlots}${user_id}`, availabilityData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
            }
        })
        .catch((error) => {

            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });

        })
}

const reject = () => {
    console.log('closed')
}

const handlePauseUser = (user_id, status, availabilityType) => {

    const availabilityData = {
        type: availabilityType,
        status: status
    }
    console.log(availabilityData)
    let message;
    if (status === true) {
        message = 'Are you sure you want to make this user active?'
    }
    else {
        message = 'Are you sure you want to make this user inactive?'
    }
    confirmDialog({
        message: message,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => accept(availabilityData, user_id),
        reject
    });

}

const availabilityBody = (rowData, props) => {
    const rowIndex = props.rowIndex + 1;
    const status = rowData.live_transfer_available
    const user_id = rowData.id
    // console.log("rowData", rowData)
    return (
        <FlexComponent justifyContent="start" gap="1rem" >
            <div style={{ width: "10rem" }} >
                <Button onClick={() => {
                    navigate(`/user-availability/${rowData.id}`)
                }} >Set Availability</Button>
            </div>
            <div style={{ width: "4rem" }} >
                <ToggleButton onLabel="OFF" offLabel="ON" checked={!status} onChange={(e) => handlePauseUser(user_id, !status, "Live Assignment")} className="w-8rem" />
            </div>
        </FlexComponent>)
};

const appointmentAvailabilityBody = (rowData, props) => {
    const rowIndex = props.rowIndex + 1;
    const status = rowData.appointment_available
    const user_id = rowData.id
    // console.log("rowData", rowData)
    return (
        <FlexComponent justifyContent="start" gap="1rem">
            <div style={{ width: "10rem" }} >
                <Button onClick={() => {
                    navigate(`/user-availability-appointment/${rowData.id}`)
                }} >Set Availability</Button>
            </div>
            <div style={{ width: "4rem" }} >
                <ToggleButton onLabel="OFF" offLabel="ON" checked={!status} onChange={(e) => handlePauseUser(user_id, !status, "Appointment")} className="w-8rem" />
            </div>
        </FlexComponent>)
};

// const activeBody = (rowData) => {
//     const status = rowData.live_transfer_available
//     const user_id = rowData.id
//     // console.log(status)
//     return (
//         <FlexComponent  >
//             <div style={{ width: "4rem" }} >
//                 <ToggleButton onLabel="OFF" offLabel="ON" checked={!status} onChange={(e) => handlePauseUser(user_id, !status)} className="w-8rem" />
//             </div>
//         </FlexComponent>)
// };

const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
};
// console.log(filters)

const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';
    return (
        <div className='header-container-with-csv-export'>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        </div>
    );
};

const header = renderHeader();

return (
    <div >
        <Toast ref={toast} />
        <ConfirmDialog />
        <FlexComponent className='applicantDivs' justifyContent="end" gap="1rem">
            <FlexComponent className='applicantDivs' justifyContent="center" style={{ color: "white", zIndex: "1", fontSize: "22px", width: "100%" }} >
               <div>User Availabilities</div>
            </FlexComponent>
            <div >
                {/* <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" /> */}
                {/* <label htmlFor="ms-cities">Select Date Range</label> */}
            </div>
        </FlexComponent>
        <div className="card p-fluid" style={{ marginTop: ".2rem" }} >
            <DataTable
                value={customUsers}
                size='small'
                paginator
                rows={10}
                rowsPerPageOptions={[10, 30, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                scrollable
                scrollHeight="65vh"
                header={header}
                filters={filters}
                loading={loading}
                onFilter={(e) => setFilters(e.filters)}
                dataKey="id"
                stateStorage="session"
                stateKey="user-availability-table"
                tableStyle={{ minWidth: '50rem' }}
            >
                <Column header="" field="first_name" className="padding-0" body={srNoBodyTemplate} />
                <Column field="last_name" header="" style={{ display: "none" }} />
                <Column field="apps" header="Live Assignment" body={availabilityBody} />
                <Column field="apps" header="Appointment" body={appointmentAvailabilityBody} />
                {/* <Column field="live_transfer_available" header="Live Assignment" body={activeBody} style={{ minWidth: '15rem', fontWeight: "bold", fontSize: "1.1rem" }} /> */}
            </DataTable>
        </div>
    </div>
);
}
