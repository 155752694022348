import React from "react";
import "./styles.css";

const BubblyBubbles = ({
  colorStart,
  colorEnd,
  duration,
}) => {
  return (
    <div id="react-bubbly-transitions__bubbles">
      <div
        style={{ animationDuration: `${duration}ms`, background: colorStart }}
        className="react-bubbly-transitions__first"
      />
      <div
        style={{ animationDuration: `${duration}ms`, background: colorEnd }}
        className="react-bubbly-transitions__second"
      />
    </div>
  );
};

export default BubblyBubbles;
