import { Button } from 'primereact/button'
import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { Dialog } from 'primereact/dialog'
import './profile.css'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate, useParams } from 'react-router-dom'

function FormSubmissionList({ data , formId}) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false); 

  const handleOpen = () => {
    setIsOpen(true);
  };

  const formatDateString = (originalDate) => {
    const formattedDate = new Date(originalDate);
  
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const date = formattedDate.getDate().toString().padStart(2, '0');
    const hours = formattedDate.getHours().toString().padStart(2, '0');
    const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
  
    return `${year}-${month}-${date} ${hours}:${minutes}`;
  }

  const handleClose = () => {
    setIsOpen(false); 
  };

  const srNoBodyTemplate = (rowData, props) => {
    const rowIndex = props.rowIndex + 1;
    return <div className='status'>{rowIndex}</div>;
  };

  const profileView = (rowData) => {
    const contact_id = rowData.contact_id
    const form_id = rowData.form_id
    console.log(rowData)
    if(rowData.form_id == formId){
      return (<Button label="currently Selected"  style={{background : "white", color: 'black'}}/>)
    }else{
      return (<Button className='view-button' label="View" icon="pi pi-external-link" onClick={()=>redirectProfilePage(contact_id, form_id)} />)
    }
};

const redirectProfilePage = (contact_id, form_id) =>{
  navigate(`/app/${contact_id}/${form_id}`)
  handleClose()
}

  return (
    <div style={{padding: '1rem'}}>
      <Button onClick={handleOpen} >Other Submissions</Button>
      <Dialog className='submissionList' header="Other Submissions" visible={isOpen} onHide={() => handleClose()}>
        <div >
          <DataTable value={data} showGridlines tableStyle={{ minWidth: '20rem' }}>
          <Column header="SR NO" className="padding-0" style={{ minWidth: '1rem'  }} body={srNoBodyTemplate} />
            <Column field="user_name" header="Application By"></Column>
            <Column field="date" header="Date"
              // body={(rowData) => formatDateString(rowData.date)}
            />
            <Column body={profileView} style={{ minWidth: '1rem' }}></Column>
          </DataTable>
        </div>
      </Dialog>
    </div>
  )
}

export default FormSubmissionList
