import React, { useEffect, useRef, useState } from 'react'
import CheckList from './CheckList'
import axios from '../../../utils/axios';
import { appSubmission, createCheckList, createCheckListHeading, deleteCheckListHeading, getCheckListTemplate, getCheckListTemplateHeadings, updateCheckListHeading } from '../../../utils/urls';
import FlexComponent from '../../FlexComponent';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChecklistTemplateItems } from './ChecklistTemplateItems';
import { Dialog } from 'primereact/dialog';
import './apps.css'

function CheckListContainer({ userId, formId, usersList, pipelineId, salesUsersList, checkListsData, handleClose, toast }) {

    //   const [checkLists, setCheckLists] = useState(checkListsData)
    const [allCheckLists, setAllCheckLists] = useState(checkListsData)
    const [addCheckListTitle, setAddCheckListTitle] = useState(false)
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [datetime12h, setDateTime12h] = useState(null);
    const [checklistHeading, setChecklistHeading] = useState()
    const [checklistHeadingsList, setChecklistHeadingsList] = useState([]);
    const [isAddChecklistTemplate, setIsAddChecklistTemplate] = useState(false)
    const templateOp = useRef(null)
    const [checklistItems, setChecklistItems] = useState([]);
    // const [isTemplateAdd, setIsTemplateAdd] = useState(false);
    const [itemsLoading, setItemsLoading] = useState(false)

    const handleSaveCheckList = () => {
        const data = {
            name: title
        }
        console.log(data)
        const Url = `${createCheckListHeading}${formId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    getAllCheckLists()
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setTitle('');
    };

    const handleUpdateCheckList = (titleName, checkListId) => {
        const data = {
            name: titleName,
            // due_date : datetime12h
        }
        console.log(data)
        const Url = `${updateCheckListHeading}${checkListId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAllCheckLists((prev) => (
                        prev.map(list => {
                            if (list.id === checkListId) {
                                return { ...list, name: titleName };
                            } else {
                                return list;
                            }
                        })
                    ))
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setTitle('');
    };


    const handleDeleteCheckList = (checkListId) => {
        confirmDialog({
            message: 'Are you sure you want to delete this Check List?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // const updatedData = allCheckLists?.filter(item => item.id !== checkListId);
                // setAllCheckLists(updatedData);
                const Url = `${deleteCheckListHeading}${checkListId}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            getAllCheckLists()
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            handleClose()
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        handleClose()
                    })

            },
            reject: () => {
                console.log("")
            }
        });
    };

    const getChecklistHeadingsList = () => {
        if (userId) {
            const url = `${getCheckListTemplateHeadings}`;
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setChecklistHeadingsList(response.data);
                        // setLoading(false)
                    }
                })
                .catch(() => {
                    // setLoading(false)
                });
        }
    };

    const getTemplateChecklistItems = (id) => {
        setItemsLoading(true)
        if (userId) {
            const url = `${getCheckListTemplate}${id}`;
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        let updatedData = response.data.items.map((item)=> ({... item, due_date : null}))
                        console.log(updatedData);
                        setChecklistItems(updatedData);

                        setItemsLoading(false)
                    }
                })
                .catch(() => {
                    setItemsLoading(false)
                });
        }
    };

    const getAllCheckLists = () => {
        setLoading(true)
        const listUrl = `${appSubmission}${formId}`
        axios.get(listUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setAllCheckLists(response.data.checklist_heading)
                    setLoading(false)
                    // setSelectedLists(response.data)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
    }

    const convertDateToString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based in Date
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSaveTemplateItems = (headingId, item) => {
        const data = {
            item: item.item,
            mention: [],
            assigned_user: [],
            due_date: convertDateToString(item.due_date),
            heading_id: headingId,
            is_completed : false
        }
        console.log(data)
        // setCheckListLoad(true)
        const Url = `${createCheckList}${formId}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // handleClick(formId)
                    // const newCheckListObj = {
                    //     checklist_id: response.data.checklist_id,
                    //     ...data
                    // }
                    // setAllCheckLists(prev => [...prev, newCheckListObj]);

                    // setCheckListLoad(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        // setValue('');
        // setMentionedUsers([]);
        // setAssignedUsers([]);
        // setDueDate('')
    };

    const saveChecklistTemplateAllList = (items)=> {
        const data = {
            name: checklistHeadingsList.find(list=> list.id === checklistHeading)?.name ,
        }
        console.log(data)
        // setCheckListLoad(true)
        const Url = `${createCheckListHeading}${formId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                // response.data.heading
                if (response.status === 200) {
                    items.forEach((item)=> {
                        handleSaveTemplateItems(response.data.id, item)
                    })
                    getAllCheckLists()
                    setChecklistItems([])
                    setChecklistHeading("")
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

        
    }

    // useEffect(() => {
    //     getAllCheckLists()
    // },[])

    return (
        <div key={loading} >
            <ConfirmDialog />
            {/* <div className="flex-auto">
                <label htmlFor="calendar-12h" className="font-bold block mb-2">
                    12h Format
                </label>
                <Calendar id="calendar-12h" value={datetime12h} onChange={(e) => setDateTime12h(e.value)} showTime hourFormat="12" />
            </div> */}
            <FlexComponent justifyContent="start" gap="1rem" >
                <p><i className="pi pi-check-square" ></i><span style={{ marginLeft: "7px" }} >CheckList</span> </p>
                <Button style={{ cursor: "pointer", padding: "0.4rem 0.5rem", border: "1px solid grey" }} onClick={() => {
                    setAddCheckListTitle(!addCheckListTitle)
                }} > <i className="pi pi-plus"></i> </Button>
                <div>
                    <Button style={{ padding: ".3rem .6rem", border: "1px solid grey" }} onClick={(e) => {
                        // templateOp.current.toggle(e)
                        setIsAddChecklistTemplate(true)
                        getChecklistHeadingsList()
                    }} >Add Template</Button>
                </div>

                <Dialog className='applicantPopup checklistTemplate'
                    // footer={
                    //     <div>{!list?.is_ghl_pipeline && <Button disabled={!checklistItems.length || !headingName.trim()} onClick={() => {
                    //         setIsTemplateAdd(false);
                    //         handleEditSubmit()
                    //     }} >Save</Button>}</div>}
                    visible={isAddChecklistTemplate} style={{ width: '50vw', minHeight: '65vh' }} onHide={() => {
                        setIsAddChecklistTemplate(false);
                        setChecklistItems([]);
                        setChecklistHeading("")
                    }}>
                    <FlexComponent style={{ marginTop: "1rem" }} className='inputContainer'  flexDirection="column" gap='.5rem' alignItems='start'>
                        <div className='inputContainerDiv' style={{ width: '50%' }}>
                            <label htmlFor="headingName" style={{ fontWeight: 'bold'}}>Checklist Heading </label>
                            <Dropdown
                                id="checklist-template"
                                value={checklistHeading}
                                options={checklistHeadingsList?.map(item => ({
                                    label: item.name,
                                    value: item.id,
                                })) || []}
                                style={{ width: '100%', marginTop : ".5rem"  }}
                                onChange={(e) => {
                                    setChecklistHeading(e.target.value)
                                    // templateOp.current.toggle(e)
                                    getTemplateChecklistItems(e.target.value)
                                }}
                                placeholder="Select Checklist Template"
                            />
                        </div>
                        <Divider />
                        <label style={{ fontWeight: 'bold' }}>Items</label>

                        {itemsLoading && <FlexComponent justifyContent="center">
                            <ProgressSpinner />
                        </FlexComponent>}

                        {!itemsLoading && <div style={{width: "100%"}} >
                            {checklistItems?.map((item) => (
                                <ChecklistTemplateItems key={item.checklist_id} toast={toast} checklistItems={checklistItems} setChecklistItems={setChecklistItems} checklistHeadingsList={checklistHeadingsList} id={item.checklist_id} item={item} />
                            ))}
                        </div>
                        }

                        <FlexComponent justifyContent="end" gap="1rem" className='inputContainerDiv' style={{ marginTop: ".5rem", width: '100%' }}>
                            <Button disabled={checklistItems.length === 0 || checklistItems.some(item=> !item.due_date)} style={{border: "1px solid grey"}} onClick={()=>{
                                setIsAddChecklistTemplate(false)
                                saveChecklistTemplateAllList(checklistItems)
                            }} >Save</Button>
                        </FlexComponent>

                    </FlexComponent>
                </Dialog>

                {/* <OverlayPanel ref={templateOp} style={{ background: "#254270" }} >
                    <div>
                        <Dropdown
                            id="checklist-template"
                            value={checklistHeading}
                            options={checklistHeadingsList?.map(item => ({
                                label: item.name,
                                value: item.id,
                            })) || []}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setChecklistHeading(e.target.value)
                                templateOp.current.toggle(e)
                                getTemplateChecklistItems(e.target.value)
                            }}
                            placeholder="Select Checklist Template"
                        />
                    </div>
                </OverlayPanel> */}

            </FlexComponent>
            {addCheckListTitle && <div style={{ padding: "1rem" }} > <FlexComponent gap="1rem" >
                <InputText placeholder='Enter the title' style={{ width: '100%', padding: ".3rem" }} id="title" value={title} onChange={(e) => {
                    setTitle(e.target.value)
                }} />
                <Button style={{ padding: ".3rem .6rem", border: "1px solid grey" }} onClick={handleSaveCheckList} >Add</Button>
            </FlexComponent>
                <Divider />
            </div>
            }
            {allCheckLists?.map((list, index) => (
                <div key={index} id={list.id} >
                    {/* <h3>{list.name}</h3> */}
                    <CheckList userId={userId} checkListHeading={list.name} pipelineId={pipelineId} checkListsData={list} handleUpdateCheckList={handleUpdateCheckList} handleDeleteCheckList={handleDeleteCheckList} usersList={usersList} salesUsersList={salesUsersList} formId={formId} toast={toast} handleClose={handleClose} />
                </div>
            ))}

        </div>
    )
}

export default CheckListContainer