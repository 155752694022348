import React, { useState, useEffect } from 'react';
import FlexComponent from '../FlexComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import './profile.css';
import Swal from 'sweetalert2';
import { UpdateCustomAppLead } from '../../utils/urls';
import axios from '../../utils/axios'


function PersonalInformation({ data, toast, formId, userId, setIsSubmit }) {
    const [isEditOpen, setIsEditOpen] = useState(false); 

    const convertDateToDateString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based in Date
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const convertDateStringToDate = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day); // month is 0-based in Date
    };

    const [formData, setFormData] = useState({
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        email: data?.email || '',
        phone: data?.phone || '',
        social_security: data?.social_security || '',
        DOB: convertDateStringToDate(data?.unformatted_DOB) || '',
        status_in_country: data?.status_in_country || '',
        relationship_status: data?.relationship_status || '',
        language: data?.language || '',
        
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleDateChange = (e) => {
        const { value } = e;
        setFormData((prevData) => ({
            ...prevData,
            DOB: value,
        }));
    };

    const handleEditClick = () => {
        setIsEditOpen(true);
    };
    

    const handleEditSave = async () => {
        try {
            const updatedData = {
                ...formData,
                DOB: convertDateToDateString(formData.DOB),
                type : "Personal Information",
                userId : userId
            };
            console.log(updatedData);
            setIsEditOpen(false);
            console.log('Submit ')
            const url = `${UpdateCustomAppLead}${formId}`
            const response = await axios.post(url, updatedData, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                setIsSubmit(true)
                // onSubmitProps.resetForm();
            }else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                setIsSubmit(true)
            }
        } catch (error) {  
            if(error.response.status === 400){
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data, life: 3000 });
                setIsSubmit(true)
            }else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsSubmit(true)
            }
        }
    };

    const handleEditCancel = () => {
        setIsEditOpen(false); 
    };


    return (
        <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', background: 'white', padding: '2rem' }}>
            <FlexComponent style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ fontWeight: '600' }}>Personal Information</p>
                <Button label="Edit" style={{ borderRadius: '25px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleEditClick} />
            </FlexComponent>
            <FlexComponent>
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>First Name</p>
                    <p>{data?.first_name}</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Last Name</p>
                    <p>{data?.last_name}</p>
                </div>
            </FlexComponent>
            <FlexComponent>
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Email</p>
                    <p>{data?.email}</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Phone</p>
                    <p>{data?.phone}</p>
                </div>
            </FlexComponent>
            <FlexComponent className="inputContainerFlex">
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Social key</p>
                    <div className=" flex justify-content-center">
                        <Password style={{border: 'none', width: '240px'}} readOnly value={data?.social_security} toggleMask feedback={false}/>
                    </div>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Date of Birth</p>
                    <p>{data?.DOB || 'N/A'}</p>
                </div>
            </FlexComponent>
            <FlexComponent>
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Status in Country</p>
                    <p>{data?.status_in_country}</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Relationship Status</p>
                    <p>{data?.relationship_status || 'N/A'}</p>
                </div>
            </FlexComponent>
            <FlexComponent>
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Language</p>
                    <p>{data?.language || 'N/A'}</p>
                </div>
            </FlexComponent>

            <Dialog className='editForm' header="Edit Personal Information" visible={isEditOpen} onHide={handleEditCancel}>
                <div>
                    <form>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="first_name">First Name</label> <br />
                                <InputText style={{ width: '100%' }} id="first_name" value={formData.first_name} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="last_name">Last Name</label> <br />
                                <InputText style={{ width: '100%' }} id="last_name" value={formData.last_name} onChange={handleInputChange}/>
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="email">Email</label> <br />
                                <InputText style={{ width: '100%' }} id="email" value={formData.email} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="phone">Phone</label> <br />
                                <InputText style={{ width: '100%' }} id="phone" value={formData.phone} onChange={handleInputChange}/>
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="social_security">Social Key</label> <br />
                                <InputText style={{ width: '100%' }} id="social_security" value={formData.social_security} onChange={handleInputChange} />
                                {/* <Password style={{border: 'none', width: '240px'}} value={formData?.social_security} onChange={handleInputChange} toggleMask tabIndex={1} feedback={false}/> */}
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="DOB">Date of Birth</label> <br />
                                <Calendar inputId="DOB" dateFormat="dd/mm/yy" value={formData.DOB} onChange={handleDateChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="status_in_country">Status in Country</label> <br />
                                <InputText style={{ width: '100%' }} id="status_in_country" value={formData.status_in_country} onChange={handleInputChange}/>
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="relationship_status">Relationship Status</label> <br />
                                <InputText style={{ width: '100%' }} id="relationship_status" value={formData.relationship_status} onChange={handleInputChange}/>
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="language">Language</label> <br />
                                <InputText style={{ width: '100%' }} id="language" value={formData.language} onChange={handleInputChange}/>
                            </div>
                        </FlexComponent>
                        <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem' }}>
                            <Button type='button' label="Save" className="p-button-success" onClick={handleEditSave} />
                            <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                        </FlexComponent>
                    </form>
                </div>
            </Dialog>
        </FlexComponent>
    );
}

export default PersonalInformation;
