import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import './form.css'
import { Country, State, City } from 'country-state-city';
import { Divider } from 'primereact/divider'
import { MultiSelect } from 'primereact/multiselect'

function LoanSetUpDetails({ values, errors, touched, handleBlur, handleChange, setFieldValue }) {
    const [areaIndex, setAreaIndex] = useState()
    const [areaCity, setAreaCity] = useState()
    const [areaState, setAreaState] = useState()
    const handleSelectAll = () => {
        const allOptions = ["1 Family", "2 Family", "3+ / Multi-Family", "Condo", "Co-op"];
        setFieldValue('home_type', allOptions);
    };

    const handleDeselectAll = () => {
        setFieldValue('home_type', []);
    };

    const handleAddClick = () => {
        // setLoading(true)
        let prior = false
        if (values.specific_area_interested_in?.length === 0) {
            prior = true
        }
        values.specific_area_interested_in.push({
            state: State.getStateByCodeAndCountry(areaState, 'US')?.name,
            city: areaCity,
            priority: prior
        });

        setAreaIndex(values.specific_area_interested_in.length - 1)
        setAreaState('')
        setAreaCity('')
    };


    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='loanSetUpDetailsId'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>Loan Set Up Details</p>
            </div>

            <div style={{ textAlign: 'start', marginTop: "1.5rem" }}>
                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                    <div className=' applicantDivs' style={{ width: '50%', }}>
                        <label htmlFor="initial_documents_collected" style={{ fontWeight: 'bold' }}>Initial Documents Collected</label>
                        <div>
                            <FlexComponent justifyContent='start' gap='0.75rem' style={{ marginTop: '0.5rem' }}>
                                <MultiSelect
                                    value={values.initial_documents_collected}
                                    options={[
                                        { label: 'ID', value: 'ID' },
                                        { label: 'Social Security Card', value: 'Social Security Card' },
                                        { label: 'Personal Tax Returns (auto populate previous year 2023)', value: 'Personal Tax Returns (auto populate previous year 2023)' },
                                        { label: 'Personal Tax Returns (auto populate 2 years previous “2022)', value: 'Personal Tax Returns (auto populate 2 years previous “2022)' },
                                        { label: 'Business Tax Returns (2023)', value: 'Business Tax Returns (2023)' },
                                        { label: 'Business Tax Returns (2022)', value: 'Business Tax Returns (2022)' },
                                        { label: 'Bank Statements (showing money for down payment and closing costs)', value: 'Bank Statements (showing money for down payment and closing costs)' },
                                    ]}
                                    style={{ width: '100%', color: 'black' }}
                                    // onChange={(event) => handleChange('initial_documents_collected')(event.value)}
                                    onChange={(event) => handleChange({ target: { name: 'initial_documents_collected', value: event.value } })}
                                    placeholder='Select'
                                    // filter
                                />
                            </FlexComponent>
                        </div>
                    </div>

                    <div className=' applicantDivs' style={{ textAlign: 'start', width: '50%', margin: '0.5rem' }}>
                        <label style={{ fontWeight: 'bold' }} htmlFor="where_are_the_documents_submitted"> Where are the documents submitted?</label> <br />
                        <Dropdown
                            id='where_are_the_documents_submitted'
                            value={values.where_are_the_documents_submitted}
                            options={[
                                { label: 'OneDrive', value: 'OneDrive' },
                                { label: 'Emailed to loandocs@unitedmortgage.com', value: 'Emailed to loandocs@unitedmortgage.com' },
                            ]}
                            style={{ width: '100%' }}
                            onChange={(event) => handleChange('where_are_the_documents_submitted')(event.value)}
                            onBlur={handleBlur}
                            placeholder='Select'
                            filter
                        />
                    </div>

                </FlexComponent>


                <div className='applicantDivs' style={{ textAlign: 'start', margin: '0.5rem' }}>
                    <label htmlFor="note_about_missing_documents" >
                        <p style={{ fontWeight: 'bold' }}>Note about missing documents</p>
                    </label>
                    <Field rows={4} type="text" id="note_about_missing_documents" name="note_about_missing_documents" as={InputTextarea} style={{ width: '100%' }} />
                </div>

                <div className='applicantDivs' style={{ textAlign: 'start', margin: '0.5rem' }}>
                    <label htmlFor="scenario_description" >
                        <p style={{ fontWeight: 'bold' }}>Scenario Description</p>
                    </label>
                    <Field rows={4} type="text" id="scenario_description" name="scenario_description" as={InputTextarea} style={{ width: '100%' }} />
                </div>

                <div className='inputContainerDiv applicantDivs' style={{ width: '100%', margin: '0.5rem' }}>
                    <label htmlFor="do_we_have_authorization_to_pull_credit" style={{ fontWeight: 'bold' }}>Do we have authorization to pull credit?</label> <br />
                    {["Yes", "No"].map((option) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                <Field type="radio" id={option} name="do_we_have_authorization_to_pull_credit" value={option} as={RadioButton} />
                                <label htmlFor={option} className="ml-2">{option}</label>
                            </FlexComponent >
                        );
                    })}
                </div>

            </div>
        </div>
    )
}

export default LoanSetUpDetails