import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';
import axios from '../../utils/axios';
import { getLanguageCampaign, addNewLanguages, addNewCampaign, locationManage, leaderCreate, locationAdd, teamCreate, userCreate, getDesignations, getCompanies } from '../../utils/urls';
import './settings.css'
import Labels from './Labels';
import Pipelines from './Pipelines';
import Designations from './Designations';
import CustomFields from './CustomFields';
import Companies from './Companies';
import UserAvailabilitiesTable from '../userAvailability/UserAvailabilitiesTable';
import CheckListTemplates from './CheckListTemplates';

export default function Settings() {
    const navigate = useNavigate()
    const [isMounted, setIsMounted] = useState(false)
    const toast = useRef(null);
    const [languageVisible, setLanguageVisible] = useState(false);
    const [campaignVisible, setCampaignVisible] = useState(false);
    const [locationVisible, setLocationVisible] = useState(false);
    const [locationAddVisible, setLocationAddVisible] = useState(false);
    const [AccessCode, setAccessCode] = useState('');
    const [newLocationId, setNewLocationId] = useState('');
    const [leaderVisible, setLeaderVisible] = useState(false);
    const [teamVisible, setTeamVisible] = useState(false);
    const [userVisible, setUserVisible] = useState(false);
    const [labelsVisible, setLabelsVisible] = useState(false);
    const [pipelinesVisible, setPipelinesVisible] = useState(false);
    const [designationsVisible, setDesignationsVisible] = useState(false);
    const [customFieldsVisible, setCustomFieldsVisible] = useState(false)
    const [companiesVisible, setCompaniesVisible] = useState(false)
    const [checkListTemplatesVisible, setCheckListTemplatesVisible] = useState(false)
    const [availabilitiesVisible, setAvailabilitiesVisible] = useState(false)
    const [newLanguages, setNewLanguages] = useState(null);
    const [newCampaign, setNewCampaign] = useState(null);
    const [loading, setLoading] = useState(false);
    const languages = [
        { name: 'English', code: 'en' },
        { name: 'Spanish', code: 'es' },
        { name: 'French', code: 'fr' },
        { name: 'German', code: 'de' },
        { name: 'Italian', code: 'it' },
        { name: 'Portuguese', code: 'pt' },
        { name: 'Dutch', code: 'nl' },
        { name: 'Russian', code: 'ru' },
        { name: 'Japanese', code: 'ja' },
        { name: 'Chinese', code: 'zh' },
        { name: 'Korean', code: 'ko' },
        { name: 'Arabic', code: 'ar' },
        { name: 'Hindi', code: 'hi' },
        { name: 'Bengali', code: 'bn' },
        { name: 'Turkish', code: 'tr' },
        { name: 'Swedish', code: 'sv' },
        { name: 'Norwegian', code: 'no' },
        { name: 'Finnish', code: 'fi' },
        { name: 'Danish', code: 'da' },
        { name: 'Greek', code: 'el' },
        { name: 'Polish', code: 'pl' },
        { name: 'Hungarian', code: 'hu' },
        { name: 'Czech', code: 'cs' },
        // Add more languages and codes as needed
    ];

    const [languagesToExclude, setLanguagesToExclude] = useState([]);
    const [filteredPopularLanguages, setFilteredPopularLanguages] = useState([]);
    const [campaignToExclude, setCampaignsToExclude] = useState([]);
    const [languageCount, setLanguageCount] = useState(0)
    const [campaignCount, setCampaignCount] = useState(0)
    const [locationCount, setLocationCount] = useState(0)
    const [leaderCount, setLeaderCount] = useState(0)
    const [teamCount, setTeamCount] = useState(0)
    const [userCount, setUserCount] = useState(0)
    const [labelsCount, setLabelsCount] = useState(0)
    const [locations, setLocations] = useState(null);

    const [teamName, setTeamName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [allowedLocations, setAllowedLocations] = useState(null);
    const [teamLocations, setTeamLocations] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [activeLocations, setActiveLocations] = useState(null);
    const [unRegisteredUsers, setUnRegisteredUsers] = useState(null);
    const [designationList, setDesignationList] = useState([])
    const [designation, setDesignation] = useState('')
    const [companiesList, setCompaniesList] = useState([])
    const [company, setCompany] = useState('')
    const [registeredUserName, setRegisteredUserName] = useState([]);
    const [selectedUser, setSeletedUser] = useState(null);
    const [activeTeams, setActiveTeams] = useState(null);
    const [allowedTeams, setAllowedTeams] = useState(null);

    const getAllDesignations = () => {
        const url = `${getDesignations}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setDesignationList(response.data)

                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
    }

    const getAllCompanies = () => {
        const url = `${getCompanies}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setCompaniesList(response.data)
                    // setLoading(false)
                }
            })
            .catch((error) => {
                // setLoading(false)
            })
    }

    useEffect(() => {
        // setLoading(true);
        getAllDesignations()
        getAllCompanies()
        axios.get(getLanguageCampaign, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    const exclude_languages = response.data.language_name.map(name => name.toLowerCase())
                    setLanguagesToExclude(exclude_languages)
                    setCampaignsToExclude(response.data.campaign_name)
                    setLanguageCount(response.data.language_count)
                    setCampaignCount(response.data.campaign_count)
                    setLocationCount(response.data.location_count)
                    setLocations(response.data.locations)
                    setActiveLocations(response.data.active_locations)
                    setRegisteredUserName(response.data.user_names)
                    setLeaderCount(response.data.leader_count)
                    setTeamCount(response.data.team_count)
                    setUserCount(response.data.user_count)
                    setUnRegisteredUsers(response.data.unregistered_users)
                    setActiveTeams(response.data.active_teams)
                }
            })
            .catch((error) => {
                console.log({ error })

            })
    }, [getLanguageCampaign, isMounted])

    useEffect(() => {
        // Create a new array of popular languages excluding those in the languagesToExclude array
        const filteredLanguages = languages.filter(language =>
            !languagesToExclude.includes(language.name.toLowerCase())
        );
        setFilteredPopularLanguages(filteredLanguages);

    }, [languagesToExclude]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get('code');

        if (codeParam) {
            setAccessCode(codeParam);
            setLocationAddVisible(true)
        }
    }, []);

    const clearParam = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('code');

        // Replace the current URL without triggering a page reload
        window.history.replaceState(null, null, url.toString());
    };

    const handleGetAccessCode = () => {
        window.location.href = 'https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.homesforsalemedia.com/settings&client_id=65cb5a832eb035865753e518-lskbf1f0&scope=businesses.readonly businesses.write calendars/events.readonly contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/tags.readonly locations/customFields.write opportunities.readonly opportunities.write oauth.readonly oauth.write users.readonly users.write';
    };

    const footer = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            <Button label="View" icon="pi pi-external-link" onClick={() => navigate('/language')} />
            <Button className='settings-add-button' label="Add" icon="pi pi-plus" onClick={() => setLanguageVisible(true)} />
        </div>
    );

    const campaignFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            <Button label="View" icon="pi pi-external-link" onClick={() => navigate('/campaign')} />
            <Button className='settings-add-button' label="Add" icon="pi pi-plus" onClick={() => setCampaignVisible(true)} />
        </div>
    );

    const locationFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            <Button className='settings-add-button' label="Manage" icon="pi pi-cog" onClick={() => setLocationVisible(true)} />
            <Button className='settings-add-button' label="Add" icon="pi pi-plus" onClick={handleGetAccessCode} />
        </div>
    );

    const teamsFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            <Button label="View" icon="pi pi-external-link" onClick={() => navigate('/teams')} />
            <Button className='settings-add-button' label="Add" icon="pi pi-plus" onClick={() => setTeamVisible(true)} />
        </div>
    );

    const usersFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            <Button label="View" icon="pi pi-external-link" onClick={() => navigate('/users')} />
            <Button className='settings-add-button' label="Add" icon="pi pi-plus" onClick={() => setUserVisible(true)} />
        </div>
    );

    const leadersFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            <Button label="View" icon="pi pi-external-link" onClick={() => navigate('/leader')} />
            <Button className='settings-add-button' label="Add" icon="pi pi-plus" onClick={() => setLeaderVisible(true)} />
        </div>
    );

    const labelsFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            <Button className='settings-add-button' label="Manage Labels" icon="pi pi-cog" onClick={() => setLabelsVisible(true)} />
        </div>
    );

    const pipelinesFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            <Button className='settings-add-button' label="Manage Pipelines" icon="pi pi-cog" onClick={() => setPipelinesVisible(true)} />
        </div>
    );

    const designationsFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            <Button className='settings-add-button' label="Manage Designations" icon="pi pi-cog" onClick={() => setDesignationsVisible(true)} />
        </div>
    );

    const customFieldsFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            <Button className='settings-add-button' label="Manage Custom Fields" icon="pi pi-cog" onClick={() => setCustomFieldsVisible(true)} />
        </div>
    );

    const companiesFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            <Button className='settings-add-button' label="Manage Companies" icon="pi pi-cog" onClick={() => setCompaniesVisible(true)} />
        </div>
    );

    const availabilitiesFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            {/* <Button className='settings-add-button' label="Manage User Availabilities" icon="pi pi-cog" onClick={() => setAvailabilitiesVisible(true)} /> */}
            <Button className='settings-add-button' label="Manage User Availabilities" icon="pi pi-cog" onClick={() => navigate('/user-availabilties')} />
        </div>
    );

    const checkListTemplatesFooter = (
        <div className="flex flex-wrap justify-content-end gap-2 card-footer">
            {/* <Button label="View" icon="pi pi-external-link" onClick={()=>navigate('/leader')}/> */}
            <Button className='settings-add-button' label="Manage CheckList Templates" icon="pi pi-cog" onClick={() => setCheckListTemplatesVisible(true)} />
        </div>
    );

    const handleLanguageSubmit = () => {
        if (newLanguages) {
            axios.post(addNewLanguages, newLanguages, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then((response) => {
                    if (response.status === 201) {
                        // console.log(response.data)
                        setIsMounted(!isMounted)
                        setLanguageVisible(false)
                        setNewLanguages(null)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'New Languages Added', life: 3000 });
                    }
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                })
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select atleast one language', life: 3000 });
        }
    }

    const handleCampaignSubmit = () => {
        if (newCampaign) {
            const searchLower = newCampaign.replace(/\s/g, '').toLowerCase();

            // Use Array.prototype.some() to check if any element matches the lowercase search string
            const isPresent = campaignToExclude.some(item => item.replace(/\s/g, '').toLowerCase() === searchLower);

            if (isPresent) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'No duplicate campaign allowed', life: 3000 });
            }
            else {
                axios.post(addNewCampaign, { newCampaign }, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then((response) => {
                        if (response.status === 201) {
                            // console.log(response.data)
                            setIsMounted(!isMounted)
                            setCampaignVisible(false)
                            setNewCampaign(null)
                            toast.current.show({ severity: 'success', summary: 'Success', detail: 'New Campaign Added', life: 3000 });
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    })
            }

        }
        else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Campaign is empty', life: 3000 });
        }
    }

    const handleLocationSubmit = () => {
        axios.post(locationManage, { locations: locations }, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setIsMounted(!isMounted)
                    setLocationVisible(false)
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleLocationAddSubmit = () => {
        if (newLocationId) {
            setLoading(true);
            const data = {
                newLocationId,
                AccessCode
            }
            axios.post(locationAdd, data, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(false);
                        setLocationAddVisible(false)
                        setAccessCode('')
                        setNewLocationId('')
                        setIsMounted(!isMounted)
                        clearParam()
                        toast.current.show({ severity: 'success', summary: 'Success', detail: response.data, life: 2000 });
                    }
                    else {
                        setLoading(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 2000 });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data, life: 2000 });
                })
        }
        else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the Location ID', life: 2000 });
        }

    }

    function isPasswordValid(password) {
        // Regular expressions for each rule
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

        // Check if the password meets all the rules
        const isLengthValid = password.length >= 8;
        const hasUppercase = uppercaseRegex.test(password);
        const hasLowercase = lowercaseRegex.test(password);
        const hasDigit = digitRegex.test(password);
        const hasSpecialChar = specialCharRegex.test(password);

        // Return true if all rules are met, otherwise false
        return isLengthValid && hasUppercase && hasLowercase && hasDigit && hasSpecialChar;
    }

    function validateUsername(username) {
        // Define a regular expression pattern for allowed characters (letters and numbers)
        var pattern = /^[a-zA-Z0-9]+$/;

        // Test the username against the pattern
        var isValid = pattern.test(username);

        // Check if the username is valid
        if (isValid) {
            return true;
        } else {
            return false;
        }
    }

    const handleLeaderSubmit = () => {
        // e.preventDefault();

        const trimmedUserName = userName ? userName.trim() : null;
        const trimmedPassword = password ? password.trim() : null;

        if (trimmedUserName == null || trimmedUserName === '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter the user name', life: 2000 });
        } else if (userName.includes(registeredUserName)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Username is already taken. Please choose a different one.', life: 2000 });
        } else if (trimmedPassword == null || trimmedPassword === '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter the password', life: 2000 });
        } else if (allowedTeams == null || allowedTeams == [] || allowedTeams == '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a team', life: 2000 });
        } else if (!selectedUser || selectedUser == null || selectedUser == {} || selectedUser == '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a user', life: 2000 });
        } else if (!designation) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a Designation', life: 2000 });
        } else if (!company) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a Company', life: 2000 });
        }
        else {

            const isValid_username = validateUsername(userName);

            if (isValid_username) {
                const isValid = isPasswordValid(password);

                if (isValid) {
                    const data = {
                        userName,
                        password,
                        allowedTeams,
                        selectedUser,
                        designation_id: designation,
                        company_id : company
                    };
                    // console.log(data)
                    axios.post(leaderCreate, data, {
                        headers: { 'Content-Type': 'application/json' },
                    })
                        .then((response) => {
                            if (response.status === 201) {
                                setIsMounted(!isMounted)
                                setLeaderVisible(false)
                                setUserName('')
                                setPassword('')
                                setDesignation('')
                                setCompany('')
                                setAllowedTeams(null)
                                setSeletedUser(null)
                                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            }
                        })
                        .catch((error) => {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        })
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'The password must be more than 8 characters including uppercase, lowercase, numbers and special characters', life: 3000 });
                }
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Username is invalid. Please use only letters and numbers', life: 3000 });
            }


        }
    }

    const handleUserSubmit = () => {
        // e.preventDefault();

        const trimmedUserName = userName ? userName.trim() : null;
        const trimmedPassword = password ? password.trim() : null;

        if (trimmedUserName == null || trimmedUserName === '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter the user name', life: 2000 });
        } else if (userName.includes(registeredUserName)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Username is already taken. Please choose a different one.', life: 2000 });
        } else if (trimmedPassword == null || trimmedPassword === '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter the password', life: 2000 });
        } else if (!selectedUser || selectedUser == null || selectedUser == {} || selectedUser == '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a user', life: 2000 });
        } else if (!designation) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a Designation', life: 2000 });
        } else if (!company) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a Company', life: 2000 });
        }
        else {

            const isValid_username = validateUsername(userName);

            if (isValid_username) {
                const isValid = isPasswordValid(password);

                if (isValid) {
                    const data = {
                        userName,
                        password,
                        selectedUser,
                        designation_id: designation,
                        company_id : company
                    };
                    // console.log(data)
                    axios.post(userCreate, data, {
                        headers: { 'Content-Type': 'application/json' },
                    })
                        .then((response) => {
                            if (response.status === 201) {
                                setIsMounted(!isMounted)
                                setUserVisible(false)
                                setUserName('')
                                setPassword('')
                                setDesignation('')
                                setCompany('')
                                setSeletedUser(null)
                                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            }
                        })
                        .catch((error) => {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        })
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'The password must be more than 8 characters including uppercase, lowercase, numbers and special characters', life: 3000 });
                }
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Username is invalid. Please use only letters and numbers', life: 3000 });
            }


        }
    }

    const handleTeamSubmit = () => {
        // e.preventDefault();

        const trimmedTeamName = teamName ? teamName.trim() : null;

        if (trimmedTeamName == null || trimmedTeamName === '') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter the team name', life: 2000 });
        }
        else {
            const data = {
                teamName,
                teamLocations
            };

            axios.post(teamCreate, data, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    if (response.status === 201) {
                        setIsMounted(!isMounted)
                        setTeamVisible(false)
                        setTeamName('')
                        setTeamLocations(null)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    }
                    else if (response.status === 207) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Team Name already exist please enter a new name!', life: 3000 });
                    }
                    else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    }
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                })
        }
    }

    const languageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleLanguageSubmit} autoFocus />
        </div>
    );

    const campaignAddFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleCampaignSubmit} autoFocus />
        </div>
    );

    const locationManageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleLocationSubmit} autoFocus />
        </div>
    );

    const locationAddFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" loading={loading} autoFocus onClick={handleLocationAddSubmit} />
        </div>
    );

    const teamManageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleTeamSubmit} autoFocus />
        </div>
    );

    const userManageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleUserSubmit} autoFocus />
        </div>
    );

    const leaderManageFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleLeaderSubmit} autoFocus />
        </div>
    );

    const handleLanguageOnHide = () => {
        setNewLanguages(null)
        setLanguageVisible(false)
    }

    const handleCampaignOnHide = () => {
        setNewCampaign(null)
        setCampaignVisible(false)
    }

    const handleLocationOnHide = () => {
        setLocationVisible(false)
        setIsMounted(!isMounted)
    }

    const handleLocationAddOnHide = () => {
        setLocationAddVisible(false)
        setAccessCode('')
        setNewLocationId('')
        setIsMounted(!isMounted)
        clearParam()
    }

    const handleLeaderOnHide = () => {
        setLeaderVisible(false)
        setUserName('')
        setPassword('')
        setDesignation('')
        setCompany('')
        setAllowedTeams(null)
        setSeletedUser(null)
        // setIsMounted(!isMounted)
    }

    const handleUserOnHide = () => {
        setUserVisible(false)
        setUserName('')
        setPassword('')
        setDesignation('')
        setCompany('')
        setSeletedUser(null)
    }

    const handlelabelsOnHide = () => {
        setLabelsVisible(false)
    }

    const handlePipelinesOnHide = () => {
        setPipelinesVisible(false)
    }

    const handleDesignationsOnHide = () => {
        setDesignationsVisible(false)
    }

    const handleCustomFieldsOnHide = () => {
        setCustomFieldsVisible(false)
    }

    const handleCompaniesOnHide = () => {
        setCompaniesVisible(false)
    }

    const handleAvailabilitesOnHide = () => {
        setAvailabilitiesVisible(false)
    }

    const handleChecklistTemplatesOnHide = () => {
        setCheckListTemplatesVisible(false)
    }

    const handleTeamOnHide = () => {
        setTeamVisible(false)
        setTeamName('')
        setTeamLocations(null)
    }

    const handleDisplayed = (display, location_id) => {
        console.log(location_id)
        console.log(display)

        // Update the locations array with the new checked value
        const updatedLocations = locations.map(location => {
            if (location.location_id === location_id) {
                return { ...location, is_displayed: display };
            }
            return location;
        });

        // Set the updated locations array using setLocations
        setLocations(updatedLocations);
    }

    const displayBodyTemplate = (rowData) => {
        const location_id = rowData.location_id
        return <Checkbox onChange={e => handleDisplayed(e.checked, location_id)} checked={rowData.is_displayed}></Checkbox>;
    };

    const handlePasswordVisible = () => {
        setPasswordVisible(!passwordVisible)
    }

    return (
        <>
            <Toast ref={toast} />
            <div className='settings-card-container'>
                <div className="card-container flex justify-content-center">
                    <Card title="Languages" subTitle={`${languageCount} Languages`} footer={footer} className="md:w-25rem card">
                    </Card>
                </div>

                <div className="card-container flex justify-content-center">
                    <Card title="Campaigns" subTitle={`${campaignCount} Campaigns`} footer={campaignFooter} className="md:w-25rem card">
                    </Card>
                </div>

                <div className="card-container flex justify-content-center">
                    <Card title="Active Locations" subTitle={`${locationCount} Active Locations`} footer={locationFooter} className="md:w-25rem card">
                    </Card>
                </div>

                <div className="card-container flex justify-content-center">
                    <Card title="Teams" subTitle={`${teamCount} Teams`} footer={teamsFooter} className="md:w-25rem card">
                    </Card>
                </div>

                <div className="card-container flex justify-content-center">
                    <Card title="Team Leaders" subTitle={`${leaderCount} Team Leaders`} footer={leadersFooter} className="md:w-25rem card">
                    </Card>
                </div>

                <div className="card-container flex justify-content-center">
                    <Card title="Users" subTitle={`${userCount} Users`} footer={usersFooter} className="md:w-25rem card">
                    </Card>
                </div>

                <div className="card-container flex justify-content-center"  >
                    <Card title="Labels" subTitle={labelsCount ? `${labelsCount} Labels` : ''} footer={labelsFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" >
                    <Card title="Pipelines" footer={pipelinesFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" >
                    <Card title="Designations" footer={designationsFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" >
                    <Card title="Custom Fields" footer={customFieldsFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" >
                    <Card title="Companies" footer={companiesFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" >
                    <Card title="User Availabilities" footer={availabilitiesFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" >
                    <Card title="CheckList Templates" footer={checkListTemplatesFooter} className="md:w-25rem card" >
                    </Card>
                </div>
                <div className="card-container flex justify-content-center" style={{ background: "transparent" }} ></div>
            </div>

            <Dialog header="Add New Languages" visible={languageVisible} style={{ width: '50vw' }} onHide={handleLanguageOnHide} footer={languageFooter}>
                <div>
                    <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <MultiSelect value={newLanguages} onChange={(e) => setNewLanguages(e.value)} options={filteredPopularLanguages} optionLabel="name"
                                filter placeholder="Select Languages" maxSelectedLabels={3} className="w-full md:w-20rem language-multiselect" />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Add New Campaign" visible={campaignVisible} style={{ width: '50vw' }} onHide={handleCampaignOnHide} footer={campaignAddFooter}>
                <div>
                    <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <InputText type="text" className="p-inputtext-lg language-multiselect" placeholder="Campaign" value={newCampaign} onChange={(e) => setNewCampaign(e.target.value)} />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Manage Locations" visible={locationVisible} style={{ width: '50vw' }} onHide={handleLocationOnHide} footer={locationManageFooter}>
                <div>
                    <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <DataTable value={locations}>
                                <Column field="location_name" header="Location" sortable style={{ width: '80%' }}></Column>
                                <Column field="is_displayed" header="Display" body={displayBodyTemplate} sortable style={{ width: '20%' }}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Add New Location" visible={locationAddVisible} style={{ width: '50vw' }} onHide={handleLocationAddOnHide} footer={locationAddFooter}>
                <div>
                    <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <InputText type="text" className="p-inputtext-lg language-multiselect" placeholder="Location ID" value={newLocationId} onChange={(e) => setNewLocationId(e.target.value)} />
                        </div>
                        <div className="card flex justify-content-center">
                            <InputText type="text" className="p-inputtext-lg language-multiselect" placeholder="Code" value={AccessCode} disabled />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Add Team Leaders" visible={leaderVisible} style={{ width: '50vw' }} onHide={handleLeaderOnHide} footer={leaderManageFooter}>
                <div>
                    <div className='purchase-form-group'>
                        {/* <div className="card flex justify-content-center"> */}
                        <form className='user-form'>

                            <div className="flex justify-content-center one-fields-flex">
                                <div className="flex flex-column gap-2 lable-input-group" style={{ width: '100%' }}>
                                    <label htmlFor="userrole">Select User</label>
                                    <Dropdown value={selectedUser} onChange={(e) => setSeletedUser(e.value)} options={unRegisteredUsers} optionLabel="name"
                                        placeholder="Select a User" filter className="w-full md:w-14rem purchase-dropdown" />
                                </div>
                            </div>

                            <div className="flex justify-content-center two-fields-flex">
                                <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="firstname">User Name</label>
                                    <InputText type='text' value={userName} className='full-input' id="firstname" aria-describedby="firstname-help" onChange={(e) => setUserName(e.target.value)} />
                                </div>

                                <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="password">Password *</label>
                                    <span className="p-input-icon-right full-input">
                                        <i className={`pi ${passwordVisible ? 'pi-eye' : 'pi-eye-slash'} password-icon`} onClick={handlePasswordVisible} />
                                        <InputText value={password} type={`${passwordVisible ? 'text' : 'password'}`} className='full-input' id="password" aria-describedby="password-help" onChange={(e) => setPassword(e.target.value)} />
                                    </span>
                                </div>
                            </div>

                            <div className="flex justify-content-center two-fields-flex">
                                <div className="flex flex-column gap-2 lable-input-group" >
                                    <label htmlFor="userrole">Allowed Teams</label>
                                    <MultiSelect value={allowedTeams} onChange={(e) => setAllowedTeams(e.value)} options={activeTeams} optionLabel="name"
                                        filter placeholder="Select Teams" maxSelectedLabels={3} className="w-full md:w-20rem language-multiselect" />
                                </div>
                                <div className="flex  gap-2 lable-input-group">
                                    <label htmlFor="designation">Designation *</label>
                                    <Dropdown
                                        id="designation"
                                        value={designation}
                                        options={designationList.map(designation => ({
                                            label: designation.title,
                                            value: designation.id,
                                        })) || []}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            setDesignation(e.value)
                                            // handleAddDesignation(e.value, userId)
                                        }}
                                        placeholder="Select Designation"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-content-center two-fields-flex">
                            <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="password">Company *</label>
                                    <Dropdown
                                        id="company"
                                        value={company}
                                        options={companiesList.map(company => ({
                                            label: company.title,
                                            value: company.id,
                                        })) || []}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            setCompany(e.value)
                                            // handleAddDesignation(e.value, userId)
                                        }}
                                        placeholder="Select Company"
                                    />
                                </div>
                            
                            </div>

                        </form>
                        {/* </div> */}
                    </div>
                </div>
            </Dialog>

            <Dialog header="Add Teams" visible={teamVisible} style={{ width: '50vw' }} onHide={handleTeamOnHide} footer={teamManageFooter}>
                <div>
                    <div className='purchase-form-group'>
                        {/* <div className="card flex justify-content-center"> */}
                        <form className='user-form'>

                            {/* <div className="flex justify-content-center two-fields-flex"> */}
                            <div className="flex flex-column gap-2 lable-input-group" style={{ width: '100%' }}>
                                <label htmlFor="teamName">Team Name</label>
                                <InputText type='text' value={teamName} className='full-input' id="teamName" aria-describedby="teamName-help" onChange={(e) => setTeamName(e.target.value)} />
                            </div>

                            {/* </div> */}

                            <div className="flex justify-content-center one-fields-flex">
                                <div className="flex flex-column gap-2 lable-input-group" style={{ width: '100%' }}>
                                    <label htmlFor="userrole">Allowed Locations</label>
                                    <MultiSelect value={teamLocations} onChange={(e) => setTeamLocations(e.value)} options={activeLocations} optionLabel="location_name"
                                        filter placeholder="Select Locations" maxSelectedLabels={3} className="w-full md:w-20rem language-multiselect" />
                                </div>
                            </div>
                        </form>
                        {/* </div> */}
                    </div>
                </div>
            </Dialog>

            <Dialog header="Add Users" visible={userVisible} style={{ width: '50vw' }} onHide={handleUserOnHide} footer={userManageFooter}>
                <div>
                    <div className='purchase-form-group'>
                        {/* <div className="card flex justify-content-center"> */}
                        <form className='user-form'>

                            <div className="flex justify-content-center one-fields-flex">
                                <div className="flex flex-column gap-2 lable-input-group" style={{ width: '100%' }}>
                                    <label htmlFor="userrole">Select User</label>
                                    <Dropdown value={selectedUser} onChange={(e) => setSeletedUser(e.value)} options={unRegisteredUsers} optionLabel="name"
                                        placeholder="Select a User" filter className="w-full md:w-14rem purchase-dropdown" />
                                </div>
                            </div>

                            <div className="flex justify-content-center two-fields-flex">
                                <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="firstname">User Name</label>
                                    <InputText type='text' value={userName} className='full-input' id="firstname" aria-describedby="firstname-help" onChange={(e) => setUserName(e.target.value)} />
                                </div>

                                <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="password">Password *</label>
                                    <span className="p-input-icon-right full-input">
                                        <i className={`pi ${passwordVisible ? 'pi-eye' : 'pi-eye-slash'} password-icon`} onClick={handlePasswordVisible} />
                                        <InputText value={password} type={`${passwordVisible ? 'text' : 'password'}`} className='full-input' id="password" aria-describedby="password-help" onChange={(e) => setPassword(e.target.value)} />
                                    </span>
                                </div>
                            </div>
                            
                            <div className="flex justify-content-center two-fields-flex">
                            <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="password">Designation *</label>
                                    <Dropdown
                                        id="designation"
                                        value={designation}
                                        options={designationList.map(designation => ({
                                            label: designation.title,
                                            value: designation.id,
                                        })) || []}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            setDesignation(e.value)
                                            // handleAddDesignation(e.value, userId)
                                        }}
                                        placeholder="Select Designation"
                                    />
                                </div>

                                <div className="flex flex-column gap-2 lable-input-group">
                                    <label htmlFor="password">Company *</label>
                                    <Dropdown
                                        id="company"
                                        value={company}
                                        options={companiesList.map(company => ({
                                            label: company.title,
                                            value: company.id,
                                        })) || []}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            setCompany(e.value)
                                            // handleAddDesignation(e.value, userId)
                                        }}
                                        placeholder="Select Company"
                                    />
                                </div>
                            </div>

                        </form>
                        {/* </div> */}
                    </div>
                </div>
            </Dialog>

            <Dialog header="Manage Labels" visible={labelsVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handlelabelsOnHide} >
                <Labels toast={toast} />
            </Dialog>

            <Dialog header="Manage Pipelines" visible={pipelinesVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handlePipelinesOnHide} >
                <Pipelines toast={toast} />
            </Dialog>

            <Dialog header="Manage Designations" visible={designationsVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handleDesignationsOnHide} >
                <Designations toast={toast} />
            </Dialog>

            <Dialog header="Manage Custom Fields" visible={customFieldsVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handleCustomFieldsOnHide} >
                <CustomFields toast={toast} />
            </Dialog>

            <Dialog header="Manage Companies" visible={companiesVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handleCompaniesOnHide} >
                <Companies toast={toast} />
            </Dialog>

            <Dialog header="Manage Availabilities" visible={availabilitiesVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handleAvailabilitesOnHide} >
                <UserAvailabilitiesTable toast={toast} />
            </Dialog>

            <Dialog header="Manage Checklist Templates" visible={checkListTemplatesVisible} style={{ width: '50vw', minHeight: '65vh' }} onHide={handleChecklistTemplatesOnHide} >
                <CheckListTemplates toast={toast} />
            </Dialog>
        </>
    )
}