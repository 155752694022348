import { BubblyLink } from "../library/src";
import { Button } from 'primereact/button';
import './Nav.css'
import MobileNav from "./MobileNav";
import { useNavigate } from "react-router-dom";
import { TieredMenu } from "primereact/tieredmenu";
import FlexComponent from "../components/FlexComponent";
import logo from '../Images/homes_sale_logo.png'
import { useEffect, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
// import Notification from "./Notification";
import { getPipelines } from "../utils/urls";
import { useSelector } from "react-redux";
import axios from '../utils/axios';
import { NotificationComponent } from "./Notification";

const MyBubblyLink = ({ to = "", text = "", icon = '', style }) => (
    <BubblyLink to={to} colorStart="#da474e" colorEnd="#ffffff">
        <Button style={style} icon={icon}>
            {text}
        </Button>
    </BubblyLink>
);

export const Nav = ({ handleLogout, setPipelineName, setBoardSections, isSuperuser, userLable, image, userName, is_leader }) => {
    const navigate = useNavigate()
    const menu = useRef(null);
    const formMenu = useRef(null)
    const superUserFormMenu = useRef(null)
    const userId = useSelector(state => state.user.userId)
    const appMenuu = useRef(null)
    const pipelineMenu = useRef(null)
    const availabilityMenu = useRef(null)
    const leaderBoardMenu = useRef(null)
    const [pipelines, setPipelines] = useState([])


    const getAllPipelines = () => {
        if (userId) {

            const url = `${getPipelines}?userId=${userId}`
            axios.get(url, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response.data)
                        const options = response?.data?.map((pipeline) => ({
                            label: pipeline.name,
                            value: pipeline.id,
                        })) || [];
                        setPipelines(options)
                    }
                })
                .catch((error) => {
                    // setAppLeads(null)
                })
        }
    }
    useEffect(() => {
        getAllPipelines()
    }, [userId])

    // console.log("isSuperuser", isSuperuser)
    return (
        <FlexComponent flexDirection="column" alignItems="start">
            <FlexComponent>
                {isSuperuser ?
                    <FlexComponent className="navbarDiv" justifyContent="space-evenly" gap="4rem" style={{ marginLeft: '15%' }}>
                        {/* <img src={logo} style={{ width: '50px', height: '50px' }} alt="" /> */}
                        <nav className="navbar-item animate-in large-nav" style={{ animationDelay: "800ms" }}>

                            <MyBubblyLink to="/" text="Dashboard" />
                            {/* <MyBubblyLink to="/leader-board" text="Leaderboard" /> */}

                            <TieredMenu model={[
                                { label: 'Caller Leaderboard', command: () => navigate('/callers-leaderboard') },
                                { label: 'Shopper Leaderboard', command: () => navigate('/shoppers-leaderboard') },
                            ]} popup ref={leaderBoardMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Leaderboard <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => leaderBoardMenu.current.toggle(e)} />

                            <TieredMenu model={pipelines?.map(item => ({
                                label: item.label,
                                command: () => navigate(`/app-kanban/${item.value}`)
                            }))} popup ref={pipelineMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Pipelines <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => pipelineMenu.current.toggle(e)} />

                            <TieredMenu model={[
                                { label: 'Campaigns', command: () => navigate('/campaign') },
                                { label: 'Teams', command: () => navigate('/team') },
                                { label: 'Participants', command: () => navigate('/participant') },
                                { label: 'Purchases', command: () => navigate('/purchase') },
                            ]} popup ref={menu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent style={{ fontWeight: '400' }} >Campaign Management <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>} onClick={(e) => menu.current.toggle(e)} />

                            <TieredMenu model={[
                                { label: 'New Mortgage App', command: () => navigate('/new-app') },
                                { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                                { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                                { label: 'Short Form App', command: () => navigate('/new-app-short') },
                            ]} popup ref={superUserFormMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent style={{ fontWeight: '400' }} >Forms <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>} onClick={(e) => superUserFormMenu.current.toggle(e)} />
                            {/* <div className="logout-button-container" style={{paddingRight:"10px"}}>
                                <MyBubblyLink to="/form" text="📃 New Application" style={{ background: "#DB424A", color: "white", width: '200px', textDecoration: 'none' }} />
                            </div> */}

                        </nav>
                    </FlexComponent>
                    :
                    <FlexComponent className="navbarDiv" justifyContent="space-evenly" gap="4rem" style={{ marginLeft: '15%' }}>
                        <nav className="navbar-item animate-in large-nav" style={{ animationDelay: "800ms" }}>
                            <MyBubblyLink to="/" text="Dashboard" />
                            {is_leader &&
                                <MyBubblyLink to="/participant" text="Participants" />
                            }
                            {/* <MyBubblyLink to="/app-kanban" text="App" breakpoint="767px" /> */}
                            {/* <MyBubblyLink to="/leader-board" text="Leaderboard" /> */}
                            <TieredMenu model={[
                                { label: 'Caller Leaderboard', command: () => navigate('/callers-leaderboard') },
                                { label: 'Shopper Leaderboard', command: () => navigate('/shoppers-leaderboard') },
                            ]} popup ref={leaderBoardMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Leaderboard <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => leaderBoardMenu.current.toggle(e)} />

                            <TieredMenu model={pipelines?.map(item => ({
                                label: item.label,
                                command: () => navigate(`/app-kanban/${item.value}`)
                            }))} popup ref={pipelineMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Pipelines <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => pipelineMenu.current.toggle(e)} />

                            <TieredMenu model={[
                                { label: 'Live Assignment', command: () => navigate(`/user-availability/${userId}`) },
                                { label: 'Appointment', command: () => navigate(`/user-availability-appointment/${userId}`) },
                            ]} popup ref={availabilityMenu} breakpoint="767px" />
                            <Button style={{ padding: '0.5rem' }} label={<FlexComponent gap="0.2rem" style={{ fontWeight: '400' }} >
                                Availability <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>}
                                onClick={(e) => availabilityMenu.current.toggle(e)} />
                            {/* <MyBubblyLink to={`/user-availability/${userId}`} text="User Availability" /> */}

                        </nav>
                        <div className="logout-button-container" style={{ paddingLeft: "10px" }} >
                            {/* <MyBubblyLink to="/form" text="📃 New Application" style={{ background: "#DB424A", color: "white", width: '200px', textDecoration: 'none' }} /> */}
                            <TieredMenu model={[
                                { label: 'New Mortgage App', command: () => navigate('/new-app') },
                                { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                                { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                                { label: 'Short Form App', command: () => navigate('/new-app-short') }
                            ]} popup ref={formMenu} breakpoint="767px" />
                            <Button style={{ boxShadow: "none", padding: '0.5rem' }} label={<FlexComponent style={{ fontWeight: '400' }} >Forms <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>} onClick={(e) => formMenu.current.toggle(e)} />

                        </div>
                    </FlexComponent>
                }
                {/* <FlexComponent> */}
                <MobileNav isSuperuser={isSuperuser} is_leader={is_leader} />

                {isSuperuser ? <FlexComponent className="newApplication" >
                    {/* <MyBubblyLink to="/form" text="📃 New Application" style={{ background: "#DB424A", color: "white", width: '190px', textDecoration: 'none' }} /> */}
                    <TieredMenu model={[
                        { label: 'New Mortgage App', command: () => navigate('/new-app') },
                        { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                        { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                        { label: 'Short Form App', command: () => navigate('/new-app-short') },
                    ]} popup ref={superUserFormMenu} breakpoint="767px" />
                    <Button style={{ boxShadow: "none", padding: '0.5rem' }} label={<FlexComponent style={{ fontWeight: '400' }} >Forms <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>} onClick={(e) => superUserFormMenu.current.toggle(e)} />

                    <Button style={{ margin: '0 2rem' }} className='logout-link' icon="pi pi-power-off" onClick={handleLogout} />
                </FlexComponent> :
                    <FlexComponent className="newApplication" >
                        {/* <MyBubblyLink to="/form" text="📃 New Application" style={{ background: "#DB424A", color: "white", width: '190px', textDecoration: 'none' }} /> */}
                        <TieredMenu model={[
                            { label: 'New Mortgage App', command: () => navigate('/new-app') },
                            { label: 'New Buyer App', command: () => navigate('/new-buyer-app') },
                            { label: 'New Loan SetUp Form', command: () => navigate('/new-loan-set-up-form') },
                            { label: 'Short Form App', command: () => navigate('/new-app-short') },
                        ]} popup ref={formMenu} breakpoint="767px" />
                        <Button style={{ boxShadow: "none", padding: '0.5rem' }} label={<FlexComponent style={{ fontWeight: '400' }} >Forms <i className="pi pi-chevron-down" style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '3px' }}></i></FlexComponent>} onClick={(e) => formMenu.current.toggle(e)} />

                        <Button style={{ margin: '0 2rem' }} className='logout-link' icon="pi pi-power-off" onClick={handleLogout} />
                    </FlexComponent>
                }
                {/* </FlexComponent> */}
                <div className="logout-button-container display-desktop">
                    <FlexComponent alignItems='center' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }}>
                        {image ?
                            <Avatar style={{ color: '#717B8C', height: "45px" }} image={image} size="large" shape='circle' />
                            :
                            <Avatar style={{ color: '#717B8C', height: "45px" }} label={userLable} size="large" shape='circle' />
                        }
                        <div className="userText" >
                            <p style={{ fontSize: '12px', color: '#717B8C', minWidth: "90px" }}>Welcome Back!</p>
                            <p style={{ fontSize: '12px', color: '#717B8C' }}>{userName}</p>
                        </div>
                        {/* ///////// */}
                        <NotificationComponent setBoardSections={setBoardSections} />
                        {/* ////////////////// */}
                    </FlexComponent>
                    {isSuperuser &&
                        <div className="settingsIcon">
                            <MyBubblyLink icon="pi pi-cog" to="/settings" />
                        </div>
                    }
                    <Button style={{ margin: '0 2rem', padding: "0.5rem 1rem" }} className='logout-link' icon="pi pi-power-off" onClick={handleLogout} />
                </div>
            </FlexComponent>
            <div className="navbar-logo" style={{ position: 'absolute', marginTop: "20px", marginLeft: "8%" }}>
                <img src={logo} style={{ width: '80px', height: '80px', borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 1px" }} alt="" />
            </div>
        </FlexComponent>
    )
};
