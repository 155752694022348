import { Button } from 'primereact/button'
import React, { useEffect, useRef, useState } from 'react'
import { addLabel, getLabel, removeLabel } from '../../../utils/urls'
import axios from '../../../utils/axios';
import FlexComponent from '../../FlexComponent';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';

function Label({ setBoardSections, labels, toast, formId, userId }) {
    const [labelsList, setLabelsList] = useState([])
    const [selectedLists, setSelectedLists] = useState(labels);

    const labelOp = useRef(null)
    const getLabelsList = () => {
        const labelsUrl = `${getLabel}`
        axios.get(labelsUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setLabelsList(response.data)
                    // setSelectedLists(response.data)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
            })
    }

    const handleAddLabel = (label) => {
        // setLoading(true)
        const data = {
            label_id: label.id
        }
        const url = `${addLabel}${formId}/${userId}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    const newLabelObj = {
                        id: label.id,
                        name: label.name,
                        color: label.color
                    }
                    setBoardSections(prevSections => {
                        const updatedSections = { ...prevSections };
                        for (const sectionId in updatedSections) {
                            updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                if (item.form_id === formId) {
                                    return {
                                        ...item,
                                        labels: [newLabelObj, ...item.labels]
                                    };
                                }
                                return item;
                            });
                        }
                        return updatedSections;
                    });

                    // setLabelName('')
                    // toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    // setLoading(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleRemoveLable = (labelId) => {
        // setLoading(true)
        const data = {
            label_id: labelId
        }
        const url = `${removeLabel}${formId}/${userId}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setBoardSections(prevSections => {
                        const updatedSections = { ...prevSections };
                        for (const sectionId in updatedSections) {
                            updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                                if (item.form_id === formId) {
                                    return {
                                        ...item,
                                        labels: item.labels.filter(label => label.id !== labelId)
                                    };
                                }
                                return item;
                            });
                        }
                        return updatedSections;
                    });
                    // setLabelName('')
                    // toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    // setLoading(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const onLabelsChange = (e) => {
        let lists = [...selectedLists];

        if (e.checked) {
            lists.push(e.value);
            // handleAddLabel(e.value.id)
            handleAddLabel(e.value)
        }
        else {
            lists = lists.filter(list => list.id !== e.value.id);
            handleRemoveLable(e.value.id)
        }
        setSelectedLists(lists);
    };

    useEffect(() => {
        getLabelsList()
    }, [])

    return (
        <div className="applicantDivs" >
            {/* <p>Labels</p> */}
            <p> <i className="pi pi-wallet" style={{marginRight: "10px"}} ></i> Labels</p>
            <FlexComponent justifyContent="start" gap=".5rem" style={{ marginTop: ".6rem", paddingLeft: "1rem" }} >
                <Button onClick={(e) => {
                    labelOp.current.toggle(e)
                }} style={{ padding: ".5rem", border:"1px solid grey", boxShadow:"none" }} ><i className='pi pi-plus'></i></Button>
                {selectedLists.map((label) => (
                    <Button key={label.id} style={{ cursor: "auto", border: "none", borderRadius: "3px", boxShadow: "none", height: "30px", background: `#${label.color}` }} >
                        {label.name}
                    </Button>
                ))}
                <OverlayPanel ref={labelOp} style={{ background: "#254270" }} >
                    <div>
                        {labelsList.map((label) => {
                            return (
                                <FlexComponent justifyContent="center" key={label.id} gap=".5rem" style={{ marginTop: ".5rem" }}>
                                    <Checkbox inputId={label.id} name="label" value={label} onChange={onLabelsChange} checked={selectedLists.some((item) => item.id === label.id)} />
                                    {/* <label htmlFor={label.id} className="ml-2" style={{background : `#${label.color}`}} >
                                        {label.name}
                                    </label> */}
                                    <FlexComponent justifyContent="center" style={{ height: "30px", width: "200px", background: `#${label.color}`, color: "white" }} >
                                       <p>{label.name}</p> 
                                    </FlexComponent>
                                </FlexComponent>
                            );
                        })}
                    </div>

                </OverlayPanel>
            </FlexComponent>
        </div>
    )
}

export default Label