import React from 'react';
import { Button } from 'primereact/button';

const FlexComponent = ({className, justifyContent, flexDirection, flexWrap, alignItems, width, gap, children, style  }) => {
    const containerStyle = {
        display: 'flex',
        justifyContent: justifyContent || 'space-between',
        alignItems: alignItems || 'center',
        flexDirection: flexDirection || 'row',
        flexWrap: flexWrap || 'nowrap',
        width : '100%' || width,
        gap : '' || gap,
        ...style
    };

    return (
        <div className={className} style={containerStyle}>
            {children}
        </div>
    );
};

export default FlexComponent;
