import React,{useContext} from 'react'
import signIn_Img from '../Images/person-working-collaboratively-online (1).png'
import logo from '../Images/HFS.png'
// import { SignUpModeContext } from '../../../Context/SignupModeContext';

function LoginPanel() {

//   const { setSignUpState } = useContext(SignUpModeContext);

  return (
    <>
    <div class="panel left-panel" style={{background: 'rgb(2 34 103 / 80%)'}}>
          <div class="content-panel">
            {/* <h3 style={{marginBottom:"5px"}}>New here ?</h3> */}
            {/* <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis,
              ex ratione. Aliquid!
            </p> */}
            {/* <button onClick={() => setSignUpState(true)} class="btn transparent" id="sign-up-btn">
              Sign up
            </button> */}
          </div>
          <img src={logo} alt="" style={{borderRadius: '20px'}}/>
    </div>
    </>
  )
}

export default LoginPanel