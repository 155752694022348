import React, { useEffect, useState } from 'react'
import { getLabel, labelCreate, labelDelete, labelUpdate } from '../../utils/urls'
import axios from '../../utils/axios';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../FlexComponent';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

function Labels({ toast }) {
    const [labelName, setLabelName] = useState('')
    const [color, setColor] = useState("00ffbb");
    const [editColor, setEditColor] = useState()
    const [editLabelName, setEditLabelName] = useState('')
    const [isAddLabel, setIsAddLabel] = useState(false)
    const [labelsList, setLabelsList] = useState([])
    const [labelEditIndex, setLabelEditIndex] = useState()
    const [isLabelEdit, setIsLabelEdit] = useState()
    const [loading, setLoading] = useState(true)
    const [isMounted, setIsMounted] = useState(false)

    const handleLabelsSubmit = () => {
        setIsMounted(true)
        setIsAddLabel(false)
        const data = {
            name: labelName,
            color: color
        }
        axios.post(labelCreate, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 201) {
                    setLabelName('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleEditSubmit = (id) => {
        setIsLabelEdit(false)
        setIsMounted(true)
        const data = {
            name: editLabelName,
            color: editColor
        }
        const url = `${labelUpdate}${id}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setLabelName('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    // console.log(color)
    const handleClick = () => {
        setIsAddLabel(!isAddLabel)
        setIsLabelEdit(false);
    }

    const handleEdit = (index) => {
        setIsAddLabel(false)
        setIsLabelEdit(true);
        setLabelEditIndex(index);
        setEditLabelName(labelsList[index].name);
        setEditColor(labelsList[index].color);
    };

    const getLabelsList = () => {
        const labelsUrl = `${getLabel}`
        axios.get(labelsUrl, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setLoading(false)
                    setLabelsList(response.data)
                }
            })
            .catch((error) => {
                // setAppLeads(null)
                setLoading(false)
            })
    }

    const handleDelete = (list) => {
        setIsMounted(true)
        confirmDialog({
            message: 'Are you sure you want to delete this Label?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const Url = `${labelDelete}${list.id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 204) {
                            setIsMounted(false)
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            // handleClose()
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // handleClose()
                    })
            },
            reject : ()=>{
                console.log("closed")
            }
        });

    };

    useEffect(() => {
        getLabelsList()
    }, [isMounted])

    return (
        <div>
            <ConfirmDialog />
            {labelsList?.length > 0 &&
                <div>
                    {labelsList.map((list, index) => (
                        <div style={{ marginTop: ".5rem" }} >
                            {!isLabelEdit || labelEditIndex !== index ? (<FlexComponent gap="1rem" >
                                <FlexComponent justifyContent="center" style={{ background: `#${list.color}`, height: "50px", width: "100%" }} >
                                    <p style={{ color: "white" }} >{list.name}</p>
                                </FlexComponent>
                                <Button style={{padding: ".5rem .6rem"}} onClick={() => {
                                    handleEdit(index)
                                }} > <i className='pi pi-pencil' ></i></Button>
                                <Button style={{padding: ".5rem .6rem"}} onClick={() => {
                                    handleDelete(list)
                                }} > <i className='pi pi-trash' ></i></Button>
                            </FlexComponent>) :
                                <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>
                                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                                        <div style={{ background: `#${editColor}`, height: "50px" }} >

                                        </div>
                                    </div>
                                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                                        <label htmlFor="email" style={{ fontWeight: 'bold' }}>Title *</label>
                                        <InputText size="small" style={{ width: '100%' }} id="labelName" value={editLabelName}
                                            onChange={(e) => {
                                                setEditLabelName(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                                        <FlexComponent justifyContent="center" >
                                            <ColorPicker value={editColor} onChange={(e) => setEditColor(e.value)} inline />
                                        </FlexComponent>
                                    </div>
                                    <FlexComponent justifyContent="center" gap="1rem" >
                                        <Button type='button' onClick={() => {
                                            handleEditSubmit(list.id)
                                        }} disabled={!editLabelName.trim()} >Update</Button>
                                        <Button style={{ background: "white", color: " black" }} type='button' onClick={() => {
                                            setIsLabelEdit(false)
                                        }} >Discard Changes</Button>
                                    </FlexComponent>
                                </FlexComponent>
                            }
                        </div>
                    ))}
                </div>
                }

                {loading && <FlexComponent justifyContent="center" >
                <ProgressSpinner/>
                </FlexComponent>}
            <Button style={{ marginTop: ".5rem" }} onClick={handleClick} >Add Label</Button>
            {isAddLabel && <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>
                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <div style={{ background: `#${color}`, height: "50px" }} >

                    </div>
                </div>
                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Title *</label>
                    <InputText size="small" style={{ width: '100%' }} id="labelName" value={labelName}
                        onChange={(e) => {
                            setLabelName(e.target.value)
                        }}
                    />
                </div>
                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <FlexComponent justifyContent="center" >
                        <ColorPicker value={color} onChange={(e) => setColor(e.value)} inline />
                    </FlexComponent>
                </div>
                <FlexComponent justifyContent="center" gap="1rem" >
                    <Button type='button' onClick={handleLabelsSubmit} disabled={!labelName.trim()} >Save</Button>
                    <Button style={{background: "white", color:"black"}} type='button' onClick={() => {
                        setIsAddLabel(false)
                    }} >Cancel</Button>
                </FlexComponent>
            </FlexComponent>}

        </div>
    )
}

export default Labels