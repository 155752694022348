import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './chart.css'

export default function ChartJs({label, leads, replies, oppertunities, preApps, shoppers, outboundCalls, chartAppLeads, loading}) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: label,
            datasets: [
                {
                    label: 'Leads',
                    backgroundColor: '#0f173e',
                    borderColor:'#0f173e',
                    data: leads
                },
                {
                    label: 'Replies',
                    backgroundColor: '#da474e',
                    borderColor: '#da474e',
                    data: replies
                },
                {
                    label: 'Outbound Calls',
                    backgroundColor: '#D3D3D3',
                    borderColor: '#D3D3D3',
                    data: outboundCalls
                },
                {
                    label: 'App Count',
                    backgroundColor: '#898fd3',
                    borderColor: '#898fd3',
                    data: chartAppLeads
                },
                {
                    label: 'Total Docs In',
                    backgroundColor: '#777b92',
                    borderColor: '#777b92',
                    data: oppertunities
                },
                {
                    label: 'Pre Approvals',
                    backgroundColor: '#eeabae',
                    borderColor: '#eeabae',
                    data: preApps
                },
                {
                    label: 'Shoppers',
                    backgroundColor: '#86acd1',
                    borderColor: '#86acd1',
                    data: shoppers
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'end',
                    color: textColor,
                    font: {
                        weight: 'bold',
                        size: 15
                    }
                },
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [label, leads, replies, oppertunities, preApps, outboundCalls, chartAppLeads]);

    return (
        // <div className="card">
        //     <Chart type="bar" data={chartData} options={chartOptions} />
        // </div>

        <div className="card chart-card">
            {loading && (
            <div className="chart-overlay">
                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
            </div>
            )}
            <Chart type="bar" data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
        </div>
    )
}
        