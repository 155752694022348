import React, { useEffect, useState } from 'react'
import { createCustomField, deleteCustomField, deleteDesignation, getCustomFields, getDesignations, getLabel, getPipelines, labelCreate, labelDelete, labelUpdate, updateCustomField, upserDesignation } from '../../utils/urls'
import axios from '../../utils/axios';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../FlexComponent';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

function CustomFields({ toast }) {
    const [title, setTitle] = useState('')
    const [editTitle, setEditTitle] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const userId = useSelector(state => state.user.userId);

    const [isAddCustomField, setIsAddCustomField] = useState(false)
    const [customFieldList, setCustomFieldList] = useState([])
    const [customFieldEditIndex, setCustomFieldEditIndex] = useState()
    const [isCustomFieldEdit, setIsCustomFieldEdit] = useState()
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [optionName, setOptionName] = useState("")
    const [loading, setLoading] = useState(true)
    const [isMounted, setIsMounted] = useState(false)
    
    const handleAddCustomField = () => {
        setIsMounted(true)
        setIsAddCustomField(false)
        const data = {
            title: title,
            field_type: selectedType,
            options: dropdownOptions
        }
        axios.post(createCustomField, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 201) {
                    setTitle('')
                    setDropdownOptions([])
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsMounted(false)
            })
    }

    const handleEditSubmit = (id) => {
        setIsCustomFieldEdit(false)
        setIsMounted(true)
        const data = {
            title: editTitle,
            options: dropdownOptions
        }
        console.log(data);
        const url = `${updateCustomField}${id}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setTitle('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsMounted(false)
            })
    }

    // console.log(color)
    const handleClick = () => {
        setSelectedType('')
        setTitle('')
        setDropdownOptions([])
        setIsAddCustomField(!isAddCustomField)
        setIsCustomFieldEdit(false);
    }

    const handleEdit = (index) => {
        setIsAddCustomField(false)
        setIsCustomFieldEdit(true);
        setCustomFieldEditIndex(index);
        setEditTitle(customFieldList[index].title);
        setDropdownOptions(customFieldList[index]?.options)
    };

    const getAllCustomFields = () => {
        const url = `${getCustomFields}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setLoading(false)
                    setCustomFieldList(response.data)

                }
            })
            .catch((error) => {
                setLoading(false)
                // setAppLeads(null)
            })
    }



    const handleDelete = (list) => {
        setIsMounted(true)
        confirmDialog({
            message: 'Are you sure you want to delete this Custom Field?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const Url = `${deleteCustomField}${list.id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMounted(false)
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            // handleClose()
                            setIsMounted(false)
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // handleClose()
                        setIsMounted(false)
                    })

            },
            reject: () => {
                console.log("closed")
            }
        });

    };


    useEffect(() => {
        getAllCustomFields()
    }, [isMounted])

    const customFieldOptions = [
        { label: "Text", value: "text" },
        { label: "Date Time", value: "datetime" },
        { label: "Number", value: "number" },
        { label: "Single Select", value: "single_select" },
    ]

    return (
        <div>
            <ConfirmDialog />
            {customFieldList?.length > 0 &&
                <div className='customFieldDiv'>
                    {customFieldList.map((list, index) => (
                        <div style={{ marginTop: ".5rem" }} >
                            {!isCustomFieldEdit || customFieldEditIndex !== index ? (<FlexComponent gap="1rem" >
                                <FlexComponent justifyContent="start" style={{ height: "50px", width: "100%" }} >
                                    <p  >{list.title}</p>
                                </FlexComponent>
                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => {
                                    handleEdit(index)
                                }} > <i className='pi pi-pencil' ></i></Button>
                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => {
                                    handleDelete(list)
                                }} > <i className='pi pi-trash' ></i></Button>
                            </FlexComponent>) :
                                <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>
                                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                                        <label htmlFor="Title" style={{ fontWeight: 'bold' }}>Title * </label>
                                        <span>(Type - {customFieldOptions?.find((item)=> item.value === list.field_type)?.label})</span>
                                        <InputText size="small" style={{ width: '100%', marginTop:".5rem" }} id="title" value={editTitle}
                                            onChange={(e) => {
                                                setEditTitle(e.target.value)
                                            }}
                                        />
                                    </div>

                                    {list.field_type === "single_select" &&
                                        <FlexComponent flexDirection="column" alignItems="start" >
                                            <label htmlFor="Options" style={{ fontWeight: 'bold' }}>Options</label>
                                            {dropdownOptions?.map((option) => (
                                                <FlexComponent style={{ width: "70%", padding: ".2rem .4rem" }} >
                                                    <p>{option}</p>
                                                    <Button onClick={() => {
                                                        const updatedItems = dropdownOptions.filter((list) => list !== option)
                                                        setDropdownOptions(updatedItems)
                                                    }} style={{ padding: ".3rem" }} ><i className='pi pi-trash'></i></Button>
                                                </FlexComponent>
                                            ))}
                                            <InputText size="small" style={{ width: '100%', marginBottom: ".3rem" }} id="title" value={optionName}
                                                onChange={(e) => {
                                                    setOptionName(e.target.value)
                                                }}
                                            />
                                            <Button disabled={!optionName.trim()} onClick={() => {
                                                setDropdownOptions((prev) => [...prev, optionName])
                                                setOptionName('')
                                            }} style={{ padding: ".3rem" }} ><i className='pi pi-plus'></i>Add Option</Button>
                                        </FlexComponent>
                                    }
                                    <FlexComponent justifyContent="center" gap="1rem" >
                                        <Button type='button' onClick={() => {
                                            handleEditSubmit(list.id)
                                        }} disabled={!editTitle.trim()} >Update</Button>
                                        <Button style={{ background: "white", color: " black" }} type='button' onClick={() => {
                                            setIsCustomFieldEdit(false)
                                        }} >Discard Changes</Button>
                                    </FlexComponent>
                                </FlexComponent>
                            }
                        </div>
                    ))}
                </div> }

                {loading && <FlexComponent justifyContent="center" >
                    <ProgressSpinner />
                </FlexComponent>}
            <Button style={{ marginTop: ".5rem" }} onClick={handleClick} >Add Custom Field</Button>
            {isAddCustomField && <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer customFieldDiv' flexDirection="column" gap='.5rem' alignItems='center'>

                <div className='inputContainerDiv ' style={{ width: '100%' }}>
                    <label htmlFor="title" style={{ fontWeight: 'bold' }}>Title *</label>
                    <InputText size="small" style={{ width: '100%', marginTop: ".5rem" }} id="title" value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                </div>
                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <label htmlFor="type" style={{ fontWeight: 'bold' }}>Type *</label>
                    <Dropdown
                        value={selectedType}
                        options={customFieldOptions}
                        style={{ width: '100%', color: 'black', marginTop: ".5rem" }}
                        onChange={(event) => {
                            setSelectedType(event.value)
                        }}
                        placeholder='Select Type'
                    />
                </div>

                {selectedType === "single_select" &&
                    <FlexComponent flexDirection="column" alignItems="start" >
                        <label htmlFor="Options" style={{ fontWeight: 'bold' }}>Options</label>
                        {dropdownOptions?.map((option) => (
                            <FlexComponent style={{ width: "70%", padding: ".2rem .4rem" }} >
                                <p>{option}</p>
                                <Button onClick={() => {
                                    const updatedItems = dropdownOptions.filter((list) => list !== option)
                                    setDropdownOptions(updatedItems)
                                }} style={{ padding: ".3rem" }} ><i className='pi pi-trash'></i></Button>
                            </FlexComponent>
                        ))}
                        <InputText size="small" style={{ width: '100%', marginBottom: ".3rem" }} id="title" value={optionName}
                            onChange={(e) => {
                                setOptionName(e.target.value)
                            }}
                        />
                        <Button disabled={!optionName.trim()} onClick={() => {
                            setDropdownOptions((prev) => [...prev, optionName])
                            setOptionName('')
                        }} style={{ padding: ".3rem" }} ><i className='pi pi-plus'></i>Add Option</Button>
                    </FlexComponent>
                }

                <FlexComponent justifyContent="center" gap="1rem" >
                    <Button type='button' onClick={handleAddCustomField} disabled={!title.trim() || selectedType.length === 0} >Save</Button>
                    <Button style={{ background: "white", color: "black" }} type='button' onClick={() => {
                        setIsAddCustomField(false)
                    }} >Cancel</Button>
                </FlexComponent>
            </FlexComponent>}

        </div>
    )
}

export default CustomFields