import { useEffect, useState } from 'react'
import { deleteCompany, getCompanies, upsertCompany } from '../../utils/urls'
import axios from '../../utils/axios';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../FlexComponent';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

function Companies({ toast }) {
    const [title, setTitle] = useState('')
    const [editTitle, setEditTitle] = useState('')

    const [isAddCompany, setIsAddCompany] = useState(false)
    const [companiesList, setCompaniesList] = useState([])
    const [companyEditIndex, setCompanyEditIndex] = useState()
    const [isCompanyEdit, setIsCompanyEdit] = useState()

    const [loading, setLoading] = useState(true)
    const [isMounted, setIsMounted] = useState(false)

    const handleAddCompany = () => {
        setIsMounted(true)
        setIsAddCompany(false)
        const data = {
            title: title,
        }
        axios.post(upsertCompany, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setTitle('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleEditSubmit = (id) => {
        setIsCompanyEdit(false)
        setIsMounted(true)
        const data = {
            company_id: id,
            title: editTitle,
        }
        console.log(data);
        const url = `${upsertCompany}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setTitle('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleClick = () => {
        setTitle('')
        setIsAddCompany(!isAddCompany)
        setIsCompanyEdit(false);
    }

    const handleEdit = (index) => {
        setIsAddCompany(false)
        setIsCompanyEdit(true);
        setCompanyEditIndex(index);
        setEditTitle(companiesList[index].title);
    };

    const getAllCompanies = () => {
        const url = `${getCompanies}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setCompaniesList(response.data)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }


    const handleDelete = (list) => {
        setIsMounted(true)
        confirmDialog({
            message: 'Are you sure you want to delete this Company?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const Url = `${deleteCompany}${list.id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMounted(false)
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            // handleClose()
                            setIsMounted(false)
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // handleClose()
                        setIsMounted(false)
                    })

            },
            reject: () => {
                console.log("closed")
            }
        });

    };

    useEffect(() => {
        getAllCompanies()
    }, [isMounted])

    return (
        <div>
            <ConfirmDialog />
            {companiesList?.length > 0 &&
                <div>
                    {companiesList.map((list, index) => (
                        <div key={index} style={{ marginTop: ".5rem" }} >
                            {!isCompanyEdit || companyEditIndex !== index ? (<FlexComponent gap="1rem" >
                                <FlexComponent justifyContent="start" style={{ height: "50px", width: "100%" }} >
                                    <p  >{list.title}</p>
                                </FlexComponent>
                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => {
                                    handleEdit(index)
                                }} > <i className='pi pi-pencil' ></i></Button>
                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => {
                                    handleDelete(list)
                                }} > <i className='pi pi-trash' ></i></Button>
                            </FlexComponent>) :
                                <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>

                                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                                        <label htmlFor="email" style={{ fontWeight: 'bold' }}>Title *</label>
                                        <InputText size="small" style={{ width: '100%' }} id="title" value={editTitle}
                                            onChange={(e) => {
                                                setEditTitle(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <FlexComponent justifyContent="center" gap="1rem" >
                                        <Button type='button' onClick={() => {
                                            handleEditSubmit(list.id)
                                        }} disabled={!editTitle.trim()} >Update</Button>
                                        <Button style={{ background: "white", color: " black" }} type='button' onClick={() => {
                                            setIsCompanyEdit(false)
                                        }} >Discard Changes</Button>
                                    </FlexComponent>
                                </FlexComponent>
                            }
                        </div>
                    ))}
                </div>}

            {loading && <FlexComponent justifyContent="center" >
                <ProgressSpinner />
            </FlexComponent>}

            <Button style={{ marginTop: ".5rem" }} onClick={handleClick} >Add Company</Button>
            {isAddCompany && <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>

                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Title *</label>
                    <InputText size="small" style={{ width: '100%' }} id="title" value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                </div>

                <FlexComponent justifyContent="center" gap="1rem" >
                    <Button type='button' onClick={handleAddCompany} disabled={!title.trim()} >Save</Button>
                    <Button style={{ background: "white", color: "black" }} type='button' onClick={() => {
                        setIsAddCompany(false)
                    }} >Cancel</Button>
                </FlexComponent>
            </FlexComponent>}

        </div>
    )
}

export default Companies