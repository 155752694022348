import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import './form.css'
import { Country, State, City } from 'country-state-city';
import { Divider } from 'primereact/divider'

function WhatTypeOfHome({ values, formName = "", errors, touched, handleBlur, handleChange, setFieldValue }) {
    const [areaIndex, setAreaIndex] = useState()
    const [areaCity, setAreaCity] = useState()
    const [areaState, setAreaState] = useState()
    const handleSelectAll = () => {
        const allOptions = ["1 Family", "2 Family", "3+ / Multi-Family", "Condo", "Co-op"];
        setFieldValue('home_type', allOptions);
    };

    const handleDeselectAll = () => {
        setFieldValue('home_type', []);
    };

    const handleAddClick = () => {
        // setLoading(true)
        let prior = false
        if (values.specific_area_interested_in?.length === 0) {
            prior = true
        }
        values.specific_area_interested_in.push({
            state: State.getStateByCodeAndCountry(areaState, 'US')?.name,
            city: areaCity,
            priority: prior
        });

        setAreaIndex(values.specific_area_interested_in.length - 1)
        setAreaState('')
        setAreaCity('')
    };

    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='homeTypeId'>
            <div style={{ background: '#08113A' }}>
                {(values.file_status === "Accepted Offer" || values.file_status === "In Contract") ? <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>What is the Subject property address?</p>
                    : <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>What type of home are you looking for?</p>
                }

            </div>
            {(formName === "New Loan Set Up App" && (values.file_status === "Accepted Offer" || values.file_status === "In Contract")) && (
                    <div className='inputContainerDiv' style={{ width: '100%', margin: "1.5rem 0" }}>
                        <label htmlFor="subject_property_address" style={{ fontWeight: 'bold' }}>Subject Property Address</label>
                        <Field rows={4} type="text" id="subject_property_address" name="subject_property_address" as={InputTextarea} style={{ width: '100%' }} />
                    </div>
                )}
            <div style={{ textAlign: 'start'  }}>
                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                    <div className='inputContainerDiv' style={{ width: '35%', margin: '0.5rem' }}>
                        <label htmlFor="home_type" style={{ fontWeight: 'bold' }}>Home type?</label>
                        <div>
                            {["1 Family", "2 Family", "3+ / Multi-Family", "Condo", "Co-op"].map((option) => (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field
                                        type="checkbox"
                                        id={option}
                                        name="home_type"
                                        value={option}
                                        as={Checkbox}
                                        checked={values.home_type.includes(option)}
                                        onChange={(event) => {
                                            const updatedValues = [...values.home_type];
                                            if (event.target.checked) {
                                                updatedValues.push(option);
                                            } else {
                                                const index = updatedValues.indexOf(option);
                                                updatedValues.splice(index, 1);
                                            }
                                            handleChange({ target: { name: 'home_type', value: updatedValues } });
                                        }}
                                    />
                                    <label htmlFor={option} className="ml-2">{option}</label>
                                </FlexComponent>
                            ))}
                            {values.home_type.length === 5 ? <Button type='button' onClick={handleDeselectAll} style={{ padding: '5px', margin: '10px' }}>Deselect all</Button> :
                                <Button type='button' onClick={handleSelectAll} style={{ padding: '5px', margin: '10px' }}>Select all</Button>}
                        </div>
                    </div>


                    <div className='inputContainerDiv' style={{ width: '25%', margin: '0.5rem' }}>
                        <label htmlFor="bedrooms" style={{ fontWeight: 'bold' }}>Bedrooms?</label> <br />
                        {["1", "2", "3", "4", "5+"].map((option) => {
                            return (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field type="radio" id={option} name="bedrooms" value={option} as={RadioButton} />
                                    <label htmlFor={option} className="ml-2">{option}</label>
                                </FlexComponent >
                            );
                        })}
                    </div>

                    <div className='inputContainerDiv' style={{ width: '25%', margin: '0.5rem' }}>
                        <label htmlFor="bathrooms" style={{ fontWeight: 'bold' }}>Bathrooms?</label> <br />
                        {["1", "2", "3", "4", "5"].map((option) => {
                            return (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field type="radio" id={option} name="bathrooms" value={option} as={RadioButton} />
                                    <label htmlFor={option} className="ml-2">{option}</label>
                                </FlexComponent >
                            );
                        })}
                    </div>

                    <div className='inputContainerDiv' style={{ width: '40%', margin: '0.5rem' }}>
                        <label htmlFor="do_you_have_price_range_in_mind" style={{ fontWeight: 'bold' }}>Do you have a price range in mind?</label>
                        <Field type="number" id="do_you_have_price_range_in_mind" name="do_you_have_price_range_in_mind" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                    </div>

                </FlexComponent>

                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                    <div className='inputContainerDiv' style={{ width: '70%', margin: '0.5rem' }}>
                        <label htmlFor="why_are_you_looking_to_buy_this_time" style={{ fontWeight: 'bold' }}>Why are you looking to buy at this time?</label>
                        <Field rows={4} type="text" id="why_are_you_looking_to_buy_this_time" name="why_are_you_looking_to_buy_this_time" as={InputTextarea} style={{ width: '100%' }}
                            placeholder="Get the full picture on why they are looking to buy a house at this time." />
                        {/* <ErrorMessage name="why_are_you_looking_to_buy_this_time" component="div" className="error" style={{color:'red'}} /> */}
                    </div>

                    <div className='inputContainerDiv' style={{ width: '30%', margin: '0.5rem' }}>
                        <label htmlFor="purchase_type" style={{ fontWeight: 'bold' }}>Purchase Type</label> <br />
                        {["Home to live in", "Investment Property"].map((option) => {
                            return (
                                <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                    <Field type="radio" id={option} name="purchase_type" value={option} as={RadioButton} />
                                    <label htmlFor={option} className="ml-2">{option}</label>
                                </FlexComponent >
                            );
                        })}
                    </div>

                </FlexComponent>

                <Divider />
                <FlexComponent justifyContent='center'>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', textAlign: 'start', margin: '0.5rem' }}>
                            <label htmlFor="specific_area_interested_in" >
                                <p style={{ fontWeight: 'bold' }}>Where are you looking?</p><span>(include as many areas of interest as they would like or just choose the state if they dont know what city)</span>
                            </label>
                        </div>
                        {/* <FlexComponent></FlexComponent> */}

                        {/* <FieldArray name="specific_area_interested_in">
                            {(arrayHelpers) => ( */}
                        <FlexComponent className='inputContainer' gap='1rem' alignItems='start' justifyContent='start'>
                            <div className='inputContainerDiv' style={{ width: '45%', margin: '0.5rem 0.5rem' }}>
                                <Field
                                    name={`areaState`}
                                >{({ field }) => (
                                    <Dropdown
                                        id={field.name}
                                        value={areaState}
                                        options={State.getStatesOfCountry('US')?.map((state) => ({
                                            label: state.name,
                                            value: state.isoCode,
                                        })) || []}
                                        style={{ width: '100%' }}
                                        onChange={(event) => {
                                            setAreaState(event.value)
                                            setAreaCity('')
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Select State"
                                    />
                                )}</Field>
                                {/* <label htmlFor="state" style={{ color: Boolean(error?.state) ? 'red' : '' }}>State</label> */}
                            </div>
                            <div className='inputContainerDiv' style={{ width: '45%', margin: '0.5rem 0.5rem' }}>
                                {/* <Field type="text" id="city" name={`specific_area_interested_in[${areaIndex}].city`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} /> */}
                                {City.getCitiesOfState('US', areaState).length > 0 ? <Field
                                    name={`areaCity`}
                                >{({ field }) => (
                                    <Dropdown
                                        id={field.name}
                                        value={areaCity}
                                        options={City.getCitiesOfState('US', areaState)?.map((state) => ({
                                            label: state.name,
                                            value: state.name,
                                        })) || []}
                                        style={{ width: '100%' }}
                                        onChange={(event) => {
                                            setAreaCity(event.value)
                                        }}
                                        onBlur={handleBlur}
                                        placeholder="Select City"
                                    />
                                )}</Field> : <Field placeholder='Enter City' type="text" id="areaCity" name="areaCity" value={areaCity} onChange={(event) => {
                                    setAreaCity(event.target.value)
                                }} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />}
                                {/* <label htmlFor="city" style={{ color: Boolean(error?.city) ? 'red' : '' }}>City</label> */}
                            </div>
                            <div style={{ textAlign: 'center', margin: '0.5rem 0.5rem' }}>
                                <Button disabled={areaState ? false : true} type='button' onClick={handleAddClick}>Add</Button>
                            </div>
                        </FlexComponent>
                        {/* )}
                         </FieldArray> */}

                        {values.specific_area_interested_in?.length > 1 && <div className='inputContainerDiv' style={{ width: '60%', textAlign: 'start', margin: '0.5rem' }}>
                            <label htmlFor="specific_area_interested_in" >
                                <p >Please Select one area as a priority</p>
                            </label>
                        </div>}

                        <div className='inputContainerDiv' style={{ width: '50%', background: 'white', padding: values?.specific_area_interested_in.length > 0 ? '0.5rem' : '', borderRadius: '10px', margin: '0.5rem' }}>
                            <FieldArray name="specific_area_interested_in">
                                {(arrayHelpers) => (
                                    <>
                                        {values?.specific_area_interested_in?.map((area, index) => {
                                            return (
                                                <FlexComponent justifyContent='start' gap='0.75rem' key={index} style={{ margin: '1rem' }}>
                                                    {/* <Field type="radio" id={area.state} name={`specific_area_interested_in[${index}].priority`} value={area.state} as={RadioButton} />
                                                    <label htmlFor={area.state} className="ml-2"><span>{area.state}, {area.city} </span></label> */}
                                                    <FlexComponent justifyContent='start' gap='0.75rem' key={index} style={{ marginTop: '0.5rem' }}>
                                                        {values.specific_area_interested_in?.length > 1 && <Field
                                                            type="checkbox"
                                                            id={area.priority}
                                                            name="home_type"
                                                            value={area.priority}
                                                            as={Checkbox}
                                                            checked={area.priority}
                                                            onChange={(event) => {
                                                                values.specific_area_interested_in?.map((option, ind) => {
                                                                    if (ind == index) {
                                                                        handleChange({ target: { name: `specific_area_interested_in[${ind}].priority`, value: true } });
                                                                    } else {
                                                                        handleChange({ target: { name: `specific_area_interested_in[${ind}].priority`, value: false } });
                                                                    }
                                                                })
                                                            }}
                                                        />}
                                                        <label htmlFor={area.priority} className="ml-2">{area.city}{area.city && ','} {area.state} </label>
                                                    </FlexComponent>
                                                    <Button type='button' style={{ margin: '0 1rem', padding: '.5rem' }} onClick={() => {
                                                        arrayHelpers.remove(index);
                                                        if (values.specific_area_interested_in.length != 1 && values.specific_area_interested_in[index].priority) {
                                                            handleChange({ target: { name: `specific_area_interested_in[${0}].priority`, value: true } });
                                                        }
                                                    }}><i className="pi pi-trash" style={{ fontSize: '1rem' }}></i></Button>
                                                </FlexComponent >
                                            );
                                        })}

                                    </>
                                )}
                            </FieldArray>
                        </div>

                    </div>
                </FlexComponent>
                <Divider />

                {/* <div style={{ width: '60%', textAlign: 'start', margin: '0.5rem' }}>
                        <label htmlFor="specific_area_interested_in" >
                            <p style={{ fontWeight: 'bold' }}>Any Other Areas</p>
                        </label>
                        <Field rows={4} type="text" id="specific_area_interested_in" name="specific_area_interested_in" as={InputTextarea} style={{ width: '100%' }} />
                    </div> */}

                <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                    <label htmlFor="notes_about_home_search" >
                        <p style={{ fontWeight: 'bold' }}>Notes about home search</p>
                    </label>
                    <Field rows={4} type="text" id="notes_about_home_search" name="notes_about_home_search" as={InputTextarea} style={{ width: '100%' }} />
                </div>

                <div className='inputContainerDiv' style={{ width: '100%', margin: '0.5rem' }}>
                    <label htmlFor="preapproved_already" style={{ fontWeight: 'bold' }}>Preapproved already?</label> <br />
                    {["Yes", "No"].map((option) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                <Field type="radio" id={option} name="preapproved_already" value={option} as={RadioButton} />
                                <label htmlFor={option} className="ml-2">{option}</label>
                            </FlexComponent >
                        );
                    })}
                </div>
                {values.preapproved_already === 'Yes' && (
                    <div className='inputContainerDiv' style={{ width: '100%', margin: '0.5rem' }}>
                        <label htmlFor="preapproval_details" style={{ fontWeight: 'bold' }}>Preapproval Details</label>
                        <Field rows={4} type="text" id="preapproval_details" name="preapproval_details" as={InputTextarea} style={{ width: '100%' }} />
                    </div>
                )}
                


                {/* <div className='inputContainerDiv' style={{ width: '100%', margin: '0.5rem' }} id='cosignerQuestionId'>
                    <label htmlFor="do_you_have_a_cosigner" style={{ fontWeight: 'bold', color: Boolean(touched.do_you_have_a_cosigner && errors.do_you_have_a_cosigner) ? 'red' : '' }}>Do you have a Cosigner? *</label> <br />
                    {["Yes", "No"].map((option) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                <Field type="radio" id={option} name="do_you_have_a_cosigner" value={option} as={RadioButton} />
                                <label htmlFor={option} className="ml-2">{option}</label>
                            </FlexComponent >
                        );
                    })}
                    <p>Anyone else who will help you by going on the loan with you? (Parents / Family / Friend / Partner)</p>
                </div> */}

            </div>
        </div>
    )
}

export default WhatTypeOfHome