import { useEffect, useState } from 'react'
import { appSubmission, getCustomFields, upserCustomFieldValue } from '../../../utils/urls'
import axios from '../../../utils/axios';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../../FlexComponent';
import { Calendar } from 'primereact/calendar';
import './apps.css'
import { useSelector } from 'react-redux';

function CustomField({ toast, imagesList, fullName, formId, applicantData, setBoardSections, pipelineId, customFields, allCustomFields }) {
  const [loading, setLoading] = useState(false)
  const [fieldsData, setFieldsData] = useState({})
  const [isFocused, setIsFocused] = useState(false);
  const [isEditText, setIsEditText] = useState({})
  const [isEditContractExpiry, setIsEditContractExpiry] = useState(false)
  const userDetail = useSelector(state=> state.user)
  const [customFieldsData, setCustomFieldsData] = useState(customFields)

  // console.log(allCustomFields)
  // console.log(customFields)
  // console.log(imagesList)


  const getApplicantData = ()=>{
    const url = `${appSubmission}${formId}?pipelineId=${pipelineId}`
    axios.get(url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.customFields)
          const data = response.data
          setCustomFieldsData(data.customFields)

          allCustomFields?.map((field) => {
            setFieldsData((prev) => ({
              ...prev,
              [field.title]: data.customFields.find((item) => item.custom_field_id === field.id)?.value
            }))
          
            if(field.type === "text"){
              setIsEditText((prev)=> ({
                ...prev,
                [field.title] : false
              }));
            }
          })
          // setPopupLoad(false)
        }
      })
      .catch((error) => {
        // setAppLeads(null)
      })
  }

  useEffect(() => {
    allCustomFields?.map((field) => {
      setFieldsData((prev) => ({
        ...prev,
        [field.title]: customFieldsData.find((item) => item.custom_field_id === field.id)?.value
      }))
    
      if(field.type === "text"){
        setIsEditText((prev)=> ({
          ...prev,
          [field.title] : false
        }));
      }
    })

    imagesList?.map((user) => {
      // console.log(user)
      if(user.roles.includes("contact_owner") ){
        if(user.id === userDetail?.ghlUserId){
          setIsEditContractExpiry(true)
        }
      }
    })

    if(userDetail.isSuperuser){
      setIsEditContractExpiry(true)
    }
    
  }, [])

  // console.log(fieldsData)

  const updateCustomField = (title, customFieldId, customValue) => {
    setLoading(true)
    const data = {
      form_id: formId,
      custom_field_id: allCustomFields.find((field) => field.id === customFieldId)?.id,
      value: customValue
    }
    console.log(data)
    axios.post(upserCustomFieldValue, data, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.status === 200) {
          // toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
          // getAllCustomFields()
          
          if(title == "Contract Expiration Date"){
            setBoardSections(prevSections => {
              const updatedSections = { ...prevSections };
              for (const sectionId in updatedSections) {
                  updatedSections[sectionId] = updatedSections[sectionId].map(item => {
                      if (item.form_id === applicantData.form_id) {
                          return {
                              ...item,
                              contract_due_date: customValue
                          };
                      }
                      return item;
                  });
              }
              return updatedSections;
          });
          }
          getApplicantData()
          setLoading(false)
        }
      })
      .catch((error) => {
        // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        setLoading(false)
      })
  }

  const convertDateToString = (date) => {
    if (!date) return '';
    const year = date?.getFullYear();
    const month = String(date.getMonth() + 1)?.padStart(2, '0'); // month is 0-based in Date
    const day = String(date.getDate())?.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const convertDateStringToDate = (dateString) => {
    try {
      if (!dateString) return null;
      const [year, month, day] = dateString?.split('-');
      return new Date(year, month - 1, day); // month is 0-based in Date
    } catch (error) {
      return null
    }
  };
  function highlightText(text) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text?.split(urlRegex);

    return (
      <div>
        {parts?.map((part, index) => {
          if (urlRegex.test(part)) {
            return (
              <a key={index} href={part} style={{ color: '#00FF2F' }} target="_blank" rel="noopener noreferrer">
                {fullName}
              </a>
            );
          } else {
            return part;
          }
        })}
      </div>
    );
  };

console.log("isEditContractExpiry", isEditContractExpiry)

  return (
    <div className='customFieldDiv' >
      <div style={{ paddingBottom: ".6rem" }}><i className='pi pi-microsoft' style={{ marginRight: "5px" }} ></i> Custom Fields</div>
      <FlexComponent style={{marginLeft: "20px"}} gap="1rem" flexWrap="wrap" justifyContent="start" >
        {allCustomFields?.map((customField, index) => (
          <div key={index} className='applicantDivs' style={{ width: "30%" }} >
            {customField.field_type === "single_select" &&
              <div style={{ width: "100%" }}>
                <div style={{ paddingBottom: ".6rem" }} >{customField.title}</div>
                <Dropdown
                  id="designationId"
                  value={fieldsData[customField.title]}
                  options={customField.options.map(designation => ({
                    label: designation,
                    value: designation,
                  })) || []}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setFieldsData((prev) => ({
                      ...prev,
                      [customField.title]: e.value
                    }))
                    updateCustomField(customField.title, customField.id, e.value)
                  }}
                  placeholder="Select"
                />
              </div>
            }
            {customField.field_type === "text" &&
              <div style={{ width: "100%" }} >
                <FlexComponent style={{paddingBottom: ".6rem"}} justifyContent="start" gap="1rem" >
                  <div  >{customField.title}</div>
                  {!isEditText[customField.title] ? <i style={{ cursor: "pointer" }} onClick={() => {
                    // setIsEditText(!isEditText)
                    setIsEditText(prev=> (
                      {...prev,
                        [customField.title] : !prev[customField.title]
                      }
                    ))
                  }} className='pi pi-pencil'></i> :
                    <i style={{ cursor: "pointer" }} onClick={() => {
                      // setIsEditText(!isEditText)
                      setIsEditText(prev=> (
                        {...prev,
                          [customField.title] : !prev[customField.title]
                        }
                      ))
                    }} className='pi pi-save'></i>
                  }
                </FlexComponent>
                {isEditText[customField.title] ? <InputText size="small" id="textField" value={fieldsData[customField.title]}
                  style={{
                    width: '100%',
                    // background: isFocused ? "white" : "#20385E"
                  }}
                  onChange={(e) => {
                    setFieldsData((prev) => ({
                      ...prev,
                      [customField.title]: e.target.value
                    }))
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => {
                    setIsFocused(false)
                    updateCustomField(customField.title, customField.id, e.target.value)
                  }}
                  placeholder=''
                /> :
                  <div style={{border: "1px solid white",  padding: ".5rem", minHeight:"40px", borderRadius : "5px"}}>{highlightText(fieldsData[customField.title])}</div>
                }
              </div>
            }

            {customField.field_type === "number" &&
              <div  style={{ width: "100%" }} >
                <div  style={{ paddingBottom: ".6rem" }}>{customField.title}</div>
                <InputText size="small" id="numberField" value={fieldsData[customField.title]}
                  style={{
                    width: '100%',
                    // background: isFocused ? "white" : "#20385E"
                  }}
                  type='number'
                  onChange={(e) => {
                    setFieldsData((prev) => ({
                      ...prev,
                      [customField.title]: e.target.value
                    }))
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => {
                    // console.log(e)
                    setIsFocused(false)
                    updateCustomField(customField.title, customField.id, e.target.value)
                  }}
                  placeholder=''
                />
              </div>
            }

            {customField.field_type === "datetime" &&
              <div style={{ width: "100%" }} >
                <div style={{ paddingBottom: ".6rem" }}>{customField.title}</div>
                {customField.title === "Contract Expiration Date" && !isEditContractExpiry ?
                  <div style={{border: "1px solid white",  padding: ".5rem", minHeight:"40px", borderRadius : "5px"}}>{fieldsData[customField.title]}</div>
                  :
                <Calendar
                  style={{
                    width: '100%',
                    // background: isFocused ? "white" : "#20385E"
                  }}
                  value={convertDateStringToDate(fieldsData[customField.title]) || fieldsData[customField.title]}
                  onChange={(e) => {
                    setFieldsData((prev) => ({
                      ...prev,
                      [customField.title]: e.value
                    }))
                    updateCustomField(customField.title, customField.id, convertDateToString(e.value))
                  }} />}
              </div>
            }
          </div>
        ))}
      </FlexComponent>
    </div>
  )
}

export default CustomField