import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ToggleButton } from 'primereact/togglebutton';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";  
import 'primeicons/primeicons.css';
import { locationList, syncLocations, lockLocationUrl } from '../../utils/urls';   
import axios from '../../utils/axios';       
import './locationTable.css'
import { useNavigate } from 'react-router-dom';

export default function LocationTable() {
    const navigate = useNavigate()
    const toast = useRef(null);
    const [customers, setCustomers] = useState(null);
    const [locationFilters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        location_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        user_count: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)

    useEffect(()=>{
        // setLoading(true);
        axios.get(locationList, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCustomers(response.data);
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    },[locationList, isMounted])

    const userView = (rowData) => {
        const location_id = rowData.location_id
        return <Button className='view-button' label="View" icon="pi pi-external-link" onClick={()=>redirectUserPage(location_id)} />
    };

    const redirectUserPage = (location_id) =>{
        navigate(`/users/${location_id}`)
    }


    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...locationFilters };

        _filters['global'].value = value;
        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = locationFilters['global'] ? locationFilters['global'].value : '';

        return (
            <div className="flex justify-content-between table-header-container">
                 {/* <div className='sync-button-container'>
                    <Button className='sync-button' label="Sync" icon="pi pi-sync" onClick={syncHandle} />
                </div> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
                </span>
            </div>
        );
    };

    const syncHandle = () =>{
        setLoading(true);
        axios.get(syncLocations, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    setCustomers(response.data);
                    setIsMounted(!isMounted)
                    setLoading(false);
                    // initFilters();
                  }
                })
            .catch((error)=>{
                setCustomers(null)
                
            })
    }

    const accept = (lockData) => {
        axios.post(lockLocationUrl, lockData,{
        headers: {'Content-Type': 'multipart/form-data'},
        })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            })
        .catch((error)=>{

            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            
        })
    }

    const reject = () => {
        console.log('closed')
    }

    const lockTemplate = (rowData) => {
        const status = rowData.is_paused
        const location_id = rowData.location_id

        return <ToggleButton onLabel="OFF" offLabel="ON" checked={status} onChange={(e) => handlePauseUser(location_id, !status)} className="w-8rem" />;
    };

    const handlePauseUser = (location_id, status) => {

        const lockData = {
            location_id,
            "status": status
        }

        let message;
        if (status===true){
            message = 'Are you sure you want to pause this team?'
        }
        else{
            message = 'Are you sure you want to make this team active?'
        }
        confirmDialog({
            message: message,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(lockData),
            reject
        });
        
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

    const header = renderHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable value={customers} paginator rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={header} filters={locationFilters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                     dataKey="id"
                    stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No teams found." tableStyle={{ minWidth: '50rem' }}>
                <Column header="SR NO" className="padding-0" style={{ width: '2rem'  }} body={srNoBodyTemplate} />
                <Column field="location_name" header="Team" sortable style={{ width: '30%' }}></Column>
                <Column field="user_count" header="participants" sortable style={{ width: '30%' }}></Column>
                <Column body={userView} style={{ width: '30%' }}></Column>
                <Column body={lockTemplate} style={{ width: '2rem'  }}></Column>
            </DataTable>
        </div>
    );
}