import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { UpdateCustomAppLead } from '../../utils/urls';
import axios from '../../utils/axios'
import { InputTextarea } from 'primereact/inputtextarea';

function SavingsAndAssets({data, toast, formId, userId, setIsSubmit}) {

    const [isEditOpen, setIsEditOpen] = useState(false);

    const [formData, setFormData] = useState({
        saved: data?.unformatted_saved || '',
        additional_savings: data?.additional_savings || '',
        any_cash_in_hand: data?.any_cash_in_hand || '',
        savings_notes: data?.savings_notes || '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleEditClick = () => {
        setIsEditOpen(true);
    };

    const handleEditSave = async () => {
        try {
            const updatedData = {
                ...formData,
                type : "Savings & Assets",
                userId : userId
            };
            console.log(updatedData);
            setIsEditOpen(false);
            console.log('Submit ')
            const url = `${UpdateCustomAppLead}${formId}`
            const response = await axios.post(url, updatedData, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                setIsSubmit(true)
                // onSubmitProps.resetForm();
            }else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                setIsSubmit(true)
            }
        } catch (error) {  
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                setIsSubmit(true)
        }
    };

    const handleEditCancel = () => {
        setIsEditOpen(false);
    };

    return (
        <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', background: 'white', padding: '2rem' }}>
            <FlexComponent style={{ width: '100%', textAlign: 'start' }}>
                <p style={{ fontWeight: '600' }}>Savings & Assets</p>
                <Button label="Edit" style={{ borderRadius: '25px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleEditClick} />
            </FlexComponent>
            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Total Amount Saved</p>
                <p>{data?.saved || 'N/A'}</p>
            </div>

            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Any additional savings</p>
                <p>{data?.additional_savings || 'N/A'}</p>
            </div>

            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Any Cash on hand</p>
                <p>{data?.any_cash_in_hand || 'N/A'}</p>
            </div>

            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Notes about savings</p>
                <p>{data?.savings_notes || 'N/A'}</p>
            </div>

            <Dialog className='editForm' header="Edit Savings & Assets" visible={isEditOpen} onHide={() => handleEditCancel()}>
                <div >
                    <form onSubmit={handleEditSave} >
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="saved">Total Amount Saved</label> <br />
                                <InputText type='number' style={{ width: '100%' }} id="saved" defaultValue={data?.unformatted_saved} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="additional_savings">Any additional savings</label> <br />
                                <InputText style={{ width: '100%' }} id="additional_savings" defaultValue={data?.additional_savings} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="any_cash_in_hand">Any Cash on hand</label> <br />
                                <InputText style={{ width: '100%' }} id="any_cash_in_hand" defaultValue={data?.any_cash_in_hand} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="savings_notes">Notes about savings</label> <br />
                                {/* <InputText style={{ width: '100%' }} id="savings_notes" defaultValue={data?.savings_notes} onChange={handleInputChange} /> */}
                                <InputTextarea rows={4} type="text" id="savings_notes" name="savings_notes" style={{ width: '100%' }}
                                value={formData.savings_notes} onChange={handleInputChange}
                                    placeholder="" />
                            </div>
                        <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem' }}>
                            <Button type='button' label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                            <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                        </FlexComponent>
                    </form>
                </div>
            </Dialog>

        </FlexComponent>
    )
}

export default SavingsAndAssets