import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import './form.css'
import { InputMask } from 'primereact/inputmask'

function ContactInformation({ values, errors, touched, handleBlur, handleChange }) {


    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='contactId'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>Lets confirm your contact information</p>
            </div>

            <div style={{ textAlign: 'start', marginTop: "1.5rem" }}>
                <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                    <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                        <label htmlFor="cell_phone_number" style={{ fontWeight: 'bold', color: Boolean(touched.cell_phone_number && errors.cell_phone_number) ? 'red' : '' }}>Cell Phone Number *</label>
                        <div className="p-inputgroup flex-1 login-input-div">
                            <span className="p-inputgroup-addon" style={{ background: 'white' }}>
                                +1
                            </span>
                            <Field mask="(999) 999-9999" type="text" id="cell_phone_number" name="cell_phone_number" as={InputMask} style={{ width: '100%' }} onBlur={handleBlur} />
                        </div>
                        {/* <ErrorMessage name="cell_phone_number" component="div" className="error" style={{color:'red'}} /> */}
                    </div>
                    <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                        <label htmlFor="email" style={{ fontWeight: 'bold', color: Boolean(touched.email && errors.email) ? 'red' : '' }}>Email *</label>
                        <Field type="text" id="email" name="email" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                        {/* <ErrorMessage name="email" component="div" className="error" style={{color:'red'}}/> */}
                    </div>

                </FlexComponent>

                <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                    <label htmlFor="best_days_and_times_to_reach" >
                        <p style={{ fontWeight: 'bold' }}>Best days & times to reach</p>
                    </label>
                    <Field rows={4} type="text" id="best_days_and_times_to_reach" name="best_days_and_times_to_reach" as={InputTextarea} style={{ width: '100%' }} />
                    <label htmlFor="best_days_and_times_to_reach">Be sure to gather any relevant details about the house, is there a mortgage on it? What is the address?</label>
                </div>

            </div>

        </div>
    )
}

export default ContactInformation