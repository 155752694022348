import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function TypeOfHome({ data }) {

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [editData, setEditData] = useState()

    const [formData, setFormData] = useState({
        home_type: data?.home_type || '',
        bedrooms: data?.bedrooms || '',
        bathrooms: data?.bathrooms || '',
        price_range: data?.price_range || '',
        purchase_type: data?.purchase_type || '',
        notes_about_home_search: data?.notes || '',
        priority_state: data?.priority_state || '',
        priority_city: data?.priority_city || '',
        why_are_you_looking_to_buy_at_this_time: data?.why_are_you_looking_to_buy_at_this_time || ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleEditClick = () => {
        setIsEditOpen(true);
    };

    const handleEditSave = () => {
        console.log(formData)
        setIsEditOpen(false);
    };
    const handleEditCancel = () => {
        setIsEditOpen(false);
    };


    return (
        <FlexComponent flexDirection='column' style={{ borderRadius: '1rem', gap: '1.25rem', textAlign: 'center', background: 'white', padding: '2rem' }}>
            <FlexComponent style={{ width: '100%', textAlign: 'start' }}>
                <p style={{ fontWeight: '600' }}>What type of home are you looking for?</p>
                {/* <Button label="Edit" style={{ borderRadius: '25px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleEditClick} /> */}
            </FlexComponent>
            <FlexComponent >
                <div style={{ textAlign: 'start', width: '60%' }}>
                    <p style={{ color: '#8C8C8C' }}>Home Type</p>
                    <p>{data?.home_type || 'N/A'}</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Bedrooms</p>
                    <p>{data?.bedrooms || 'N/A'}</p>
                </div>
                <div style={{ textAlign: 'start', width: '40%' }}>
                    <p style={{ color: '#8C8C8C' }}>Bathrooms</p>
                    <p>{data?.bathrooms || 'N/A'}</p>
                </div>
            </FlexComponent>

            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Do you have a price range in mind?</p>
                <p>{data?.price_range || 'N/A'}</p>
            </div>

            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Purchase Type</p>
                <p>{data?.purchase_type || 'N/A'}</p>
            </div>
            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>How soon are you available to go see houses?</p>
                <p>{data?.available || 'N/A'}</p>
            </div>
            <div style={{ textAlign: 'start', width: '100%' }}>
                <p style={{ color: '#8C8C8C' }}>Notes about home search</p>
                <p>{data?.notes || 'N/A'}</p>
            </div>
            <Dialog className='editForm' header="Edit Personal Information" visible={isEditOpen} onHide={() => handleEditCancel()}>
                <div >
                    <form onSubmit={handleEditSave} >
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="home_type">Home Type</label> <br />
                                <InputText style={{ width: '100%' }} id="home_type" defaultValue={data?.home_type} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="bedrooms">Bedrooms</label> <br />
                                <InputText style={{ width: '100%' }} id="bedrooms" defaultValue={data?.bedrooms} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="bathrooms">Bathrooms</label> <br />
                                <InputText style={{ width: '100%' }} id="bathrooms" defaultValue={data?.bathrooms} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="price_range">Price Range</label> <br />
                                <InputText style={{ width: '100%' }} id="price_range" defaultValue={data?.unformatted_price_range} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="purchase_type">Purchase Type</label> <br />
                                <InputText style={{ width: '100%' }} id="purchase_type" defaultValue={data?.purchase_type} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="notes_about_home_search">Notes about home search</label> <br />
                                <InputText style={{ width: '100%' }} id="notes_about_home_search" defaultValue={data?.notes} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="priority_state">Priority State</label> <br />
                                <InputText style={{ width: '100%' }} id="priority_state" defaultValue={data?.priority_state} onChange={handleInputChange} />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="priority_city">Priority City</label> <br />
                                <InputText style={{ width: '100%' }} id="priority_city" defaultValue={data?.priority_city} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>
                        <FlexComponent className='inputContainer' gap='1rem'>
                            <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                <label htmlFor="why_are_you_looking_to_buy_at_this_time">Why are you looking to buy at this time?</label> <br />
                                <InputText style={{ width: '100%' }} id="why_are_you_looking_to_buy_at_this_time" defaultValue={data?.why_are_you_looking_to_buy_at_this_time} onChange={handleInputChange} />
                            </div>
                        </FlexComponent>

                        <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem' }}>
                            <Button type='button' label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                            <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                        </FlexComponent>
                    </form>
                </div>
            </Dialog>

        </FlexComponent>
    )
}

export default TypeOfHome