import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import './form.css'
import { Dropdown } from 'primereact/dropdown'

function GetStarted({ formName, setLanguageSelect, values, errors, touched, handleBlur, handleChange }) {

    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='getStarted'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>I can definitely help. Let's get started!</p>
            </div>

            <FlexComponent className='inputContainer' gap='1rem' alignItems='start' style={{ padding: "1rem 0" }}>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '65%', margin: '0.5rem' }}>
                    <label htmlFor="name" style={{ color: Boolean((touched.firstName && errors.firstName) || (touched.middleName && errors.middleName) || (touched.lastName && errors.lastName)) ? 'red' : '', fontWeight: 'bold' }}>Name *</label> <br />
                    <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                        <div className='inputContainerDiv'>
                            <Field type="text" id="firstName" name="firstName" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            <label htmlFor="firstName" style={{ color: Boolean(touched.firstName && errors.firstName) ? 'red' : '' }}>First</label>
                            {/* <ErrorMessage name="firstName" component="div" className="error" style={{color:'red'}} /> */}
                        </div>
                        <div className='inputContainerDiv'>
                            <Field type="text" id="middleName" name="middleName" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            <label htmlFor="middleName" style={{ color: Boolean(touched.middleName && errors.middleName) ? 'red' : '' }}>Middle</label>
                            {/* <ErrorMessage name="middleName" component="div" className="error" style={{color:'red'}}/> */}
                        </div>
                        <div className='inputContainerDiv'>
                            <Field type="text" id="lastName" name="lastName" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                            <label htmlFor="lastName" style={{ color: Boolean(touched.lastName && errors.lastName) ? 'red' : '' }}>Last</label>
                            {/* <ErrorMessage name="lastName" component="div" className="error" style={{color:'red'}}/> */}
                        </div>

                    </FlexComponent>
                </div>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '35%', margin: '0.5rem' }}>
                    <label htmlFor="language" style={{ fontWeight: 'bold', color: Boolean(touched.language && errors.language) ? 'red' : '' }}>Language *</label> <br />
                    {['English', 'Spanish', 'Spanish & Some English', 'Other'].map((lang) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={lang} style={{ marginTop: '0.5rem' }}>
                                {/* <Field type="radio" id={lang} name="language" value={lang} as={RadioButton} /> */}
                                <RadioButton inputId="language" name="language" value={lang} onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name : `language`,
                                            value: e.value
                                        }
                                    })
                                    setLanguageSelect(e.value)
                                }} checked={values.language === lang} />
                                <label htmlFor={lang} className="ml-2">{lang}</label>

                            </FlexComponent >
                        );
                    })}
                    {values.language === 'Other' && (
                        <div style={{ marginTop: '1rem' }}>
                            <Field type="text" placeholder="Other" id="otherLanguage" name="otherLanguage" as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                        </div>
                    )}

                </div>
            </FlexComponent>
            {formName != "New Loan Set Up App" && <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '65%', margin: '0.5rem' }}>
                    <label htmlFor="where_are_you_in_the_process" >
                        <p style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}> Where are you in the process?</p>
                        <p>- Have you been out to see houses yet, or just browsing online? - Have you been preapproved yet? - How soon would you be available to go see homes?</p>
                    </label>
                    <Field rows={4} type="text" id="where_are_you_in_the_process" name="where_are_you_in_the_process" as={InputTextarea} style={{ width: '100%' }} />
                </div>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '35%', margin: '0.5rem' }}>
                    <label style={{ fontWeight: 'bold' }} htmlFor="will_this_be_your_first_time">Will this be your first time?</label> <br />
                    {["First Time", "I've purchased property before", "I own property now"].map((option) => {
                        return (
                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                <Field type="radio" id={option} name="will_this_be_your_first_time" value={option} as={RadioButton} />
                                <label htmlFor={option} className="ml-2">{option}</label>

                            </FlexComponent >
                        );
                    })}
                </div>
            </FlexComponent>}
            {formName === "New Loan Set Up App" && <FlexComponent>
                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '35%', margin: '0.5rem' }}>
                    <label style={{ fontWeight: 'bold' }} htmlFor="file_status">File Status</label> <br />
                    <Dropdown
                    id='file_status'
                    value={values.file_status}
                    options={[
                        { label: 'Documents In', value: 'Documents In' },
                        { label: 'Accepted Offer', value: 'Accepted Offer' },
                        { label: 'In Contract', value: 'In Contract' },
                    ]}
                    style={{ width: '100%' }}
                    onChange={(event) => handleChange('file_status')(event.value)}
                    onBlur={handleBlur}
                    placeholder='Select'
                    filter
                />
                </div>
                
            </FlexComponent>}

        </div>
    )
}

export default GetStarted