import React, { useRef, useState, useEffect } from 'react'
import FlexComponent from '../FlexComponent'
import { Button } from 'primereact/button'
import { SplitButton } from 'primereact/splitbutton';
import { Divider } from 'primereact/divider'
import { Avatar } from 'primereact/avatar'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import axios from '../../utils/axios'
import { UpdateCustomAppLead } from '../../utils/urls';


function Applicant({ data, setIsSubmit, handleStageUpdate, contactId, formId, userId, AddLeadFollowers, AddLeadSales, MoveLeadSalesToFollower, MoveLeadFollowerToSales, RemoveLeadFollowerSales, memberLoading, updateContactOpportunityOwner, toast }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isRemoveOpen, setIsRemoveOpen] = useState(false);

    const [salesTeam, setSalesTeam] = useState(null)
    const [followers, setFollowers] = useState(null)
    const [locationUsers, setLocationUsers] = useState(null)
    const [label, setLabel] = useState('')
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [contactOwner, setContactOwner] = useState(null);
    const [opportunitytOwner, setOpportunityOwner] = useState(null);
    const [opportunityOwnerData, setOpportunityOwnerData] = useState(null)
    const [contactOwnerData, setContactOwnerData] = useState(null)
    const [OwnersData, setOwnerData] = useState(null)



    const handleOpportunityowner = (e) => {
        let user = OwnersData.find(user => user.id === e.value);
        setOpportunityOwner(e.value)
        setOpportunityOwnerData(user)
    }

    const handleContactowner = (e) => {
        let user = OwnersData.find(user => user.id === e.value);
        if (user) {
            setContactOwner(e.value)
            setContactOwnerData(user)
            updateContactOpportunityOwner(user)
        }

    }


    const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                <Avatar image={option.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(option?.name)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                {option.name}
            </FlexComponent>
        );
    };

    const selectedUserTemplate = (option) => (
        <div className="selected-user">
            <Avatar image={option.image} size="small" shape="circle" style={{ marginRight: '8px' }} />
            {option.name}
        </div>
    );

    useEffect(() => {
        const filteredSalesTeam = data?.all_users.filter(user => user.role != 'followers')
        const filteredFollowers = data?.all_users.filter(user => user.role != 'sales')
        setSalesTeam(filteredSalesTeam)
        setFollowers(filteredFollowers)
        setLocationUsers(data?.location_available_users)
        setContactOwner(data?.contact_owner_data.id)
        // setOpportunityOwner(data?.opportunity_owner_data.id)
        // setOpportunityOwnerData(data?.opportunity_owner_data)
        setContactOwnerData(data?.contact_owner_data)
        setOwnerData(data?.user_owners)
    }, [data])


    const handleAddClick = (labelData) => {
        // console.log(locationUsers)
        setLabel(labelData)
        setIsOpen(true);
    };

    const handleRemoveClick = (labelData) => {
        // console.log(locationUsers)
        setLabel(labelData)
        setIsRemoveOpen(true);
    };

    const handleRemoveUser = (user) => {
        RemoveLeadFollowerSales(user)
    };

    const handleSave = (label) => {
        // console.log(selectedUsers)
        if (label === 'sales') {
            AddLeadSales(selectedUsers)
        }
        else {
            AddLeadFollowers(selectedUsers)
        }
        setIsOpen(false);
        setSelectedUsers(null)
        // const status = 200
        // if (status === 200) {
        //     if (label === 'sales') {
        //         const updateSalesTeam = selectedUsers ? selectedUsers.map(user => ({ ...user, role: label })) : [];
        //         console.log({updateSalesTeam});
        //         setSalesTeam(prev => [...prev, ...updateSalesTeam]);
        //     } else {
        //         const updateFollowers = selectedUsers ? selectedUsers.map(user => ({ ...user, role: label })) : [];
        //         console.log({updateFollowers});
        //         setFollowers(prev => [...prev, ...updateFollowers]);
        //     }
        //     const updatedUsers = locationUsers.filter(item => !selectedUsers.some(user => user.id === item.id));
        //     console.log(updatedUsers)
        //     setLocationUsers(updatedUsers)
        // }
        // setIsOpen(false);
        // setSelectedUsers(null)
    };

    const handleCancel = () => {
        setSelectedUsers(null)
        setIsOpen(false);
    };

    const handlRemoveCancel = () => {
        // setSelectedUsers(null)
        setIsRemoveOpen(false);
    };

    const handleMoveToSales = (user) => {
        MoveLeadFollowerToSales(user)
    }

    const handleMoveToFollowers = (user) => {
        MoveLeadSalesToFollower(user)
    }

    // const toast = useRef(null);
    const stages = [
        {
            label: '⏲️ Waiting/Follow Up',
            // icon: 'pi pi-refresh',
            command: () => {
                handleStageUpdate(contactId, formId, '⏲️ Waiting/Follow Up', 1)
            }
        },
        {
            label: '🤳 Self-Serve Quick App',
            // icon: 'pi pi-refresh',
            command: () => {
                handleStageUpdate(contactId, formId, '🤳 Self-Serve Quick App', 3)
            }
        },
        {
            label: '📃✨ New Application',
            // icon: 'pi pi-refresh',
            command: () => {
                handleStageUpdate(contactId, formId, '📃✨ New Application', 4)
            }
        },
        {
            label: '🤖 Auto Doc Request',
            // icon: 'pi pi-refresh',
            command: () => {
                handleStageUpdate(contactId, formId, '🤖 Auto Doc Request', 5)
            }
        },
        {
            label: '🔥 Prequalified Awaiting Docs',
            // icon: 'pi pi-times',
            command: () => {
                handleStageUpdate(contactId, formId, '🔥 Prequalified Awaiting Docs', 6)
            }
        },
        {
            label: '📄 Docs in - Active Client',
            // icon: 'pi pi-times',
            command: () => {
                handleStageUpdate(contactId, formId, '📄 Docs in - Active Client', 15)
            }
        },
        {
            label: '✅ Preapproved',
            // icon: 'pi pi-external-link',
            command: () => {
                handleStageUpdate(contactId, formId, '✅ Preapproved', 7)
            }
        },
        {
            label: '🛒Shopping',
            // icon: 'pi pi-upload',
            command: () => {
                handleStageUpdate(contactId, formId, '🛒Shopping', 8)
            }
        },
        {
            label: '📈 Refi',
            // icon: 'pi pi-times',
            command: () => {
                handleStageUpdate(contactId, formId, '📈 Refi', 9)
            }
        },
        {
            label: '✅ Active Loan',
            // icon: 'pi pi-times',
            command: () => {
                handleStageUpdate(contactId, formId, '✅ Active Loan', 10)
            }
        }
    ];

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    function changeIncomeColor(applicantIncome) {
        const income = parseInt(applicantIncome?.replace(/\D/g, ''));
        // console.log(income)
        if (isNaN(income)) return '';
        if (income >= 80) {
            return '#2FC623';
        } else if (income < 80 && income >= 65) {
            return 'orange';
        } else {
            return '#EB4243'
        }
    }

    function changeCreditColor(applicantCredit) {
        const credit = parseInt(applicantCredit);
        // console.log(credit)
        if (isNaN(credit)) return '';
        if (credit >= 620) {
            return '#2FC623';
        } else if (credit < 620 && credit >= 500) {
            return '#F7DF01';
        } else {
            return 'red'
        }
    }

    function changeAvailableColor(cashAvailable) {
        const amount = parseInt(cashAvailable?.replace(/\D/g, ''));
        // console.log(amount)
        if (isNaN(amount)) return '';
        if (amount >= 45) {
            return '#2FC623';
        } else if (amount < 45 && amount >= 20) {
            return '#FF9A00';
        } else {
            return 'red'
        }
    }

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [formData, setFormData] = useState({
        first_name: data?.first_name,
        middle_name: data?.middle_name,
        last_name: data?.last_name,
        income: data?.unformatted_income,
        applicant_credit: data?.applicant_credit,
        savings: data?.unformatted_savings,
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleEditClick = () => {
        setIsEditOpen(true);
    };

    const handleEditSave = async () => {
        try {
            const updatedData = {
                ...formData,
                type: "Applicant",
                userId: userId
            };
            console.log(updatedData);
            setIsEditOpen(false);
            console.log('Submit ')
            const url = `${UpdateCustomAppLead}${formId}`
            const response = await axios.post(url, updatedData, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Updated', life: 3000 });
                setIsSubmit(true)
                // onSubmitProps.resetForm();
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data, life: 3000 });
                setIsSubmit(true)
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            setIsSubmit(true)
        }
    };

    const handleEditCancel = () => {
        setIsEditOpen(false);
    };

    return (
        <>
            {/* <Toast ref={toast}></Toast> */}
            <FlexComponent className="applicantProfile" flexDirection='column' style={{ gap: '1.25rem', textAlign: 'center', background: 'white', padding: '1.5rem 2rem 2rem 1.5rem', borderRadius: '1rem', }}>
                <div style={{ width: '100%', textAlign: 'end' }}>
                    <Button label="Edit" style={{ borderRadius: '25px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleEditClick} />

                </div>
                <Avatar style={{ width: '150px', height: '150px' }} label={<span style={{ fontWeight: 'bold', fontSize: '62px', color: '#B2B2B2' }}>{data?.applicant_headshort}</span>} size="xlarge" shape="circle" />
                <p style={{ fontSize: '20px', fontWeight: '600' }}>{data?.applicant_name}</p>
                <p style={{ width: '250px', color: '#969696', fontSize: '16px' }}>{data?.applicant_work}</p>
                <FlexComponent style={{ width: '60%' }}>
                    <div>
                        <p style={{ fontWeight: 'bold', color: changeIncomeColor(data?.applicant_income), fontSize: '20px' }}>{data?.applicant_income}</p>
                        <p>Income</p>
                    </div>
                    <div>
                        <p style={{ fontWeight: 'bold', color: changeCreditColor(data?.applicant_credit), fontSize: '20px' }}>{data?.applicant_credit}</p>
                        <p>Credit</p>
                    </div>
                    <div>
                        <p style={{ fontWeight: 'bold', color: changeAvailableColor(data?.applicant_savings), fontSize: '20px' }}>{data?.applicant_savings}</p>
                        <p>Available</p>
                    </div>
                </FlexComponent>
                <FlexComponent className="inputContainer" gap="2rem" justifyContent="center">
                    <SplitButton style={{ background: '#0C8CE9', border: 'none' }} label={data?.application_stage} model={stages} />
                    <Button style={{ background: '#da474e', border: 'none' }} onClick={() => window.open(`https://app.hfsarm.com/v2/location/${data?.location_id}/contacts/detail/${data?.contact_id}`, '_blank')}>View Contact</Button>
                </FlexComponent>
                <div>

                </div>
                {/* <p>Application started with {data?.application_by} {data?.application_date}</p> */}
                <FlexComponent justifyContent='start' alignItems="start" style={{ gap: '.5rem' }}>
                    <div>
                    <Avatar image = {data?.application_by_image} style={{ width: '40px', height: '40px' }} label={<span style={{ color: '#B2B2B2' }}>{data?.application_by_headshort}</span>} size="medium" shape="circle" />
                    </div>
                    <div style={{fontSize:"16px"}} >Application started with {data?.application_by} {data?.application_date} </div>
                    <div style={{ color: '#969696', fontSize:"10px", minWidth:"80px", marginTop:"6px" }}>{data?.application_difference}</div>

                </FlexComponent>
                {/* <div style={{ textAlign: 'left' }}>
                <p style={{ fontWeight: 'bold' }}>About</p>
                <p style={{ marginTop: '1rem' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div> */}
                <Divider />

                <FlexComponent className='inputContainerFlex' alignItems="start" style={{ justifyContent: 'center' }}>

                    <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                        <FlexComponent gap="0.2rem" style={{ cursor: 'pointer' }} justifyContent="start" >
                            <label htmlFor="" style={{ fontWeight: 'bold' }}>Sales</label>
                            <Avatar onClick={() => {
                                handleAddClick('sales')
                            }} size="large" shape='circle' style={{ background: 'white', border: '1px solid #8C8C8C', width: '25px', height: '25px' }} label={<i className="pi pi-plus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />

                            {salesTeam?.length > 0 && <Avatar onClick={() => {
                                handleRemoveClick('salesRemove')
                            }} size="large" shape='circle' style={{ background: 'white', border: '1px solid #8C8C8C', width: '25px', height: '25px' }} label={<i className="pi pi-minus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />}
                        </FlexComponent>

                        <FlexComponent style={{ marginTop: '.8rem' }} justifyContent="start" gap="0.1rem">
                            {salesTeam?.map((user, index) => (
                                <>
                                    {index < 3 && <Avatar image={user.image} style={{ width: '35px', height: '35px' }} key={user.id} size="small" shape='circle'
                                        label={getInitials(user?.name)}
                                    />}
                                </>
                            ))}
                            {salesTeam?.length > 3 && <Avatar style={{ width: '35px', height: '35px' }} size="small" shape='circle' label={`${salesTeam.length - 3}+`} />}
                        </FlexComponent>
                    </div>

                    <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                        <label htmlFor="" style={{ fontWeight: 'bold' }}>Contact Owner</label>
                        <FlexComponent style={{ justifyContent: 'center', marginTop: '.5rem', pointerEvents: memberLoading && "none", opacity: memberLoading && "0.5" }}>
                            {contactOwner && <Avatar size="small" image={contactOwnerData?.image} shape='circle'
                                label={<span style={{ padding: '5px' }}>{getInitials(contactOwnerData?.name)}</span>}
                                style={{ background: 'white', border: '1px solid #8C8C8C', width: '30px', height: '30px' }} />}
                            {/* <span style={{fontSize:'.8rem'}}>{contactOwnerData?.name}</span> */}
                            <Dropdown
                                value={contactOwner}
                                style={{ width: '100%', textAlign: 'start', border: 'none', boxShadow: 'none' }}
                                options={OwnersData}
                                onChange={handleContactowner}
                                optionLabel="name"
                                itemTemplate={userTemplate}

                                placeholder="Unassigned"
                                filter
                                // showClear
                                className="user-dropdown"
                                optionLabelTemplate={userTemplate}
                                optionValue="id"
                            />
                        </FlexComponent>
                        {memberLoading && <p style={{ color: "green" }} >loading...</p>}
                    </div>

                    <div className='inputContainerDiv' style={{ margin: '0.5rem' }}>
                        <FlexComponent gap="0.2rem" justifyContent="start" style={{ cursor: 'pointer' }}>
                            <label htmlFor="" style={{ fontWeight: 'bold' }}>Followers</label>
                            <Avatar onClick={() => {
                                handleAddClick('followers')
                            }} size="large" shape='circle' style={{ background: 'white', border: '1px solid #8C8C8C', width: '25px', height: '25px' }} label={<i className="pi pi-plus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />
                            {followers?.length > 0 && <Avatar onClick={() => {
                                handleRemoveClick('followersRemove')
                            }} size="large" shape='circle' style={{ background: 'white', border: '1px solid #8C8C8C', width: '25px', height: '25px' }} label={<i className="pi pi-minus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />
                            }
                        </FlexComponent>

                        <FlexComponent style={{ marginTop: '.8rem' }} justifyContent="start" gap="0.5rem">
                            {followers?.map((user, index) => (
                                <>
                                    {index < 3 && <Avatar image={user.image} style={{ width: '35px', height: '35px' }} key={user.id} size="small" shape='circle'
                                        label={getInitials(user?.name)}
                                    />}
                                </>
                            ))}
                            {followers?.length > 3 && <Avatar style={{ width: '35px', height: '35px' }} size="large" shape='circle' label={`${followers.length - 3}+`} />}
                        </FlexComponent>
                    </div>

                </FlexComponent>

                {/* <Divider /> */}

                {/* <FlexComponent style={{ width: "100%" }} gap="0.5rem">
                    <div style={{ color: '#8C8C8C', width: '25%' }}>Sales Team</div>
                    <FlexComponent style={{ width: '65%' }} justifyContent="center" gap="0.4rem">
                        {salesTeam?.map((user, index) => (
                            <>
                                {index < 4 && <Avatar image={user.image} style={{ width: '45px', height: '45px' }} key={user.id} size="small" shape='circle'
                                    label={getInitials(user?.name)}
                                />}
                            </>
                        ))}
                        {salesTeam?.length > 4 && <Avatar style={{ width: '45px', height: '45px' }} size="small" shape='circle' label={`${salesTeam.length - 4}+`} />}
                    </FlexComponent>
                    <FlexComponent flexDirection='column' gap="0.2rem" style={{ width: '10%', cursor: 'pointer' }}>
                        <Avatar onClick={() => {
                            handleAddClick('sales')
                        }} size="large" shape='circle' style={{ background: 'white', border: '2px solid #8C8C8C', width: '30px', height: '30px' }} label={<i className="pi pi-plus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />

                        {salesTeam?.length > 0 && <Avatar onClick={() => {
                            handleRemoveClick('salesRemove')
                        }} size="large" shape='circle' style={{ background: 'white', border: '2px solid #8C8C8C', width: '30px', height: '30px' }} label={<i className="pi pi-minus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />}

                    </FlexComponent>

                </FlexComponent>
                <Divider />

                <FlexComponent style={{ width: "100%" }} >
                    <div style={{ color: '#8C8C8C', width: '20%' }}>Followers</div>
                    <FlexComponent style={{ width: '65%' }} justifyContent="center" gap="0.4rem">
                        {followers?.map((user, index) => (
                            <>
                                {index < 4 && <Avatar image={user.image} style={{ width: '45px', height: '45px' }} key={user.id} size="small" shape='circle'
                                    label={getInitials(user?.name)}
                                />}
                            </>
                        ))}
                        {followers?.length > 4 && <Avatar style={{ width: '45px', height: '45px' }} size="large" shape='circle' label={`${followers.length - 4}+`} />}
                    </FlexComponent>
                    <FlexComponent flexDirection='column' gap="0.2rem" style={{ width: '10%', cursor: 'pointer' }}>
                        <Avatar onClick={() => {
                            handleAddClick('followers')
                        }} size="large" shape='circle' style={{ background: 'white', border: '2px solid #8C8C8C', width: '30px', height: '30px' }} label={<i className="pi pi-plus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />
                        {followers?.length > 0 && <Avatar onClick={() => {
                            handleRemoveClick('followersRemove')
                        }} size="large" shape='circle' style={{ background: 'white', border: '2px solid #8C8C8C', width: '30px', height: '30px' }} label={<i className="pi pi-minus" style={{ fontSize: '1.25rem', color: '#8C8C8C' }}></i>} />
                        }
                    </FlexComponent>
                </FlexComponent> */}

                <Dialog className='editForm' header={label === 'sales' ? "Add Sales Team" : "Add Followers"} visible={isOpen} onHide={() => handleCancel()}>
                    <div >
                        <FlexComponent className='inputContainer' gap='1rem' justifyContent="center" style={{ margin: '0.5rem' }}>
                            <MultiSelect style={{ width: '60%' }} value={selectedUsers} onChange={(e) => setSelectedUsers(e.value)} options={locationUsers} optionLabel="name"
                                filter placeholder="Select Users" maxSelectedLabels={3} className="w-full md:w-20rem" />
                        </FlexComponent>

                        <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem' }}>
                            <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleCancel} />
                            <Button type='button' label="Add" className="p-button-success" onClick={() => handleSave(label)} />
                        </FlexComponent>
                    </div>
                </Dialog>

                <Dialog className='editForm' header={label === 'salesRemove' ? "Remove Sales Team" : "Remove Followers"} visible={isRemoveOpen} onHide={() => handlRemoveCancel()}>
                    <div >
                        <FlexComponent className='inputContainer' flexDirection="column" gap='1rem' justifyContent="center" style={{ margin: '0.5rem' }}>
                            {label === 'salesRemove' && salesTeam?.map((user, index) => (
                                <FlexComponent justifyContent="center" gap="2rem">
                                    <p style={{ width: '200px' }}>{user.name}</p>
                                    <Button onClick={() => {
                                        handleMoveToFollowers(user)
                                    }}>Move to Followers</Button>
                                    <Button onClick={() => {
                                        handleRemoveUser(user)
                                    }}><i className="pi pi-trash" style={{ fontSize: '1rem' }}></i></Button>
                                </FlexComponent>
                            ))}
                            {label === 'followersRemove' && followers?.map((user, index) => (
                                <FlexComponent justifyContent="center" gap="2rem">
                                    <p style={{ width: '200px' }}>{user.name}</p>
                                    <Button onClick={() => {
                                        handleMoveToSales(user)
                                    }}>Move to Sales Team</Button>
                                    <Button onClick={() => {
                                        handleRemoveUser(user)
                                    }}><i className="pi pi-trash" style={{ fontSize: '1rem' }}></i></Button>
                                </FlexComponent>
                            ))}
                        </FlexComponent>
                    </div>
                </Dialog>

                <Dialog className='editForm' header="Edit Applicant Data" visible={isEditOpen} onHide={() => handleEditCancel()}>
                    <div >
                        <form  >
                            <FlexComponent className='inputContainer' gap='1rem'>
                                <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                    <label htmlFor="first_name">First Name</label> <br />
                                    <InputText style={{ width: '100%' }} id="first_name" name="first_name" defaultValue={data?.first_name} onChange={handleInputChange} />
                                </div>
                                <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                    <label htmlFor="middle_name">Middle Name</label> <br />
                                    <InputText style={{ width: '100%' }} id="middle_name" defaultValue={data?.middle_name} onChange={handleInputChange} />
                                </div>
                            </FlexComponent>
                            <FlexComponent className='inputContainer' gap='1rem'>
                                <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                    <label htmlFor="last_name">Last Name</label> <br />
                                    <InputText style={{ width: '100%' }} id="last_name" defaultValue={data?.last_name} onChange={handleInputChange} />
                                </div>
                                <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                    <label htmlFor="income">Income</label> <br />
                                    <InputText style={{ width: '100%' }} id="income" defaultValue={data?.unformatted_income} onChange={handleInputChange} />
                                </div>
                            </FlexComponent>
                            <FlexComponent className='inputContainer' gap='1rem'>
                                <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                    <label htmlFor="applicant_credit">Credit</label> <br />
                                    <InputText style={{ width: '100%' }} id="applicant_credit" defaultValue={data?.applicant_credit} onChange={handleInputChange} />
                                </div>
                                <div style={{ textAlign: 'start', width: '100%', margin: '0.5rem' }}>
                                    <label htmlFor="savings">Available</label> <br />
                                    <InputText style={{ width: '100%' }} id="savings" defaultValue={data?.unformatted_savings} onChange={handleInputChange} />
                                </div>
                            </FlexComponent>
                            <FlexComponent justifyContent='center' gap='1rem' style={{ marginTop: '1rem' }}>
                                <Button type='button' label="Save" className="p-button-success" onClick={() => handleEditSave()} />
                                <Button type='button' label="Cancel" className="p-button-secondary" onClick={handleEditCancel} />
                            </FlexComponent>
                        </form>
                    </div>
                </Dialog>

            </FlexComponent>

        </>
    )
}

export default Applicant