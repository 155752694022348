import { useSortable } from '@dnd-kit/sortable';
import React, { useRef, useState } from 'react'
import FlexComponent from '../../FlexComponent';
import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';
import { Mention } from 'primereact/mention';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import axios from '../../../utils/axios';
import { createCheckList, deleteCheckList, updateCheckList } from '../../../utils/urls'
import { CSS } from "@dnd-kit/utilities";

function CheckListItem({ list, index, usersList, convertDateToString, getNameById, userTemplate, getInitials, itemTemplate, setIsCheckListEdit,
    setCheckLists,
    setIsCheckListAdd,
    updateCheckList,
    userId,
    toast,
    handleClose,
    setValue,
    setMentionedUsers,
    setAssignedUsers,
    setDueDate,
    checkLists,
    suggestions,
    onSearch,
    convertDateToDateString,
    setCheckListEditIndex,
    checkListEditIndex,
    isCheckListEdit }) {

    const [editCheckListValue, setEditCheckListValue] = useState(null)
    const [editMentionedUsers, setEditMentionedUsers] = useState([]);
    const [editAssignedUsers, setEditAssignedUsers] = useState([])
    const [editDueDate, setEditDueDate] = useState('')

    const editDueDateOp = useRef(null);
    const editAssignOp = useRef(null);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: list.checklist_id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%",
        // background: "white",
        padding: "0.4rem 1rem",
        // display: hideCheckedItems && list.completed ? 'none' : '' 
    };

    // console.log(checkLists)
    const handleSaveEditCheckList = (list) => {
        const data = {
            // checklist_id : list.checklist_id,
            item: editCheckListValue,
            mention: editMentionedUsers.map(item => ({ id: item.id })),
            assigned_user: editAssignedUsers.map(item => ({ id: item })),
            due_date: editDueDate ? convertDateToString(editDueDate) : list.due_date,
            is_completed: list.completed
        }
        console.log(data)
        setIsCheckListEdit(false)
        setCheckLists(prevItems =>
            prevItems.map(item =>
                item.checklist_id === list.checklist_id ? { ...list, ...data } : item
            )
        );
        setIsCheckListAdd(false)
        // setCommentLoad(true)
        const Url = `${updateCheckList}${list.checklist_id}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setCommentLoad(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })
        setValue('');
        setMentionedUsers([]);
        setAssignedUsers([]);
        setDueDate('')
    };

    const handleDelete = (list) => {
        const updatedData = checkLists?.filter(item => item.checklist_id !== list.checklist_id);
        setCheckLists(updatedData);
        const Url = `${deleteCheckList}${list.checklist_id}/${userId}`
        axios.post(Url, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {

                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                    handleClose()
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

    };

    function getImageById(id) {
        const item = usersList.find(obj => obj.value === id);
        return item ? item.image : null; // Return the name if found, otherwise return null
    }

    function formatDateString(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }


    const convertDateStringToDate = (dateString) => {
        if (!dateString) return null;
        const [year, month, day] = dateString.split('-');
        return new Date(year, month - 1, day); // month is 0-based in Date
    };


    const handleEdit = (index) => {
        setIsCheckListAdd(false)
        setIsCheckListEdit(true);
        setCheckListEditIndex(index);
        setEditCheckListValue(checkLists[index].item);
        setEditAssignedUsers(checkLists[index].assigned_user.map(list => list.id))
        setEditDueDate(convertDateStringToDate(checkLists[index].due_date))
        setEditMentionedUsers(checkLists[index].mention)
        // setEditUsers(extractUsers(commentsData[index].comment));
    };

    const onListChange = (e, list) => {
        const { value, checked } = e.target;
        setCheckLists((prevCheckLists) =>
            prevCheckLists.map((list) =>
                list.checklist_id === value.checklist_id
                    ? { ...list, completed: checked }
                    : list
            )
        );
        const { completed, checklist_id, created_at, created_by_image, assigned_user, created_by_name, mention, ...listData } = list
        const data = {
            ...listData,
            assigned_user: assigned_user.map(item => ({ id: item.id })),
            mention: mention.map(item => ({ id: item.id })),
            is_completed: checked
        }
        console.log(data)
        const Url = `${updateCheckList}${list.checklist_id}/${userId}`
        axios.post(Url, data, {
            headers: { 'Content-Type': 'application/json' },
        }).then((response) => {
            if (response.status === 200) {
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            }
        })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                handleClose()
            })

    };
    const highlightMentions = (text) => {
        const mentionRegex = /@[\w.-]+/g;
        const parts = text.split(mentionRegex);
        const mentions = text.match(mentionRegex) || [];

        const result = [];
        parts.forEach((part, index) => {
            result.push(part);
            if (mentions[index]) {
                result.push(<span key={index} style={{ color: "#2FC623" }} className="mention">{mentions[index]}</span>);
            }
        });

        return result;
    };

    return (

        <div className="list-row" ref={setNodeRef} key={list.checklist_id} style={style} >
            {/* <Checkbox inputId={list.checklist_id} name="list" value={list} onChange={onListChange} checked={list.completed} />
                <span htmlFor={list.checklist_id} =onClick{() => {
                    }} style={{ marginLeft: "0.5rem", textDecoration: list.completed ? 'line-through' : '', cursor: "pointer" }} >
                    {list.item}
                    </span> */}
            <FlexComponent className='checkListItem' alignItems="start" gap=".3rem" style={{ padding: "0.5rem", border: '1px solid grey', borderRadius: ".5rem" }}>
                <span style={{ cursor: "pointer", fontSize: "15px" }} {...attributes} {...listeners}>⣿</span>
                <Checkbox inputId={list.checklist_id} name="list" value={list} onChange={(event) => {
                    onListChange(event, list)
                }} checked={list.completed} />
                {!isCheckListEdit || checkListEditIndex !== index ? (
                    <FlexComponent className="applicantContainer" justifyContent="start" >
                        <div className="applicantDivs" htmlFor={list.checklist_id} style={{ display: "flex", justifyContent: "space-between", width: "100%", marginLeft: "0.5rem", cursor: "pointer" }} >
                            <div className="applicantDivs" onClick={() => {
                                handleEdit(index)
                            }} style={{ width: "65%", textDecoration: list.completed ? 'line-through' : '' }}> {highlightMentions(list.item)}</div>
                            <FlexComponent className='fullWidth' gap="1rem" style={{ width: "35%" }} >
                                <div onClick={() => {
                                    handleEdit(index)
                                }}>{list?.due_date ? <span style={{ fontSize: "14px" }} ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>{formatDateString(list?.due_date)}</span> : ''}</div>
                                <div onClick={() => {
                                    handleEdit(index)
                                }}>
                                    {/* {list.assigned_user.length === 0 && <i className="pi pi-user-plus" style={{ marginRight: '5px' }}></i>} */}
                                    {list.assigned_user?.map((user, index) => (
                                        <Avatar key={index} style={{ fontSize: "14px", width: '25px', height: '25px', color: 'black', marginRight: "2px" }} image={getImageById(user?.id)} shape='circle' label={getInitials(getNameById(user.id))} />
                                    ))}
                                </div>
                                <div><i onClick={() => {
                                    handleDelete(list)
                                }} className="pi pi-trash" style={{ marginRight: '5px' }}></i></div>
                            </FlexComponent>
                        </div>
                    </FlexComponent>
                ) : (
                    <div className="applicantDivs" style={{ width: "100%" }} >
                        <Mention
                            value={editCheckListValue}
                            onChange={(e) => {
                                setEditCheckListValue(e.target.value)
                            }}
                            suggestions={suggestions}
                            onSearch={onSearch}
                            field="label"
                            placeholder="Edit comment..."
                            // rows={1}
                            cols={50}
                            itemTemplate={itemTemplate}
                            className="edit-mention-input"
                            style={{ width: "98%", marginLeft: "0.5rem" }}
                        />
                        <FlexComponent >
                            <FlexComponent gap="0.5rem" justifyContent="start" style={{ marginTop: ".5rem" }} >
                                <Button style={{ padding: "0.4rem 1rem", background: "#2FC635", border: "1px solid grey" }} onClick={() => {
                                    handleSaveEditCheckList(list)
                                }} >Save</Button>
                                <Button style={{ padding: "0.4rem 1rem", border: "1px solid grey" }} onClick={() => {
                                    setIsCheckListEdit(false)
                                    setEditAssignedUsers([])
                                    setEditCheckListValue('');
                                    setEditDueDate('')
                                }} >Cancel</Button>
                            </FlexComponent>
                            <FlexComponent justifyContent="center" gap="1rem" >
                                <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                    <span onClick={(e) => editAssignOp.current.toggle(e)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }} ><i className="pi pi-user-plus" style={{ marginRight: '5px' }}></i>
                                        {editAssignedUsers.length > 0 ? (
                                            <>
                                                {editAssignedUsers.map((id, index) => (
                                                    <Avatar style={{ fontSize: "14px", width: '25px', height: '25px', color: 'black', marginRight: "2px" }} image={getImageById(id)} shape='circle' label={getInitials(getNameById(id))} />
                                                ))}
                                                {/* {editAssignedUsers.length > 1 && <span style={{ marginLeft: ".2rem", padding: ".2rem", background: "white", color: "black", borderRadius: "25px" }}>+{editAssignedUsers.length - 1}</span>} */}
                                            </>
                                        ) :
                                            'Assign'}
                                    </span>
                                </FlexComponent>
                                <FlexComponent style={{ border: "none", boxShadow: "none" }} justifyContent="start" >
                                    <span style={{ cursor: "pointer" }}
                                        onClick={(e) => editDueDateOp.current.toggle(e)}
                                    ><i className="pi pi-clock" style={{ marginRight: '5px' }}></i>
                                        {list.due_date ? <span>{editDueDate ? convertDateToDateString(editDueDate) : list.due_date}</span> : <span>Due Date</span>}
                                    </span>
                                </FlexComponent>
                            </FlexComponent>
                        </FlexComponent>
                        <OverlayPanel ref={editAssignOp} style={{ background: '#1A2B53', maxWidth: "400px" }} showCloseIcon>
                            <MultiSelect
                                value={editAssignedUsers}
                                options={usersList}
                                itemTemplate={userTemplate}
                                style={{ width: '100%', color: 'black' }}
                                onChange={(event) => {
                                    setEditAssignedUsers(event.value)
                                }}
                                placeholder='Select Members'
                                filter
                            />
                        </OverlayPanel>
                        <OverlayPanel ref={editDueDateOp} style={{ background: '#1A2B53' }} >
                            <Calendar id="buttondisplay" value={editDueDate} inline onChange={(e) => {
                                setEditDueDate(e.value)
                                editDueDateOp.current.toggle(e)
                            }} />
                        </OverlayPanel>
                    </div>
                )}
            </FlexComponent>
        </div>
    )
}

export default CheckListItem