import React, { useEffect, useState } from 'react'
import { deleteDesignation, getDesignations, getLabel, getPipelines, labelCreate, labelDelete, labelUpdate, upserDesignation } from '../../utils/urls'
import axios from '../../utils/axios';
import { InputText } from 'primereact/inputtext';
import FlexComponent from '../FlexComponent';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

function Designations({ toast }) {
    const [title, setTitle] = useState('')
    const [editTitle, setEditTitle] = useState('')
    // const [pipelines, setPipelines] = useState([])
    const [editPipelines, setEditPipelines] = useState([])
    const [pipelinesList, setPipelinesList] = useState([])
    const [selectedPipelines, setSelectedPipelines] = useState([])
    const [pipelineId, setPipelineId] = useState('')
    const userId = useSelector(state => state.user.userId);

    const [isAddDesignation, setIsAddDesignation] = useState(false)
    const [designationList, setDesignationList] = useState([])
    const [designationEditIndex, setDesignationEditIndex] = useState()
    const [isDesignationEdit, setIsDesignationEdit] = useState()

    const [loading, setLoading] = useState(true)
    const [isMounted, setIsMounted] = useState(false)

    const handleAddDesignation = () => {
        setIsMounted(true)
        setIsAddDesignation(false)
        const data = {
            title: title,
            pipelines: selectedPipelines
        }
        axios.post(upserDesignation, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    setTitle('')
                    setSelectedPipelines([])
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    const handleEditSubmit = (id) => {
        setIsDesignationEdit(false)
        setIsMounted(true)
        const data = {
            designation_id: id,
            title: editTitle,
            pipelines: selectedPipelines
        }
        console.log(data);
        const url = `${upserDesignation}`
        axios.post(url, data, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    // setTitle('')
                    toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                    setIsMounted(false)
                }
                else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            })
    }

    // console.log(color)
    const handleClick = () => {
        setSelectedPipelines([])
        setTitle('')
        setIsAddDesignation(!isAddDesignation)
        setIsDesignationEdit(false);
    }

    const handleEdit = (index) => {
        setIsAddDesignation(false)
        setIsDesignationEdit(true);
        setDesignationEditIndex(index);
        setEditTitle(designationList[index].title);
        setSelectedPipelines(designationList[index].pipelines.map(list => list.id))
    };

    const getAllDesignations = () => {
        const url = `${getDesignations}`
        axios.get(url, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data)
                    setDesignationList(response.data)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const getPipelinesList = () => {
        if (userId) {
            const pipelineUrl = `${getPipelines}?userId=${userId}`;
            axios.get(pipelineUrl, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    if (response.status === 200) {
                        const options = response?.data?.map((user) => ({
                            label: user.name,
                            value: user.id
                        })) || [];
                        setPipelinesList(options);
                    }
                })
                .catch(() => { });
        }
    };


    const handleDelete = (list) => {
        // const updatedData = checkLists?.filter(item => item.checklist_id !== list.checklist_id);
        // setCheckLists(updatedData);
        setIsMounted(true)
        confirmDialog({
            message: 'Are you sure you want to delete this Designation?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const Url = `${deleteDesignation}${list.id}`
                axios.post(Url, {
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMounted(false)
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                            // handleClose()
                            setIsMounted(false)
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                        // handleClose()
                        setIsMounted(false)
                    })
                
            },
            reject : ()=>{
                console.log("closed")
            }
        });

    };
    const userTemplate = (option) => {
        return (
            <FlexComponent justifyContent="start">
                {/* <Avatar image={option.image} size="small" label={getInitials(option?.label)} shape="circle" style={{ marginRight: '8px' }} /> */}
                <span>{option.label}</span>
            </FlexComponent>
        );
    };

    useEffect(() => {
        getAllDesignations()
        getPipelinesList()
    }, [isMounted])

    // console.log(pipelinesList)
    // console.log(selectedPipelines)
    return (
        <div>
            <ConfirmDialog />
            {designationList?.length > 0 &&
                <div>
                    {designationList.map((list, index) => (
                        <div key={index} style={{ marginTop: ".5rem" }} >
                            {!isDesignationEdit || designationEditIndex !== index ? (<FlexComponent gap="1rem" >
                                <FlexComponent justifyContent="start" style={{ height: "50px", width: "100%" }} >
                                    <p  >{list.title}</p>
                                </FlexComponent>
                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => {
                                    handleEdit(index)
                                }} > <i className='pi pi-pencil' ></i></Button>
                                <Button style={{ padding: ".5rem .6rem" }} onClick={() => {
                                    handleDelete(list)
                                }} > <i className='pi pi-trash' ></i></Button>
                            </FlexComponent>) :
                                <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>

                                    <div className='inputContainerDiv' style={{ width: '100%' }}>
                                        <label htmlFor="email" style={{ fontWeight: 'bold' }}>Title *</label>
                                        <InputText size="small" style={{ width: '100%' }} id="title" value={editTitle}
                                            onChange={(e) => {
                                                setEditTitle(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className='inputContainerDiv' style={{ width: '100%', }}>
                                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Pipeline *</label>
                                        <MultiSelect
                                            value={selectedPipelines}
                                            options={pipelinesList}
                                            itemTemplate={userTemplate}
                                            style={{ width: '100%', color: 'black' }}
                                            onChange={(event) => {
                                                setSelectedPipelines(event.value)
                                            }}
                                            placeholder='Select Pipelines'
                                            filter
                                        />
                                    </div>
                                    <FlexComponent justifyContent="center" gap="1rem" >
                                        <Button type='button' onClick={() => {
                                            handleEditSubmit(list.id)
                                        }} disabled={!editTitle.trim()} >Update</Button>
                                        <Button style={{ background: "white", color: " black" }} type='button' onClick={() => {
                                            setIsDesignationEdit(false)
                                        }} >Discard Changes</Button>
                                    </FlexComponent>
                                </FlexComponent>
                            }
                        </div>
                    ))}
                </div> }

                {loading && <FlexComponent justifyContent="center" >
                    <ProgressSpinner />
                </FlexComponent>}

            <Button style={{ marginTop: ".5rem" }} onClick={handleClick} >Add Designation</Button>
            {isAddDesignation && <FlexComponent style={{ marginTop: "1rem", border: "1px solid grey", padding: "1rem", borderRadius: "15px" }} className='inputContainer' flexDirection="column" gap='.5rem' alignItems='center'>

                <div className='inputContainerDiv' style={{ width: '100%' }}>
                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Title *</label>
                    <InputText size="small" style={{ width: '100%' }} id="title" value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                </div>
                <div className='inputContainerDiv' style={{ width: '100%'}}>
                <label htmlFor="email" style={{ fontWeight: 'bold' }}>Pipeline *</label>
                    <MultiSelect
                        value={selectedPipelines}
                        options={pipelinesList}
                        itemTemplate={userTemplate}
                        style={{ width: '100%', color: 'black' }}
                        onChange={(event) => {
                            setSelectedPipelines(event.value)
                        }}
                        placeholder='Select Pipelines'
                        filter
                    />
                </div>
                <FlexComponent justifyContent="center" gap="1rem" >
                    <Button type='button' onClick={handleAddDesignation} disabled={!title.trim() || selectedPipelines.length === 0} >Save</Button>
                    <Button style={{ background: "white", color: "black" }} type='button' onClick={() => {
                        setIsAddDesignation(false)
                    }} >Cancel</Button>
                </FlexComponent>
            </FlexComponent>}

        </div>
    )
}

export default Designations