import React, { useState } from 'react'
import FlexComponent from '../FlexComponent'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import { ErrorMessage, Field, FieldArray } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import './form.css'
import { Dialog } from 'primereact/dialog'
import { Avatar } from 'primereact/avatar'

function WorkHistory({ values, errors, touched, handleBlur, handleChange }) {
    const [isEditOpen, setIsEditOpen] = useState(false); // State for popup visibility
    const [workIndex, setWorkIndex] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const handleAddClick = () => {
        setLoading(true)
        values.work_history.push({
            title: '',
            yearly_income: 0,
            time_at_position: '',
            otherTimeAtPostion: '',
            company_name: '',
            employment_type: 'Salary - W2 Employee',
            status: '',
            start_date: null,
            end_date: null,
            work_history_description: '',
        });

        //   handleChange('work_history')(values.work_history); 
        setWorkIndex(values.work_history.length - 1)
        setIsEdit(false);
        setIsDelete(false)
        setIsEditOpen(true);
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    };


    function changeAvatarColor(type) {
        if (!type) return ''
        if (type.includes("W2")) {
            return '#F141D0';
        } else if (type === "1099 Independent Contractor") {
            return '#418BCC';
        } else {
            return '#EB4243'
        }
    }

    function avatarTitle(type) {
        if (!type) return ''
        if (type.includes("W2")) {
            return 'W2';
        } else if (type === "1099 Independent Contractor") {
            return '1099';
        } else if (type === "Self Employed/Business Owner") {
            return 'SE'
        }else{
            return ''
        }
    }

    const handleSave = ()=>{
        const workEntry = values.work_history[workIndex];
        const errors = {};
        if (!workEntry.title?.trim()) errors.title = true;
        if (!workEntry.yearly_income) errors.yearly_income = true;
        if (!workEntry.time_at_position) errors.time_at_position = true;
        if (!workEntry.status && typeof workEntry.status !== 'boolean') errors.status = true;
        setError(errors);

        if (Object.keys(errors).length === 0) {
            setIsEditOpen(false);
        }
    }

    return (
        <div style={{ width: '100%', padding: '1.5rem' }} id='workAndIncome'>
            <div style={{ background: '#08113A' }}>
                <p style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.75rem', color: 'white' }}>What do you do for work?</p>
            </div>
            <div style={{ margin: "1rem 0.5rem" }}>
                <FlexComponent>
                    <label >Let's collect a full 2 to 3 years of work history</label>
                    <svg onClick={handleAddClick} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" className="mercado-match" width="24" height="24" focusable="false">
                        <path d="M21 13h-8v8h-2v-8H3v-2h8V3h2v8h8z"></path>
                    </svg>
                </FlexComponent>
            </div>
            {!loading && values.work_history?.map((work, index) => (
                <FlexComponent  key={index} style={{ background: 'white', margin: '1rem 0', padding: '1rem', borderRadius: '10px' }} gap='1rem'>
                    <FlexComponent alignItems='start' justifyContent='start' gap='1rem' style={{ textAlign: 'start' }}>
                        <div>
                            <Avatar style={{ width: '4.5rem', color: 'white', background: changeAvatarColor(work?.employment_type) }} label={avatarTitle(work?.employment_type)} size="xlarge" />
                        </div>
                        <div style={{ width: '50%' }}>
                            <p>{work?.title}</p>
                            <p>{work?.employment_type}</p>
                            <p style={{ color: '#8C8C8C' }}>{work?.time_at_position}</p>
                            <p ><span style={{ fontSize: '22px', fontWeight: 'bold' }}>$ {work?.yearly_income}</span><span style={{ marginLeft: '.3rem' }}>Yearly</span></p>
                        </div>
                    </FlexComponent>
                    <Button type='button' onClick={() => {
                        setWorkIndex(index)
                        setIsEdit(true)
                        setIsDelete(true)
                        setIsEditOpen(true);
                    }}>Edit</Button>
                </FlexComponent>
            ))}
            <div style={{ textAlign: "center", margin: '1rem' }}>
                <Button type='button' label="Add Work History" style={{ borderRadius: '5px', fontSize: '16px', padding: '5px 20px', border: '1px solid #E0E4EC', background: 'white', color: '#8C8C8C' }} onClick={handleAddClick} />
            </div>
            <div style={{ textAlign: "center", margin: '1rem' }}>
                <ErrorMessage name="work_history" component="div" className="error" style={{ color: 'red' }} />
            </div>
            <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                <label htmlFor="do_you_have_any_additional_source_of_income" >
                    <p style={{ fontWeight: 'bold' }}>Do you have any additional sources of income?</p>
                    <p style={{ margin: "0.5rem 0" }}>eg. Investment income, rental income, side job income, child support, alimony, etc.</p>
                </label>
                <Field rows={4} type="text" id="do_you_have_any_additional_source_of_income" name="do_you_have_any_additional_source_of_income" as={InputTextarea} style={{ width: '100%' }} />
            </div>

            <FieldArray name="work_history">
                {(arrayHelpers) => (
                    <Dialog className='historyForm' header="Add Work History" visible={isEditOpen} onHide={() => {
                        if (!isEdit) {
                            arrayHelpers.remove(workIndex);
                            setIsEdit(false)
                            setIsEditOpen(false);
                            setError('')
                        }
                        if (isEdit) {
                            handleSave()
                        }
                        setIsDelete(true)
                        // setError('')
                        // setIsEditOpen(false);

                    }}>
                        {/* -------------------- */}
                        <div style={{ textAlign: 'start', padding: '1.5rem' }}>
                            <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                                <div className='inputContainerDiv' style={{ width: '45%', margin: '0.5rem' }}>
                                    <label htmlFor={`"title"`} style={{ fontWeight: 'bold', color: Boolean(error?.title) ? 'red' : '' }}>Title *</label>
                                    <Field type="text" id="title" name={`work_history[${workIndex}].title`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                    {/* <ErrorMessage name="title" component="div" className="error" style={{color:'red'}} /> */}
                                </div>
                                <div className='inputContainerDiv' style={{ width: '30%', margin: '0.5rem' }}>
                                    <label htmlFor="yearly_income" style={{ fontWeight: 'bold', color: Boolean(error?.yearly_income) ? 'red' : '' }}>Yearly Income *</label>
                                    <Field type="number" id="yearly_income" name={`work_history[${workIndex}].yearly_income`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                    {/* <ErrorMessage name="yearly_income" component="div" className="error" style={{color:'red'}}/> */}
                                </div>
                                <div className='inputContainerDiv' style={{ width: '25%', margin: '0.5rem' }}>
                                    <label htmlFor="time_at_position" style={{ fontWeight: 'bold', color: Boolean(error?.time_at_position) ? 'red' : '' }}>Time at Position *</label> <br />
                                    {['1 year or less', '2 years', 'Other'].map((option) => {
                                        return (
                                            <FlexComponent justifyContent='start' gap='0.75rem' key={option} style={{ marginTop: '0.5rem' }}>
                                                <Field type="radio" id={option} name={`work_history[${workIndex}].time_at_position`} value={option} as={RadioButton} />
                                                <label htmlFor={option} className="ml-2">{option}</label>

                                            </FlexComponent >
                                        );
                                    })}
                                    {values.work_history[workIndex]?.time_at_position === 'Other' && (
                                        <div style={{ marginTop: '1rem' }}>
                                            <Field type="text" placeholder="Other" id="otherTimeAtPostion" name={`work_history[${workIndex}].otherTimeAtPostion`} as={InputText} style={{ width: '100%' }} />
                                        </div>
                                    )}

                                </div>

                            </FlexComponent>

                            <FlexComponent className='inputContainer' gap='1rem' alignItems='start'>
                                <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                                    <label htmlFor="company_name" style={{ fontWeight: 'bold' }}>Company Name</label>
                                    <Field type="text" id="company_name" name={`work_history[${workIndex}].company_name`} as={InputText} style={{ width: '100%' }} onBlur={handleBlur} />
                                </div>
                                <div className='inputContainerDiv' style={{ width: '50%', margin: '0.5rem' }}>
                                    <label htmlFor="employment_type" style={{ fontWeight: 'bold' }}>Employment Type</label>
                                    <Field
                                        name={`work_history[${workIndex}].employment_type`}
                                    >
                                        {({ field }) => (
                                            <Dropdown
                                                id={field.name}
                                                value={values.work_history[workIndex]?.employment_type}
                                                options={[
                                                    { label: 'Salary - W2 Employee', value: 'Salary - W2 Employee' },
                                                    { label: 'Hourly - W2 Employee', value: 'Hourly - W2 Employee' },
                                                    { label: '1099 Independent Contractor', value: '1099 Independent Contractor' },
                                                    { label: 'Self Employed/Business Owner', value: 'Self Employed/Business Owner' },
                                                ]}
                                                style={{ width: '100%' }}
                                                onChange={(event) => handleChange(`work_history[${workIndex}].employment_type`)(event.value)}
                                                onBlur={handleBlur}
                                                placeholder="Select Time"
                                            />
                                        )}
                                    </Field>
                                </div>
                            </FlexComponent>

                            <FlexComponent className='inputContainer' gap='1rem' alignItems='start' justifyContent="start">
                                <div className='inputContainerDiv' style={{ textAlign: 'start', width: '35%', margin: '0.5rem' }}>
                                <label style={{ fontWeight: 'bold',color : Boolean(error?.status) ? 'red' : '' }} htmlFor="status" >Status *</label> <br />
                                    {[{label : "Currently working in this role", value : true}, {label : "I used to work in this role", value : false}].map((option) => {
                                        return (
                                            <FlexComponent justifyContent='start' gap='0.75rem' key={option.label} style={{ marginTop: '0.5rem' }}>
                                                <Field type="radio" id={option.label} name={`work_history[${workIndex}].status`} value={option.value} as={RadioButton} />
                                                <label htmlFor={option.label} className="ml-2">{option.label}</label>

                                            </FlexComponent >
                                        );
                                    })}
                                </div>
                                <div className='inputContainerDiv' style={{ width: '28%', margin: '0.5rem' }}>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="start_date" >Start Date</label>
                                    <Field
                                        name={`work_history[${workIndex}].start_date`}
                                    >{({ field, form }) => (
                                        <Calendar
                                            id={field.name}
                                            value={field.value}
                                            style={{ width: '100%' }}
                                            onChange={(e) => form.setFieldValue(field.name, e.value)}
                                        />
                                    )}</Field>
                                </div>
                                {!values.work_history[workIndex]?.status &&
                                    <div className='inputContainerDiv' style={{ width: '28%', margin: '0.5rem' }}>
                                        <label style={{ fontWeight: 'bold' }} htmlFor="end_date" >End Date</label>
                                        <Field
                                            name={`work_history[${workIndex}].end_date`}
                                        >{({ field, form }) => (
                                            <Calendar
                                                id={field.name}
                                                value={field.value}
                                                style={{ width: '100%' }}
                                                onChange={(e) => form.setFieldValue(field.name, e.value)}
                                            />
                                        )
                                            }</Field>
                                    </div>
                                }
                            </FlexComponent>
                            <div style={{ textAlign: 'start', margin: '0.5rem' }}>
                                <label htmlFor="work_history_description" >
                                    <p style={{ fontWeight: 'bold' }}> Work History Description </p>
                                    <p style={{ margin: "0.5rem 0" }}>Full 2 to 3 years of work history, # of years at this position, # of years in the profession/industry</p>
                                </label>
                                <Field rows={4} type="text" id="work_history_description" name={`work_history[${workIndex}].work_history_description`} as={InputTextarea} style={{ width: '100%' }}
                                    placeholder="Include any additional notes they share about their job/work experience here..." />
                            </div>


                            {/* ----------- */}
                        </div>
                        <div style={{ position: 'sticky', bottom: '0', background: 'white' }}>
                            <FlexComponent gap='1rem' justifyContent={!isDelete ? 'end' : ''} style={{ padding: '1.5rem' }}>
                                {isDelete && <Button type='button' onClick={() => {
                                    // if (!isEdit) {
                                    arrayHelpers.remove(workIndex);
                                    setIsEdit(false)
                                    setIsDelete(true)
                                    // }
                                    setIsEditOpen(false);
                                }}>Delete History</Button>}
                                <Button label={isEdit ? 'Close' : 'Save'} type='button' onClick={() => {
                                    handleSave()
                                }} />
                            </FlexComponent>
                        </div>
                    </Dialog>
                )}
            </FieldArray>

        </div>
    )
}

export default WorkHistory