import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { BubblyLink } from "../library/src";
import './Nav.css'

export default function MobileNav({isSuperuser, is_leader}) {
    const [visible, setVisible] = useState(false);

    const MyBubblyLink = ({ to = "", text = "" }) => (
        <BubblyLink to={to} colorStart="#da474e" colorEnd="#ffffff">
          {text}
        </BubblyLink>
      );

    return (
        <div className="mobile-nav">
            <Sidebar visible={visible} onHide={() => setVisible(false)} className="w-full md:w-20rem lg:w-30rem">
                {isSuperuser?
                    <nav className="animate-in mobile-nav-link" style={{ animationDelay: "800ms" }}>
                        <MyBubblyLink to="/" text="Dashboard" />
                        <MyBubblyLink to="/callers-leaderboard" text="Caller Leaderboard" />
                        <MyBubblyLink to="/shoppers-leaderboard" text="Shopper Leaderboard" />
                        <MyBubblyLink to="/participant" text="Participants" />
                        <MyBubblyLink to="/app-kanban" text="App" />
                        <MyBubblyLink to="/team" text="Teams" />
                        <MyBubblyLink to="/campaign" text="Campaigns" />
                        <MyBubblyLink to="/purchase" text="Purchases" />
                        <MyBubblyLink to="/settings" text="Settings" />
                    </nav>
                :
                <nav className="animate-in mobile-nav-link" style={{ animationDelay: "800ms" }}>
                    <MyBubblyLink to="/" text="Dashboard" />
                    <MyBubblyLink to="/callers-leaderboard" text="Caller Leaderboard" />
                    <MyBubblyLink to="/shoppers-leaderboard" text="Shopper Leaderboard" />
                    {is_leader&&
                        <MyBubblyLink to="/participant" text="Participants" />
                    }
                    <MyBubblyLink to="/app-kanban" text="App" />
                    {/* <MyBubblyLink to="/team" text="Teams" /> */}
                    {/* <MyBubblyLink to="/campaign" text="Campaigns" /> */}
                    {/* <MyBubblyLink to="/purchase" text="Purchases" /> */}
                    {/* <MyBubblyLink to="/settings" text="Settings" /> */}
                </nav>
                }
            </Sidebar>
            <Button icon="pi pi-bars" onClick={() => setVisible(true)} />
        </div>
    )
}
        